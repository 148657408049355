import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from "@mui/material/styles";

const StyledAutocomplete = styled(Autocomplete)({
  "&.Mui-focused .MuiInputLabel-outlined": {
    color: "#800000"
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#800000"
  },
  "& .MuiAutocomplete-popper .MuiAutocomplete-option": 
  {
    backgroundColor: "#800000",
  },
"& .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
  {
    backgroundColor: "#4396e6",
  },
"& .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'] .Mui-focused":
  {
    backgroundColor: "#3878b4",
  },
  "& .MuiAutocomplete-tag":
  {
    color: '#800000',
    backgroundColor: 'rgba(128, 0, 0, 0.08)',
  },
  "& .MuiAutocomplete-hasClearIcon":
  {
    color: '#800000',
    backgroundColor: 'rgba(128, 0, 0, 0.08)',
  },

  "& .MuiAutocomplete-hasPopupIcon":
  {
    color: '#800000',
    backgroundColor: 'rgba(128, 0, 0, 0.08)',
  },
  "& .MuiChip-deleteIcon":
  {
    color: 'rgba(128, 0, 0, 0.26) !important',
  },
  "& .MuiChip-deleteIcon:hover":
  {
    color: 'rgba(128, 0, 0, 0.44) !important',
  },
  "& .MuiAutocomplete-clearIndicator":
  {
    color: 'rgba(128, 0, 0, 0.8) !important',
  },
  "& .MuiAutocomplete-clearIndicator:hover":
  {
    color: 'rgba(128, 0, 0, 0.8) !important',
    backgroundColor: 'rgba(128, 0, 0, 0.08) !important'
  },
  "& .MuiAutocomplete-popupIndicator":
  {
    color: 'rgba(128, 0, 0, 0.8) !important',
  },
  "& .MuiAutocomplete-popupIndicator:hover":
  {
    color: 'rgba(128, 0, 0, 0.8) !important',
    backgroundColor: 'rgba(128, 0, 0, 0.08) !important'
  },
  '& .MuiAutocomplete-listbox::-webkit-scrollbar': {
    width: '0.5rem',
  },
  '& .MuiAutocomplete-listbox::-webkit-scrollbar-track': {
    background: 'rgba(128, 0, 0, 0.08) !important',
  },
  '& .MuiAutocomplete-listbox::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(128, 0, 0, 0.6) !important',
  },
  '& .MuiAutocomplete-listbox::-webkit-scrollbar-thumb:hover': {
    background: 'rgba(128, 0, 0, 0.8) !important',
  },
  '& .MuiAutocomplete-listbox::-webkit-scrollbar-thumb:active': {
    background: 'rgba(128, 0, 0, 1) !important',
  },
});

export default function PlayerSelect({onChangePlayer, value, options}) {
    const [inputValue, setInputValue] = React.useState('')

    const handleChange = (event, newValue) => {
        onChangePlayer(event, newValue);
    };

    return (
      <FormControl sx={{mr: 2, minWidth: 230}} size="medium">
        <StyledAutocomplete
          labelId="player-select-label"
          id="player-select"
          name="playerId"
          multiple
          value={value}
          inputValue={inputValue}
          onInputChange={(_, newInputValue) => {
            setInputValue(newInputValue)
          }}
          options={options}
          getOptionLabel={(option) => option.fullName}
          isOptionEqualToValue={(option, value) => option.id === value.id }
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} label="Player" />}
        />
      </FormControl>
    );
  }