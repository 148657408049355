import React from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import aws4 from 'aws4';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';
import { format as formatUrl } from 'url'
import Cookies from 'js-cookie';
import parse from 'html-react-parser'; 
import CheckboxesGroup from './CheckboxesGroup';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import TeamSelect from './TeamSelect';
import SeasonSelect from './SeasonSelect';
import PlayerSelect from './PlayerSelect';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import PositionSelect from './PositionSelect';
import { viewThreshold } from '../util/constants.js';

if (process.env.REACT_APP_ENV === 'production') {
  console.log = function () {}
}

export default function Form({onFormSubmit, onLoading, checkboxes, onCheckboxChange, teamList, onTeamListChange, season, onSeasonChange, positionList, onPositionListChange, playerOptionsList, 
  onPlayerListChange, selectedPlayerList, updatePlayerOptionsList, startTime, onStartTimeChange, endTime, onEndTimeChange, statList, onStatListChange, statPanelExpanded, onStatPanelChange, 
  filterPanelExpanded, onFilterPanelChange, isLanding, isPlayerBreakdown, onPlayerBreakdownUpdate, hasURLParams, valid, showAlert}) {
  const theme = useTheme();
  const [loading, setLoading] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("There is an issue with the input form")
  const [alertType, setAlertType] = React.useState("error")
  const [localIsLanding, setLocalIsLanding] = React.useState(true);
  const today = new Date();
  const views = Cookies.get('views')
  console.log('Form top views is ' + views)

  const clientId = process.env.REACT_APP_CLIENT_ID
  // redirect_uri should be the full redirect url
  const redirect = window.location.origin + '/oauth/redirect'

  const loginUrl = formatUrl({
      protocol: 'https',
      host: 'patreon.com',
      pathname: '/oauth2/authorize',
      query: {
          response_type: 'code',
          client_id: clientId,
          redirect_uri: redirect,
          state: 'chill'
      }
  })

  const joinHtmlString = `You have reached the maximum daily guest views allowed. Please consider <a href='https://www.patreon.com/FourteenSeventeen'>Joining Our Patreon</a>, then <a href='${loginUrl}'>Login</a> with Patreon for unlimited access!`;

  if (isLanding && localIsLanding) {
    setLocalIsLanding(false);
    callOnLanding();
  }

  if (!isLanding && localIsLanding && showAlert && !openAlert) {
    console.log("setting Alert")
    setLocalIsLanding(false);
    handleAlert(true, "warning", joinHtmlString)
  }

  axiosRetry(axios, {
    retries: 3, // number of retries
    retryDelay: (retryCount) => {
        console.log(`retry attempt: ${retryCount}`);
        return retryCount * 500; // time interval between retries
    },
    retryCondition: (error) => {
        // if retry condition is not specified, by default idempotent requests are retried
        return error.response == null;
    },
});

  // initial call to populate charts on Landing
  async function callOnLanding() {
    if (!valid && views >= viewThreshold) {
      console.log("in callOnLanding, calling handleSubmit with null event ");
      handleSubmit(null)
      return;
    }
    setLoading(true)
    onLoading(true)
    
    let response = {}
    let events = []
    let players = []
    //make one call per team
    if (!hasURLParams) {
      Promise.all([callQueryLambda('13,54', '', 'GOAL')]).then(function(responses) {
        response = responses[0];
        for (var i = 0; i < responses.length; i++) {
          if (responses[i].data.events != null && responses[i].data.events.length > 0) {
            events.push(...responses[i].data.events)
          }
          if (responses[i].data.players != null && responses[i].data.players.length > 0) {
            players.push(...responses[i].data.players)
          }
        }
        setLoading(false);
        onFormSubmit(response, events, players, ['GOAL'], [13,54], [], []);
      })
    } else {
      handleSubmit(null);
    }
    updatePlayerList(teamList, []);
  }

  async function callQueryLambda(t, p, s) {
    let start = startTime == null ? '' : startTime;
    let end = endTime == null ? '' : endTime;
    let body = { 
      startTime: `${start}`, 
      endTime: `${end}`,
      teamId: `${t}`,
      playerId: `${p}`,
      statList: `${s}`,
      season: '20232024'
    };
    let request = {
      host: 'h86l12d7n5.execute-api.us-west-2.amazonaws.com',
      method: 'POST',
      url: `https://h86l12d7n5.execute-api.us-west-2.amazonaws.com/default/java-basic-QueryLambda-oU6qE6DzZf3g`,
      data: body,
      body: JSON.stringify(body), // aws4 looks for body; axios for data
      path: `/default/java-basic-QueryLambda-oU6qE6DzZf3g`,
      headers: {
        'content-type': 'application/json'
      }
    }
    let signedRequest = aws4.sign(request,
      {
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
        accessKeyId: process.env.REACT_APP_ACCESS_KEY
      })
    
    delete signedRequest.headers['Host']
    delete signedRequest.headers['Content-Length']
    return axios(signedRequest)
  }

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    width: 450,
    boxShadow: 24,
    pt: 2,
  };

  const minYear = season != null ? parseInt(season.toString().substring(0,4)) : 1970;
  const maxYear = season != null ? parseInt(season.toString().substring(0,4)) + 1 : today.getFullYear() + 2;

  function handlePlayerListChange(event, newValue) {
    if (newValue.length > 15) {
      handleAlert(true, "warning", "Please contact us at contact@fourteenseventeen.com if you would like to select more than 15 players!")
      return;
    }
    onPlayerListChange(event, newValue)
  }

  function handleAlert(alert, alertType, message) {
    setAlertType(alertType)
    setAlertMessage(message);
    setOpenAlert(alert);
  }

  function handlePlayerBreakdownUpdate(event) {
    onPlayerBreakdownUpdate(event)
  }

  async function handleTeamChange(event, newValue) {
    if (newValue.length > 1 && newValue[0].label === "Any") {
      newValue.shift();
    }
    // TODO: handle null removal better?
    onTeamListChange(newValue == null ? [{ label: 'Any', id: "" }] : newValue)
    const positionIdList = [];
    positionList.forEach(position => {
      if (position.id !== ""){
        positionIdList.push(position.id);
      }
    })
    updatePlayerList(newValue, positionIdList);
  }

  async function updatePlayerList(teamList, positionIdList) {
    const newPlayerList = [];
    if (teamList !== null && teamList !== '' && teamList.length > 0) {
      let promises = []
      for (const team of teamList) {
        promises.push(axios.post(
          'https://h86l12d7n5.execute-api.us-west-2.amazonaws.com/default/java-basic-PlayerLambda-ElQOPrHet6dv',
          { teamId: `${team.id}`, 
            season: `${season}`, 
            positionList: `${positionIdList}`,
          }
        ))
      }

      Promise.all(promises).then(function(responses) {
        responses.forEach((response) => {
          if (response.data != null) {
            newPlayerList.push(...response.data);
          }
        })
        updatePlayerOptionsList(newPlayerList);
      });
    }
  }

  async function handleSeasonChange(event) {
    const value = event.target.value;
    // TODO: handle null removal
    onSeasonChange(value);
    const positionIdList = [];
    positionList.forEach(position => {
      if (position.id !== ""){
        positionIdList.push(position.id);
      }
    })
    updatePlayerList(teamList, positionIdList);
  }

  async function handlePositionChange(event, newValue) {
    const value = newValue;
    // TODO: handle null removal
    onPositionListChange(value)
    const positionIdList = [];
    value.forEach(position => {
      if (position.id !== ""){
        positionIdList.push(position.id);
      }
    })
    updatePlayerList(teamList, positionIdList);
  }

  function handleCheckboxChange(event) {
    // Destructuring
    const { checked, id, name } = event.target;

    if (statList.length >= 5 && checked) {
      handleAlert(true, "warning", "Please contact us at contact@fourteenseventeen.com if you would like to select more than 5 stats!")
      return;
    }
     
    // Case 1 : The user checks the box
    if (checked) {
      onStatListChange([...statList, id])
      onCheckboxChange({
        ...checkboxes,
        [name]: checked,
      });
    }
  
    // Case 2  : The user unchecks the box
    else {
      onStatListChange(statList.filter((e) => e !== id))
      onCheckboxChange({
        ...checkboxes,
        [name]: checked,
      });
    }
  }

  async function handleSubmit(event) {
    console.log('Form views is ' + views)
    console.log('viewThreshold is ' + viewThreshold);
    console.log('In Form handleSubmit');
    const teamIdList = [];
    const playerIdList = [];
    const positionIdList = [];
    let events = []
    let players = []
    positionList.forEach(position => {
      if (position.id !== ""){
        positionIdList.push(position.id);
      }
    })
    if (event != null) {
      event.preventDefault();
      const url = new URL(window.location.href);
      url.pathname = "team/" + teamList[0].abbreviation;
      if (teamList.length > 1) {
        let teamListString = ""
        let i = 0;
        for(const team of teamList) {
          if (i !== 0) {
            teamListString += team.abbreviation;
          }
          if (i !== 0 && i < teamList.length - 1) {
            teamListString += ",";
          }
          i++;
        }
        url.searchParams.set('compareTeams', teamListString);
      } else {
        url.searchParams.delete('compareTeams')
      }
      url.searchParams.set('season', season);
      url.searchParams.set('stats', statList.toString())
      if (positionIdList.length > 0) {
        url.searchParams.set('positions', positionIdList.toString())
      }
      if (isPlayerBreakdown != null && isPlayerBreakdown) {
        url.searchParams.set('byPlayer', isPlayerBreakdown)
      } else {
        url.searchParams.delete('byPlayer')
      }
      if (startTime != null) {
        url.searchParams.set('startTime', startTime)
      }
      if (endTime != null) {
        url.searchParams.set('endTime', endTime)
      }
      window.history.replaceState(null, null, url);
    }
    if (!valid && views >= viewThreshold) {
      onLoading(false);
      setLoading(false);
      handleAlert(true, "warning", joinHtmlString)
      onFormSubmit({}, [], [], [], [], [], []);
      return;
    }
    onLoading(true);
    setLoading(true);
    teamList.forEach(team => {
      if (team.id !== ""){
        teamIdList.push(team.id);
      }
    })
    if (teamIdList.length === 0) {
      onLoading(false);
      setLoading(false)
      handleAlert(true, "error", "Please select 1-3 teams")
    } else if (statList.length === 0) {
      onLoading(false);
      setLoading(false);
      handleAlert(true, "error", "Please select at least one stat")
    } else {
      selectedPlayerList.forEach(player => {
        playerIdList.push(player.id);
      })
      let promises = []
      let response = {}
      // each stat will be called as a separate lambda
      // if playerList is included, ignore teamList entirely
      if (playerIdList.length > 0) {
        for (const p of playerIdList) {
          for (const s of statList) {
            promises.push(callFullQueryLambda('', p.toString(), s.toString()))
          }
        }
      } else {
        for (const t of teamIdList) {
          for (const s of statList) {
            promises.push(callFullQueryLambda(t.toString(), '', s.toString()))
          }
        }
      }
      
      Promise.all(promises).then(function(responses) {
        response = responses[0];
        for (var i = 0; i < responses.length; i++) {
          console.log("i is " + i)
          console.log("responses[i].data is " + responses[i].data)
          if (responses[i].data.events != null && responses[i].data.events.length > 0) {
            events.push(...responses[i].data.events)
          }
          if (responses[i].data.players != null && responses[i].data.players.length > 0) {
            players.push(...responses[i].data.players)
          }
        }
        setLoading(false);
        onFormSubmit(response, events, players, statList, teamIdList, playerIdList, positionIdList, isPlayerBreakdown);
      });
    }
  }

  // arguments: teamIdList, playerIdList, statList
  async function callFullQueryLambda(t, p, s) {
    let start = startTime == null ? '' : startTime;
    let end = endTime == null ? '' : endTime;
    let body = { 
      startTime: `${start}`, 
      endTime: `${end}`,
      teamId: `${t}`,
      playerId: `${p}`,
      statList: `${s.toString()}`,
      season: `${season}`
    };
    let request = {
      host: 'h86l12d7n5.execute-api.us-west-2.amazonaws.com',
      method: 'POST',
      url: `https://h86l12d7n5.execute-api.us-west-2.amazonaws.com/default/java-basic-QueryLambda-oU6qE6DzZf3g`,
      data: body, // object describing the foo
      body: JSON.stringify(body), // aws4 looks for body; axios for data
      path: `/default/java-basic-QueryLambda-oU6qE6DzZf3g`,
      headers: {
        'content-type': 'application/json'
      }
    }
    let signedRequest = aws4.sign(request,
      {
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
        accessKeyId: process.env.REACT_APP_ACCESS_KEY
      })
    
    delete signedRequest.headers['Host']
    delete signedRequest.headers['Content-Length']
    return axios(signedRequest)
  }

  return (
    <Box
      noValidate
    >
        <Box
          sx={{
            '& .MuiTextField-root': { m: 1 },
            pl: 1, 
            outline: 'none'
          }}
          noValidate
        >
          <Modal
            open={openAlert}
            onClose={() => {
              setOpenAlert(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              "& .MuiModal-backdrop":
              {
                backgroundColor: 'transparent',
                boxShadow: 'none',
              },
            }}
          >
            <Box sx={modalStyle}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="medium"
                    onClick={() => {
                      setOpenAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
                severity={alertType}
              >
                <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                  { parse(alertMessage) }
                </Typography>
              </Alert>
            </Box>
          </Modal>
          <Grid container>
            <Grid item xs={12}>
              <TeamSelect 
                onChangeTeam={handleTeamChange}
                value={teamList}
                onTeamSizeAlert={handleAlert}
              />
            </Grid>
            <Grid item xs={12}>
              <Tooltip title="Shows stats of each player on the team individually. Only includes the player's stats for the team(s) selected and for the season selected.">
                <FormControlLabel
                  sx={{ pl: 1}}
                  label="Show stats per player"
                  control={<Checkbox checked={isPlayerBreakdown} onChange={handlePlayerBreakdownUpdate} id="PLAYER_BREAKDOWN" name="playerBreakdown"/>}
                />
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{ p: 1, px: 2 }
          }
          noValidate
        >
          <Accordion expanded={statPanelExpanded} onChange={onStatPanelChange}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon/>}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                "& .MuiAccordionSummary-expandIconWrapper":
                {
                  color: 'rgba(128, 0, 0, 0.8) !important',
                },
              }}
            >
              <Typography color="primary" component="h6" variant="subtitle1" align="center">Stats</Typography>
            </AccordionSummary>
            <CheckboxesGroup 
              name="statList"
              onChangeTask={handleCheckboxChange}
              goal={checkboxes.goal}
              powerPlayGoal={checkboxes.powerPlayGoal}
              shorthandedGoal={checkboxes.shorthandedGoal}
              gameWinningGoal={checkboxes.gameWinningGoal}
              goalAgainst={checkboxes.goalAgainst}
              hit={checkboxes.hit}
              hitTaken={checkboxes.hitTaken}
              fight={checkboxes.fight}
              shot={checkboxes.shot}
              save={checkboxes.save}
              missedShot={checkboxes.missedShot}
              blockedShot={checkboxes.blockedShot}
              shotTakenBlocked={checkboxes.shotTakenBlocked}
              point={checkboxes.point}
              assist={checkboxes.assist}
              primaryAssist={checkboxes.primaryAssist}
              secondaryAssist={checkboxes.secondaryAssist}
              faceoff={checkboxes.faceoff}
              faceoffWin={checkboxes.faceoffWin}
              faceoffLoss={checkboxes.faceoffLoss}
              giveaway={checkboxes.giveaway}
              takeaway={checkboxes.takeaway}
              penalty={checkboxes.penalty}
              penaltyDrawn={checkboxes.penaltyDrawn}
            />
          </Accordion>
        </Box>
        <Box
          sx={{
            '& .MuiTextField-root': { m: 1 },
            p: 1,
            px: 2
          }}
          noValidate
        >
          <Accordion expanded={filterPanelExpanded} onChange={onFilterPanelChange}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon/>}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                "& .MuiAccordionSummary-expandIconWrapper":
                {
                  color: 'rgba(128, 0, 0, 0.8) !important',
                },
              }}
            >
              <Typography color="primary" component="h6" variant="subtitle1" align="center" >Optional Filters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={0} alignItems="center">
                <Grid item xs={12}>
                  <SeasonSelect 
                    onChangeSeason={handleSeasonChange}
                    value={season}
                  />
                  <PositionSelect
                    onChangePosition={handlePositionChange}
                    value={positionList}
                  />
                  <PlayerSelect 
                    onChangePlayer={handlePlayerListChange}
                    value={selectedPlayerList}
                    options={playerOptionsList}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    label="Start Date"
                    format="YYYY-MM-DD"
                    inputFormat="YYYY-MM-DD"
                    onChange={(newValue) => onStartTimeChange(newValue)}
                    minDate={dayjs(new Date(minYear, 8, 1))}
                    maxDate={dayjs(new Date(maxYear, 9, 1))}
                    value={startTime == null ? null : dayjs(startTime)}
                  />
                  <DatePicker
                    label="End Date"
                    format="YYYY-MM-DD"
                    inputFormat="YYYY-MM-DD"
                    minDate={dayjs(new Date(minYear, 8, 1))}
                    maxDate={dayjs(new Date(maxYear, 9, 1))}
                    onChange={(newValue) => onEndTimeChange(newValue)}
                    value={endTime == null ? null: dayjs(endTime)}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box
          component="form" 
          sx={{p: 2, pb: 1 }}
          noValidate
          onSubmit={handleSubmit}
        >
          <LoadingButton 
            variant="contained" 
            type="submit" 
            loading={loading}
          >
            Submit
          </LoadingButton>
        </Box>
    </Box>
  );
}