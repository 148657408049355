import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AccordionDetails from '@mui/material/AccordionDetails';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

export default function CheckboxesGroup({onChangeTask, goal, powerPlayGoal, shorthandedGoal, gameWinningGoal, goalAgainst, hit, hitTaken, shot, save, fight, missedShot, blockedShot, shotTakenBlocked, point, assist, primaryAssist, secondaryAssist, faceoff, faceoffWin, faceoffLoss, giveaway, takeaway, penalty, penaltyDrawn}) {

  const handleChange = (event) => {
    onChangeTask(event);
  };

  return (
    <div>
        <AccordionDetails>
          <FormControl sx={{flexDirection: { xs: "column", sm: "row" }}} variant="standard">
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox checked={goal} onChange={handleChange} id="GOAL" name="goal" />
                }
                label="Goals"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={powerPlayGoal} onChange={handleChange} id="POWER_PLAY_GOAL" name="powerPlayGoal" />
                }
                label="Power Play Goals"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={shorthandedGoal} onChange={handleChange} id="SHORTHANDED_GOAL" name="shorthandedGoal" />
                }
                label="Shorthanded Goals"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={gameWinningGoal} onChange={handleChange} id="GAME_WINNING_GOAL" name="gameWinningGoal" />
                }
                label="Game Winning Goals"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={goalAgainst} onChange={handleChange} id="GOAL_AGAINST" name="goalAgainst" />
                }
                label="Goals Against"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={assist} onChange={handleChange} id="ASSIST" name="assist" />
                }
                label="Assists"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={primaryAssist} onChange={handleChange} id="PRIMARY_ASSIST" name="primaryAssist" />
                }
                label="Primary Assists"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={secondaryAssist} onChange={handleChange} id="SECONDARY_ASSIST" name="secondaryAssist" />
                }
                label="Secondary Assists"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={point} onChange={handleChange} id="POINT" name="point" />
                }
                label="Points"
              />
            </FormGroup>
          </FormControl>
          <FormControl sx={{flexDirection: { xs: "column", sm: "row" } }} component="fieldset" variant="standard">
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox checked={hit} onChange={handleChange} id="HIT" name="hit" />
                }
                label="Hits"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={hitTaken} onChange={handleChange} id="HIT_TAKEN" name="hitTaken" />
                }
                label="Hits Taken"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={save} onChange={handleChange} id="SAVE" name="save" />
                }
                label="Saves"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={faceoff} onChange={handleChange} id="FACEOFF" name="faceoff" />
                }
                label="Faceoffs"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={faceoffWin} onChange={handleChange} id="FACEOFF_WIN" name="faceoffWin" />
                }
                label="Faceoff Wins"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={faceoffLoss} onChange={handleChange} id="FACEOFF_LOSS" name="faceoffLoss" />
                }
                label="Faceoff Losses"
              />
            </FormGroup>
          </FormControl>
          <FormControl sx={{flexDirection: { xs: "column", sm: "row" } }} component="fieldset" variant="standard">
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox checked={giveaway} onChange={handleChange} id="GIVEAWAY" name="giveaway" />
                }
                label="Giveaways"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={takeaway} onChange={handleChange} id="TAKEAWAY" name="takeaway" />
                }
                label="Takeaways"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={penalty} onChange={handleChange} id="PENALTY" name="penalty" />
                }
                label="Penalties"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={penaltyDrawn} onChange={handleChange} id="PENALTY_DRAWN" name="penaltyDrawn" />
                }
                label="Penalties Drawn"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={fight} onChange={handleChange} id="FIGHT" name="fight" />
                }
                label="Fights"
              />
            </FormGroup>
          </FormControl>
          <FormControl sx={{flexDirection: { xs: "column", sm: "row" } }} component="fieldset" variant="standard">
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox checked={shot} onChange={handleChange} id="SHOT" name="shot" />
                }
                label="Shots"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={blockedShot} onChange={handleChange} id="BLOCKED_SHOT" name="blockedShot" />
                }
                label="Blocked Shots"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={missedShot} onChange={handleChange} id="MISSED_SHOT" name="missedShot" />
                }
                label="Missed Shots"
              />
              {/* add info: shots taken that were blocked by opposing team */}
              <Tooltip title="Shot Blocked: offensive shot taken that was blocked by defending team">
                <FormControlLabel
                  control={
                    <Checkbox checked={shotTakenBlocked} onChange={handleChange} id="SHOT_TAKEN_BLOCKED" name="shotTakenBlocked" />
                  }
                  label="Shot Blocked"
                />
              </Tooltip>
              <Tooltip title="Shot Blocked: offensive shot taken that was blocked by defending team" placement="right" sx={{pb:2,}}>
                <IconButton size='small'>
                  <InfoIcon fontSize='small' color='info'/>
                </IconButton>
              </Tooltip>
            </FormGroup>
          </FormControl>
        </AccordionDetails>
    </div>
  );
}
