import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { teamInfo } from '../util/TeamInfo';
import { styled } from "@mui/material/styles";
import { teamSelection } from '../util/constants.js';

const StyledAutocomplete = styled(Autocomplete)({
  "&.Mui-focused .MuiInputLabel-outlined": {
    color: "#800000"
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#800000"
  },
  "& .MuiAutocomplete-popper .MuiAutocomplete-option": 
  {
    backgroundColor: "#800000",
  },
"& .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
  {
    backgroundColor: "#4396e6",
  },
"& .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'] .Mui-focused":
  {
    backgroundColor: "#3878b4",
  },
  "& .MuiAutocomplete-tag":
  {
    color: '#800000',
    backgroundColor: 'rgba(128, 0, 0, 0.08)',
  },
  "& .MuiAutocomplete-hasClearIcon":
  {
    color: '#800000',
    backgroundColor: 'rgba(128, 0, 0, 0.08)',
  },

  "& .MuiAutocomplete-hasPopupIcon":
  {
    color: '#800000',
    backgroundColor: 'rgba(128, 0, 0, 0.08)',
  },
  "& .MuiChip-deleteIcon":
  {
    color: 'rgba(128, 0, 0, 0.26) !important',
  },
  "& .MuiChip-deleteIcon:hover":
  {
    color: 'rgba(128, 0, 0, 0.44) !important',
  },
  "& .MuiAutocomplete-clearIndicator":
  {
    color: 'rgba(128, 0, 0, 0.8) !important',
  },
  "& .MuiAutocomplete-clearIndicator:hover":
  {
    color: 'rgba(128, 0, 0, 0.8) !important',
    backgroundColor: 'rgba(128, 0, 0, 0.08) !important'
  },
  "& .MuiAutocomplete-popupIndicator":
  {
    color: 'rgba(128, 0, 0, 0.8) !important',
  },
  "& .MuiAutocomplete-popupIndicator:hover":
  {
    color: 'rgba(128, 0, 0, 0.8) !important',
    backgroundColor: 'rgba(128, 0, 0, 0.08) !important'
  },
  '& .MuiAutocomplete-listbox::-webkit-scrollbar': {
    width: '0.5rem',
  },
  '& .MuiAutocomplete-listbox::-webkit-scrollbar-track': {
    background: 'rgba(128, 0, 0, 0.08) !important',
  },
  '& .MuiAutocomplete-listbox::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(128, 0, 0, 0.6) !important',
  },
  '& .MuiAutocomplete-listbox::-webkit-scrollbar-thumb:hover': {
    background: 'rgba(128, 0, 0, 0.8) !important',
  },
  '& .MuiAutocomplete-listbox::-webkit-scrollbar-thumb:active': {
    background: 'rgba(128, 0, 0, 1) !important',
  },
});

// TODO: more than 3 teams for subscribers only?
export default function TeamSelect({onChangeTeam, value, season, onTeamSizeAlert}) {
    const [inputValue, setInputValue] = React.useState([''])
    const options = teamInfo;

    const handleChange = (event, newValue) => {
      if (newValue.length > teamSelection) {
        onTeamSizeAlert(true, 'warning', 'Please contact us at contact@fourteenseventeen.com if you would like to select more than 3 teams!');
      } else {
        onChangeTeam(event, newValue);
      }
    };

    return (
      <FormControl sx={{mr: 2, minWidth: 270}} size="medium">
        <StyledAutocomplete
          labelId="team-select-label"
          id="team-select"
          name="teamId"
          multiple
          disableCloseOnSelect
          value={value}
          inputValue={inputValue}
          onInputChange={(_, newInputValue, reason) => {
            console.log("input change reason is " + reason)
            setInputValue(newInputValue)
          }}
          options={options}
          groupBy={(option) => option.division}
          getOptionLabel={(option) => option.label.toString()}
          isOptionEqualToValue={(option, value) => option.id === value.id }
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              {option.logo !== "" && (
                <img src={option.logo} height="25" width="25"/>
              )}
              {option.label}
            </Box>
          )}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} label="Team" />}
        />
      </FormControl>
    );
  }