// TODO: generate list of teams based on season?
export const teamInfo =  [
    // TODO: Any is for subscribers only?
    //{ label: 'Any', id: "", abbreviation: "", hex: "#000000", logo: "" },
    { label: 'Boston Bruins', id: 6, abbreviation: "BOS", hex: "#FDB41F", secondaryHex: "#000000", tertiaryHex: "#42280E", logo: "/logo/nhl-boston-bruins-logo-3.png", division: "Atlantic" },
    { label: 'Buffalo Sabres', id: 7, abbreviation: "BUF", hex: "#003087", secondaryHex: "#FFB81C", tertiaryHex: "#000000", logo: "/logo/nhl-buffalo-sabres-logo-3.png", division: "Atlantic" },
    { label: 'Detroit Red Wings', id: 17, abbreviation: "DET", hex: "#C8102E", secondaryHex: "#000000", tertiaryHex: "#fbca05", logo: "/logo/nhl-detroit-red-wings-logo-3.png", division: "Atlantic" },
    { label: 'Florida Panthers', id: 13, abbreviation: "FLA", hex: "#C8102E", secondaryHex: "#BC955C", tertiaryHex: "#041E42", logo: "/logo/nhl-florida-panthers-logo-3.png", division: "Atlantic" },
    { label: 'Montreal Canadiens', id: 8, abbreviation: "MTL", hex: "#a71930", secondaryHex: "#192168", tertiaryHex: "#72bae3", logo: "/logo/nhl-montreal-canadiens-logo-3.png", division: "Atlantic" },
    { label: 'Ottawa Senators', id: 9, abbreviation: "OTT", hex: "#191919", secondaryHex: "#B79257", tertiaryHex: "#DA1A32", logo: "/logo/nhl-ottawa-senators-logo-3.png", division: "Atlantic" },
    { label: 'Tampa Bay Lightning', id: 14, abbreviation: "TBL", hex: "#00205B", secondaryHex: "#000000", tertiaryHex: "#b3b4bc", logo: "/logo/nhl-tampa-bay-lightning-logo-3.png", division: "Atlantic" },
    { label: 'Toronto Maple Leafs', id: 10, abbreviation: "TOR", hex: "#054ec3", secondaryHex: "#000000", tertiaryHex: "#067d45", logo: "/logo/nhl-toronto-maple-leafs-logo-3.png", division: "Atlantic" },
    { label: 'Carolina Hurricanes', id: 12, abbreviation: "CAR", hex: "#CE1126", secondaryHex: "#000000", tertiaryHex: "#A2AAAD", logo: "/logo/nhl-carolina-hurricanes-logo-3.png", division: "Metropolitan" },
    { label: 'Columbus Blue Jackets', id: 29, abbreviation: "CBJ", hex: "#003366", secondaryHex: "#A4A9AD", tertiaryHex: "#CE1126", logo: "/logo/nhl-columbus-blue-jackets-logo-3.png", division: "Metropolitan" },
    { label: 'New Jersey Devils', id: 1, abbreviation: "NJD", hex: "#CC0000", secondaryHex: "#000000", tertiaryHex: "#244b3c", logo: "/logo/nhl-new-jersey-devils-logo-3.png", division: "Metropolitan" },
    { label: 'New York Islanders', id: 2, abbreviation: "NYI", hex: "#00468B", secondaryHex: "#F47D30", tertiaryHex: "#49a7b7", logo: "/logo/nhl-new-york-islanders-logo-3.png", division: "Metropolitan" },
    { label: 'New York Rangers', id: 3, abbreviation: "NYR", hex: "#0038A8", secondaryHex: "#CE1126", tertiaryHex: "#c0c0c0", logo: "/logo/nhl-new-york-rangers-logo-3.png", division: "Metropolitan" },
    { label: 'Philadelphia Flyers', id: 4, abbreviation: "PHI", hex: "#f74902", secondaryHex: "#000000", tertiaryHex: "#ff6319", logo: "/logo/nhl-philadelphia-flyers-logo-3.png", division: "Metropolitan" },
    { label: 'Pittsburgh Penguins', id: 5, abbreviation: "PIT", hex: "#FFB81C", secondaryHex: "#000000", tertiaryHex: "#CFC493", logo: "/logo/nhl-pittsburgh-penguins-logo-3.png", division: "Metropolitan" },
    { label: 'Washington Capitals', id: 15, abbreviation: "WSH", hex: "#CE0E2D", secondaryHex: "#0B1F41", tertiaryHex: "#000000", logo: "/logo/nhl-washington-capitals-logo-3.png", division: "Metropolitan" },
    { label: 'Arizona Coyotes', id: 53, abbreviation: "ARI", hex: "#98002E", secondaryHex: "#E2D6B5", tertiaryHex: "#000000", logo: "/logo/nhl-arizona-coyotes-logo-3.png", division: "Central" },
    { label: 'Chicago Blackhawks', id: 16, abbreviation: "CHI", hex: "#CF0A2C", secondaryHex: "#000000", tertiaryHex: "#00833E", logo: "/logo/nhl-chicago-blackhawks-logo-3.png", division: "Central" },
    { label: 'Colorado Avalanche', id: 21, abbreviation: "COL", hex: "#6F263D", secondaryHex: "#05649a", tertiaryHex: "#99a7a9", logo: "/logo/nhl-colorado-avalanche-logo-3.png", division: "Central"},
    { label: 'Dallas Stars', id: 25, abbreviation: "DAL", hex: "#006847", secondaryHex: "#000000", tertiaryHex: "#8F8F8C", logo: "/logo/nhl-dallas-stars-logo-3.png", division: "Central" },
    { label: 'Minnesota Wild', id: 30, abbreviation: "MIN", hex: "#154734", secondaryHex: "#ddc9a5", tertiaryHex: "#AF1E2D", logo: "/logo/nhl-minnesota-wild-logo-3.png", division: "Central" },
    { label: 'Nashville Predators', id: 18, abbreviation: "NSH", hex: "#FFB819", secondaryHex: "#041E42", tertiaryHex: "#c0c0c0", logo: "/logo/nhl-nashville-predators-logo-3.png", division: "Central" },
    { label: 'St. Louis Blues', id: 19, abbreviation: "STL", hex: "#003087", secondaryHex: "#FFB81C", tertiaryHex: "#bd2108", logo: "/logo/nhl-st-louis-blues-logo-3.png", division: "Central" },
    { label: 'Winnipeg Jets', id: 52, abbreviation: "WPG", hex: "#002654", secondaryHex: "#8E9090", tertiaryHex: "#AC162C", logo: "/logo/nhl-winnipeg-jets-logo-3.png", division: "Central" },
    { label: 'Anaheim Ducks', id: 24, abbreviation: "ANA", hex: "#B5985A", secondaryHex: "#F47A38", tertiaryHex: "#321424", logo: "/logo/nhl-anaheim-ducks-logo-3.png", division: "Pacific" },
    { label: 'Calgary Flames', id: 20, abbreviation: "CGY", hex: "#C8242E", secondaryHex: "#F1BE48", tertiaryHex: "#000000", logo: "/logo/nhl-calgary-flames-logo-3.png", division: "Pacific" },
    { label: 'Edmonton Oilers', id: 22, abbreviation: "EDM", hex: "#FF4C00", secondaryHex: "#00205B", tertiaryHex: "#c0c0c0", logo: "/logo/nhl-edmonton-oilers-logo-3.png", division: "Pacific" },
    { label: 'Los Angeles Kings', id: 26, abbreviation: "LAK", hex: "#000000", secondaryHex: "#A3ABAE", tertiaryHex: "#572A84", logo: "/logo/nhl-los-angeles-kings-logo-3.png", division: "Pacific" },
    { label: 'San Jose Sharks', id: 28, abbreviation: "SJS", hex: "#00788A", secondaryHex: "#000000", tertiaryHex: "#EA7200", logo: "/logo/nhl-san-jose-sharks-logo-3.png", division: "Pacific" },
    { label: 'Seattle Kraken', id: 55, abbreviation: "SEA", hex: "#96d8d8", secondaryHex: "#001628", tertiaryHex: "#639FB6", logo: "/logo/nhl-seattle-kraken-logo-3.png", division: "Pacific" },
    { label: 'Vancouver Canucks', id: 23, abbreviation: "VAN", hex: "#152F55", secondaryHex: "#003D7D", tertiaryHex: "#99999A", logo: "/logo/nhl-vancouver-canucks-logo-3.png", division: "Pacific" },
    { label: 'Vegas Golden Knights', id: 54, abbreviation: "VGK", hex: "#B4975A", secondaryHex: "#111111", tertiaryHex: "#333F42", logo: "/logo/nhl-vegas-golden-knights-logo-3.png", division: "Pacific" },
]