import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Title from './Title';

export default function Summary({eventType, amount, startTime, endTime}) {
  const useTo = startTime == null || startTime === "" ? "" : " to ";

  /*
  const handleDateChange = (event, newDate) => {
    onDateChange(newDate);
  };
  */

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={4} sm={2} align="right">
          <Title>{eventType} Total:</Title>
        </Grid>
        <Grid item xs={1} align="left">
          <Typography component="h5" variant="h5">
            {amount}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography color="text.secondary" sx={{ p: 0.8 }}>
            {startTime}{useTo}{endTime}
          </Typography>
        </Grid>
        
      </Grid>
    </React.Fragment>
  );
}

/*
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));
*/

/*
<Grid item xs={4} row>
  <StyledToggleButtonGroup
    color="primary"
    size="small"
    value={dateRange}
    exclusive
    onChange={handleDateChange}
    aria-label="text alignment"
  >
    <ToggleButton value='1d' aria-label="one day">
      1d
    </ToggleButton>
    <ToggleButton value='1w' aria-label="one week">
      1w
    </ToggleButton>
    <ToggleButton value='1m' aria-label="one month">
      1m
    </ToggleButton>
    <ToggleButton value='6m' aria-label="six months">
      6m
    </ToggleButton>
    <ToggleButton value='1s' aria-label="one season">
      season
    </ToggleButton>
  </StyledToggleButtonGroup>
  <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
  <StyledToggleButtonGroup
    size="small"
    value={dateRange}
    onChange={handleDateChange}
    aria-label="text formatting"
  >
    <ToggleButton value="bold" aria-label="bold">
      Bold
    </ToggleButton>
    <ToggleButton value="italic" aria-label="italic">
      Italic
    </ToggleButton>
    <ToggleButton value="underlined" aria-label="underlined">
      Under
    </ToggleButton>
    <ToggleButton value="color" aria-label="color" disabled>
      Color
    </ToggleButton>
  </StyledToggleButtonGroup>
</Grid>
*/