// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getFirestore } from "firebase/firestore";
import 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

//const admin = require("firebase-admin");

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
let app = initializeApp(firebaseConfig);
const functions = getFunctions(app);

// admin is not available on client-side
/*
if (window.location.hostname === "localhost") {
  console.log(
    "Testing locally: hitting local firestore emulator."
  );
  connectFunctionsEmulator(functions, "localhost", 5001);
  app = admin.initializeApp({
    credential: admin.credential.cert(serviceAccount)
  })
} else {
    app = initializeApp(firebaseConfig);
}
*/

if (window.location.hostname === "localhost") {
    console.log(
        "testing locally -- hitting local functions emulator"
    );
    connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}

const analytics = getAnalytics(app);

export {app, functions}