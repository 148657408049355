import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  Typography,
} from '@mui/material'
import Title from './Title';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

const toiComparator = (x, y) => {
    if (x == undefined) {
        return -1;
    }
    if (y == undefined) {
        return 1;
    }
    let a = parseInt(x.split(':')[0])
    let b = parseInt(y.split(':')[0])
    let v = parseInt(x.split(':')[1])
    let w = parseInt(y.split(':')[1])
    if (a !== b) {
        return a - b;
    } else {
        return v - w;
    }
};
const rankComparator = (x, y) => {
    if (x == undefined) {
        return -1;
    }
    if (y == undefined) {
        return 1;
    }
    return parseInt(x.split(' ')[0]) - parseInt(y.split(' ')[0]);
}
const pctComparator = (x, y) => {
    if (x == undefined) {
        return -1;
    }
    if (y == undefined) {
        return 1;
    }
    return parseFloat(x.split(' ')[0]) - parseFloat(y.split(' ')[0]);
}

// TODO: FaceoffPct
const columns = [
  { field: 'playerName', headerName: 'Player', minWidth: 160, headerClassName: 'super-app-theme--header' },
  {
    field: 'team',
    headerName: 'Team',
    minWidth: 80,
    headerClassName: 'super-app-theme--header'
  },
  {
    field: 'position',
    headerName: 'Position',
    minWidth: 90,
    headerClassName: 'super-app-theme--header'
  },
  {
    field: 'Games',
    headerName: 'GP',
    description: 'Games Played',
    width: 50,
    headerClassName: 'super-app-theme--header'
  },
  {
    field: 'TOI',
    headerName: 'TOI',
    description: 'Time on Ice',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: toiComparator
  },
  {
    field: 'TOIPerG',
    headerName: 'TOI/G',
    description: 'Time on Ice Per Game',
    minWidth: 80,
    headerClassName: 'super-app-theme--header',
    sortComparator: toiComparator
  },
  {
    field: 'G',
    headerName: 'G',
    description: 'Goals',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'A',
    headerName: 'A',
    description: 'Assists',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'P',
    headerName: 'P',
    description: 'Points',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'PIM',
    headerName: 'PIM',
    description: 'Penalty Minutes',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'Shots',
    headerName: 'Shots',
    minWidth: 110,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'ShotPct',
    headerName: 'S%',
    description: 'Shot Percentage',
    minWidth: 110,
    headerClassName: 'super-app-theme--header',
    sortComparator: pctComparator
  },
  {
    field: 'Hits',
    headerName: 'Hits',
    minWidth: 110,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'FaceoffPct',
    headerName: 'FO%',
    description: 'Faceoff Percentage',
    minWidth: 90,
    headerClassName: 'super-app-theme--header'
  },
  {
    field: 'BlockedShots',
    headerName: 'BS',
    description: 'Blocked Shots',
    minWidth: 110,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'PlusMinus',
    headerName: '+/-',
    description: 'Plus/Minus',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'PPG',
    headerName: 'PPG',
    description: 'Power Play Goals',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'PPP',
    headerName: 'PPP',
    description: 'Power Play Points',
    minWidth: 80,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'SHG',
    headerName: 'SHG',
    description: 'Shorthanded Goals',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'SHP',
    headerName: 'SHP',
    description: 'Shorthanded Points',
    minWidth: 80,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'GWG',
    headerName: 'GWG',
    description: 'Game Winning Goals',
    minWidth: 90,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'OTG',
    headerName: 'OTG',
    description: 'Overtime Goals',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'Shifts',
    headerName: 'Shifts',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'divide',
    headerName: '|',
    width: 10,
    headerClassName: 'super-app-theme--header',
    valueGetter: (params) => `|`,
  },
  {
    field: 'playoffs',
    headerName: 'Playoffs',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'playoffGames',
    headerName: 'GP',
    description: 'Games Played',
    minWidth: 90,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'playoffTOI',
    headerName: 'TOI',
    description: 'Time on Ice',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: toiComparator
  },
  {
    field: 'playoffTOIPerG',
    headerName: 'TOI/G',
    description: 'Time on Ice Per Game',
    minWidth: 80,
    headerClassName: 'super-app-theme--header',
    sortComparator: toiComparator
  },
  {
    field: 'playoffG',
    headerName: 'G',
    description: 'Goals',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'playoffA',
    headerName: 'A',
    description: 'Assists',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'playoffP',
    headerName: 'P',
    description: 'Points',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'playoffPIM',
    headerName: 'PIM',
    description: 'Penalty Minutes',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'playoffShots',
    headerName: 'Shots',
    minWidth: 110,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'playoffShotPct',
    headerName: 'ShotPct',
    description: 'Shot Percentage',
    minWidth: 110,
    headerClassName: 'super-app-theme--header',
    sortComparator: pctComparator
  },
  {
    field: 'playoffHits',
    headerName: 'Hits',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'playoffFaceoffPct',
    headerName: 'FO%',
    description: 'Faceoff Percentage',
    minWidth: 90,
    headerClassName: 'super-app-theme--header'
  },
  {
    field: 'playoffBlockedShots',
    headerName: 'BlockedShots',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'playoffPlusMinus',
    headerName: '+/-',
    description: 'Plus/Minus',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'playoffPPG',
    headerName: 'PPG',
    description: 'Power Play Goals',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'playoffPPP',
    headerName: 'PPP',
    description: 'Power Play Points',
    minWidth: 80,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'playoffSHG',
    headerName: 'SHG',
    description: 'Shorthanded Goals',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'playoffSHP',
    headerName: 'SHP',
    description: 'Shorthanded Points',
    minWidth: 80,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'playoffGWG',
    headerName: 'GWG',
    description: 'Game Winning Goals',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'playoffShifts',
    headerName: 'Shifts',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
  },
];

function NoResultsOverlay() {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Typography component="h1" variant="h4" display="block">No results found in local filter</Typography>
    </Stack>
  );
}

function NoRowsOverlay() {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Typography component="h1" variant="h4" display="block">No rows found</Typography>
    </Stack>
  );
}

// TODO: tabulate table?
export default function PlayerTable({data, onChangeRowsPerPage, rowsPerPage}) {
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: rowsPerPage,
    page: 0,
  });
  
  const dataGridHeight = data.length < 10 ? 300 : 750
  
  const handleChangePage = (event, newPage) => {
    setPaginationModel({...paginationModel, page: newPage});
  };

  // TODO: low priority - send pagination up to Dashboard?
  const handleChangeRowsPerPage = (event) => {
    onChangeRowsPerPage(parseInt(event.target.value, 10));
    setPaginationModel({pageSize: parseInt(event.target.value, 10), page: 0});
  };

  console.log("in playerTable component. data[0] is " + JSON.stringify(data[0]))
  console.log("in playerTable component. data[1] is " + JSON.stringify(data[1]))

  return (
    <Box sx={{pt:2}}>
      <Title>Player Stats</Title>
      <Box sx={{ height: dataGridHeight, width: '100%' }}>
      <DataGrid
        rows={data}
        columns={columns}
        getRowId={(row) => row.rowId || 'empty'}
        density="compact"
        disableDensitySelector
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[25, 50, 100]}
        slots={{ toolbar: GridToolbar, noRowsOverlay: NoRowsOverlay, noResultsOverlay: NoResultsOverlay }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            printOptions: { disableToolbarButton: true },
            csvOptions: { disableToolbarButton: true },
          },
        }}
        sx={{
          fontSize:'13px',
          '& .MuiDataGrid-columnHeaderTitle': { 
              fontWeight: 'bold !important',
              color: 'rgba(128, 0, 0, 1)'
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: 'rgba(128, 0, 0, 0.08)',
            width: '100%'
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
            width: '0.5rem',
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
            background: 'rgba(128, 0, 0, 0.08)',
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(128, 0, 0, 0.6)',
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
            background: 'rgba(128, 0, 0, 0.8)',
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:active': {
            background: 'rgba(128, 0, 0, 1)',
          },
          '& .MuiInput-root': {
            color: 'rgba(128, 0, 0, 1)',
          },
          '& .MuiInput-root:hover': {
            borderBottom: 'rgba(128, 0, 0, 0.53) !important',
          },
          '& .MuiInputBase-root': {
            color: 'rgba(128, 0, 0, 1)',
            borderBottom: 'rgba(128, 0, 0, 0.26) !important',
          },
          '& .MuiInputBase-root:hover': {
            borderBottom: 'rgba(128, 0, 0, 0.53) !important',
          },
          '& .MuiInputBase-root:active': {
            borderBottom: 'rgba(128, 0, 0, 1) !important',
          },
          '& .MuiTablePagination-root': {
            color: 'rgba(128, 0, 0, 1) !important',
          },
        }}
      />
      </Box>
    </Box>
  );
}