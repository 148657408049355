import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

export default function PrivacyPolicy() {
    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', minWidth: 335 }}>
                        <Typography component="h1" variant="h4" align="center" sx={{ py: 2}}> Privacy Policy </Typography>
                        <Typography component="h1" variant="subtitle1" sx={{ py: 2}}> At fourteenseventeen.com, we recognize that privacy of your personal information is important. Here is information on what types of personal 
                        information we receive and collect when you use and visit fourteenseventeen.com, and how we safeguard your information. We never sell your personal information to third parties.
                        </Typography>
                        <Typography component="h1" variant="h6" sx={{ py: 2}}> Log Files </Typography>
                        <Typography component="h1" variant="subtitle1" sx={{ py: 2}}> 
                        As with most other websites, we collect and use the data contained in log files. The information in the log files includes your IP (internet protocol) address, 
                        your ISP (internet service provider), the browser you used to visit our site (such as Google Chrome or Firefox), the time you visited our site, 
                        and which pages you visited throughout our site.
                        </Typography>
                        <Typography component="h1" variant="h6" sx={{ py: 2}}> Cookies and Web Beacons </Typography>
                        <Typography component="h1" variant="subtitle1" sx={{ py: 2}}> 
                        We use third-party advertisements on russianmachineneverbreaks.com to support our site. Some of these advertisers may use technology such as cookies and web beacons when they advertise on our site, which will also send these 
                        advertisers (such as Google through the Google AdSense program) information including your IP address, your ISP, the browser you used to visit our site, and in some cases, whether you have Flash installed. This is generally 
                        used for geotargeting purposes (showing New York real estate ads to someone in New York, for example) or showing certain ads based on specific sites visited (such as showing cooking ads to someone who frequents cooking sites).
                        </Typography>
                        <Typography component="h1" variant="subtitle1" sx={{ py: 2}}> 
                        You can choose to disable or selectively turn off our cookies or third-party cookies in your browser settings, or by managing preferences in your browser. However, this can affect how you are able 
                        to interact with our site as well as other websites. This could include the inability to login to services or programs, such as logging into forums or accounts.
                        </Typography>
                        <Typography component="h1" variant="h6" sx={{ py: 2}}> Advertising Privacy Statement</Typography>
                        <Typography component="h1" variant="subtitle1" sx={{ py: 2}}> 
                        We publish interest-based advertisements on the Site; that is, ads that are tailored to reflect your interests. To help understand your interests, Google and our other advertising partners will track your 
                        behavior on our website and on other websites across the Internet using cookies.
                        </Typography>
                        <Typography component="h1" variant="subtitle1" sx={{ py: 2}}> 
                        A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. 
                        The identifier is then sent back to the server each time the browser requests a page from the server. Cookies may be either “persistent” cookies or “session” 
                        cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date; 
                        a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed. Cookies do not typically contain any information 
                        that personally identifies a user, but personal information that we store about you may be linked to the information stored in and obtained from cookies.
                        </Typography>
                        <Typography component="h1" variant="subtitle1" sx={{ py: 2}}> 
                        By using this Site, you consent to the use of such cookies and the sharing of data captured by such cookies with Google and our other third-party partners. 
                        You can view, delete or add interest categories associated with your browser by visiting: https://adssettings.google.com. You can also opt-out of the network cookie 
                        using those settings or using the Network Advertising Initiative’s multi-cookie opt-out mechanism at: http://optout.networkadvertising.org. However, these opt-out 
                        mechanisms themselves use cookies, and if you clear the cookies from your browser your opt-out will not be maintained.
                        </Typography>
                        <Typography component="h1" variant="h6" sx={{ py: 2}}> For European Economic Area Residents </Typography>
                        <Typography component="h1" variant="subtitle1" sx={{ py: 2}}> 
                        If you reside in a country in the European Economic Area (EEA), then under the GDPR and applicable data protection laws you have the rights, among other things, to 
                        access your personal data, have us erase it, and/or restrict its further processing. If you wish to access or delete your personal data (if any) maintained by us or
                        Google related to advertising on the Site, you can contact us at contact@fourteenseventeen.com. 
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}