import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SeasonSelect({onChangeSeason, value}) {
    const handleChange = (event) => {
        onChangeSeason(event);
    };

    // TODO: add other season data
    // TODO: include "any" again?
    return (
      <FormControl sx={{m:1, minWidth: 230}} size="medium">
        <InputLabel id="season-input-label">Season</InputLabel>
        <Select
          labelId="season-select-label"
          id="season-select"
          name="season"
          value={value}
          onChange={handleChange}
          label="Season"
        >
          <MenuItem value={20232024}>2023-2024</MenuItem>
          <MenuItem value={20222023}>2022-2023</MenuItem>
          <MenuItem value={20212022}>2021-2022</MenuItem>
          <MenuItem value={20202021}>2020-2021</MenuItem>
          <MenuItem value={20192020}>2019-2020</MenuItem>
          <MenuItem value={20182019}>2018-2019</MenuItem>
        </Select>
      </FormControl>
    );
  }