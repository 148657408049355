import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

export default function Contact() {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', minWidth: 335 }}>
              <Typography component="h1" variant="h5" sx={{ py: 2}}> Contact </Typography>
              <Typography component="h1" variant="subtitle1" sx={{ py: 2}}> We are available by email at contact@fourteenseventeen.com </Typography>
              <Typography component="h1" variant="h5" sx={{ py: 2}}> Help Us Improve </Typography>
              <Typography component="h1" variant="subtitle1" sx={{ py: 2}}> We are working hard to improve the site each day, but we would like to hear from you! 
              If you have any comments, requests, or suggestions, please send us an email at contact@fourteenseventeen.com </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    )
  }