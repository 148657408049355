import * as React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

export default function Footer(props) {
    return (
      <React.Fragment>
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
          <Link underline="hover" href="/about">
              About
          </Link>&nbsp;&nbsp;&nbsp;&nbsp;
          <Link underline="hover" href="/contact">
            Contact Us
          </Link>&nbsp;&nbsp;&nbsp;&nbsp;
          <Link underline="hover" href="/privacy-policy">
            Privacy Policy
          </Link>&nbsp;&nbsp;&nbsp;&nbsp;
          <Link underline="hover" href="/terms-conditions">
            Terms & Conditions
          </Link>
        </Typography>
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
          {'© '}{new Date().getFullYear()}{' FourteenSeventeen.com'}
        </Typography>
      </React.Fragment>
    );
  }