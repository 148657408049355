import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

export default function About() {
    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', minWidth: 335 }}>
                        <Typography component="h1" variant="h5" sx={{ py: 2}}> FourteenSeventeen </Typography>
                        <Typography component="h1" variant="subtitle1" sx={{ py: 2}}> FourteenSeventeen is an independent aggregator of hockey stats for the National Hockey League. It is designed to be a sandbox, displaying stats of every game to the most granular level available, updated daily.
                        FourteenSeventeen dives into the minute-by-minute and second-by-second breakdown of each stat across the season per team and per player to provide insights not found on a box score or game summary.  </Typography>
                        <Typography component="h1" variant="subtitle1" sx={{ py: 2}}> 
                        FourteenSeventeen (1,417) is the number of games played in the regular season (32 teams, playing 82 games against each other equals 1,312 regular season games) plus the number of potential games in the playoffs 
                        (8 first round series, 4 quarterfinal series, 2 conference series, 1 Stanley Cup Final is 15 series total. All 15 series taken to seven games would be 105 playoff games). 1312 + 105 = FourteenSeventeen
                        </Typography>
                        <Typography component="h1" variant="h5" sx={{ py: 2}}> Disclaimer </Typography>
                        <Typography component="h1" variant="subtitle1" sx={{ py: 2}}> 
                        FourteenSeventeen is not in any way affiliated with the National Hockey League (NHL) or any of its respective teams. The NHL logo, team logos, team names, and other trademarks are the property of their respective owners. 
                        If you are the owner of a trademark that is used on this website and would like it removed, please contact us.
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}