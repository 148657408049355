import React from 'react';
import { format as formatUrl } from 'url'
import IconButton from '@mui/material/IconButton';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PersonIcon from '@mui/icons-material/Person';
import Drawer from '@mui/material/Drawer';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';


export default function Login({valid, userName, imageUrl, onLogout}) {
    const [open, setOpen] = React.useState(false);

    const clientId = process.env.REACT_APP_CLIENT_ID
    // redirect_uri should be the full redirect url
    const redirect = window.location.origin + '/oauth/redirect'

    const toggleDrawer = () => {
        setOpen(!open);
      };

    const loginUrl = formatUrl({
        protocol: 'https',
        host: 'patreon.com',
        pathname: '/oauth2/authorize',
        query: {
            response_type: 'code',
            client_id: clientId,
            redirect_uri: redirect,
            state: 'chill'
        }
    })

    const logout = () => {
        toggleDrawer()
        onLogout()
      };

    const drawerWidth = "10rem";

    return (
        <React.Fragment>
            { userName != null ? (
                <React.Fragment>
                    <Link 
                        component="button"
                        variant="body1"
                        underline="none"
                        color="#fff"
                        onClick={toggleDrawer}
                    >
                        {userName} &nbsp;&nbsp;
                    </Link>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{ 
                        mr: 2,
                        }}
                    >
                        { imageUrl != undefined ? (
                            <Avatar alt={userName} src={imageUrl} sx={{ width: 25, height: 25, border: "1px solid black" }}/>
                        ) : (
                            <PersonIcon /> 
                        )
                        }
                    </IconButton>
                </React.Fragment>
            ) : (
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                    sx={{ 
                    mr: 2,
                    }}
                >
                    <SettingsOutlinedIcon />
                </IconButton>
            )}
            <Drawer 
                anchor="right" 
                open={open} 
                onClose={toggleDrawer} 
                onOpen={toggleDrawer}
                sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}>
            <Toolbar />
            <Box sx={{ overflow: 'auto' }}>
            { userName != null ? (
                <ListItemButton sx={{pb: 2, pt: 4}} onClick={() => logout()}>
                  <ListItemText primary="Log Out" />
                </ListItemButton>
            ) : (
                <ListItemButton sx={{pb: 2, pt: 4}} component="a" href={loginUrl}>
                    <ListItemText primary="Login With Patreon" />
                </ListItemButton>
            )}

            { !valid && (
                <ListItemButton component="a" href="https://www.patreon.com/FourteenSeventeen">
                    <ListItemText primary="Become a Patron" />
                </ListItemButton>)}
            </Box>
          </Drawer>
        </React.Fragment>
      );
}