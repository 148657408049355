import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import Dashboard from './components/Dashboard';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getFirestore, doc, getDoc} from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword  } from "firebase/auth";
import {app} from './firebase.js'

// Initialize Firebase
const firestore = getFirestore(app);
const auth = getAuth(app);
const patreonCampaignId = 10897441

if (process.env.REACT_APP_ENV === 'production') {
  console.log = function () {}
}

// sign-in happening at the global level so it only happens once, not during each re-render
signInWithEmailAndPassword(auth, process.env.REACT_APP_EMAIL, process.env.REACT_APP_LOGIN)
  .then(userCredential => {
    // User successfully signed in
    const user = userCredential.user;
  })
  .catch(error => {
    // Handle error
    console.error('Error signing in:', error);
  });

const App = () => {
  const [verified, setVerified] = React.useState(false);
  const [valid, setValid] = React.useState(false);
  const [userName, setUserName] = React.useState(null);
  const midnight = new Date();
  midnight.setHours(24, 0, 0, 0);

  // TODO: secure cookies or pass this information a different way. Maybe session firestore?
  const userId = Cookies.get('patreon_cookie_id')
  const token = Cookies.get('token');
  const cookieViews = Cookies.get('views')
  const views = Number.isInteger(parseInt(cookieViews)) ? cookieViews : 0
  const first_name = Cookies.get('first_name')
  const member = Cookies.get('member')
  const imageUrl = Cookies.get('image_url')
  console.log('userId is ' + userId)
  console.log('token is ' + token)
  console.log('App views is ' + views)
  console.log('member is ' + member)
  console.log('imageUrl is ' + imageUrl)
  if (!Number.isInteger(parseInt(cookieViews))) {
    Cookies.set('views', views, {expires: midnight})
  }
  if (userId != null && !valid && !verified && userName == null) {
    // Allowing initial call to go through. App will check full validity in the background for subsequent calls
    if (member === 'true' || !Boolean(member)) {
      console.log("userId exists and is a member, allow first call to go through")
      setValid(true)
    }
    setUserName(first_name)
  }

  const handleLogout = () => {
    Cookies.remove('patreon_cookie_id');
    Cookies.remove('token');
    Cookies.remove('first_name');
    Cookies.remove('member');
    Cookies.remove('image_url');
    if (process.env.REACT_APP_ENV === 'development') {
      Cookies.remove('views')
    }
    setVerified(false)
    setValid(false)
    setUserName(null)
  }

  const fetchUser = async () => {
       
    console.log('inside fetchUser');
    const today = new Date();
    // TODO: make conditional check the timestamp of the doc
    if (valid && userName !== '' && userName != null && verified) {
      console.log("already logged in!")
      return;
    }
    let user
    try {
      const userRef = doc(firestore, "users", userId);
      user = await getDoc(userRef);
      // TODO: get membership
      // Call firestore to confirm userData
      if (!user.exists()) {
        console.log('No such document for id ' + userId);
        handleLogout()
      } else if (today > new Date(user.data().expires)) {
        console.log('user data has expired and must log in again. Expiration was: ' + new Date(user.data().expires))
        handleLogout()
      } else {
        console.log('User exists! Document data:', user.data());
        // check if token matches and expiration date is still valid
        // TODO: enable membership check
        let isMember = false
        try {
          // TODO: test on former member? test on patreons that have more than one member?
          const sponsorships = user.data()
          if (user.data().relationships.memberships.data != null && user.data().relationships.memberships.data.length > 0 
            && user.data().relationships.memberships.data[0].type === 'member') {
            isMember = true
          }
        } catch (err) {
          console.log('error retrieving user data: ' + err)
          isMember = false;
        }
        Cookies.set('member', isMember, { expires: 7 })
        setVerified(true)
        let isValid = token === user.data().token && user.data().expires != null && today < new Date(user.data().expires) && isMember;
        setValid(isValid);
        setUserName(user.data().attributes.first_name);
      }
    } catch (err) {
      console.log('firestore call failed with err: ' + err)
    }
    console.log('user is ' + user)   
  }

  useEffect(()=>{
    fetchUser();
  }, [])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dashboard valid={valid} userName={userName} imageUrl={imageUrl} onLogout={handleLogout}/>
    </LocalizationProvider>
  );
}

export default App;
