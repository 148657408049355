import * as React from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { useMediaQuery } from 'react-responsive'
import { PieChart, Pie, BarChart, Bar, Cell, Sector, CartesianGrid, ScatterChart, Scatter, LineChart, ComposedChart, Line, Brush, XAxis, YAxis, ZAxis, Label, Tooltip, Legend, ReferenceArea, ReferenceLine, ResponsiveContainer } from 'recharts';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Slider from '@mui/material/Slider';
import { Typography } from '@mui/material';

const TimeSlider = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-valueLabel': {
    fontSize: '1rem',
    fontWeight: 'normal',
    top: -6,
    backgroundColor: 'unset',
    '&:before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
      color: '#800000',
    },
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
}));

export default function Chart({title, data, pieData, defaultCoordinateData, rotatedCoordinateData, statList, ticks, isSecond, isSeason, chartType, chartRadio, statIndex, showTotal, onShowTotal, 
  isRotated, onRotate, useAnimation, keys, colorObject, gameTypeIndex, gameLengthArray, isPlayerBreakdown}) {
  const theme = useTheme();
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [value, setValue] = React.useState([chartRadio === 'game' ? 1 : 0, data[data.length - 1].timeNumber]);
  const [startIndex, setStartIndex] = React.useState(chartRadio === 'game' ? 1 : 0);
  const [endIndex, setEndIndex] = React.useState(data[data.length - 1].timeNumber);
  const [strokeWidth, setStrokeWidth] = React.useState(initializeStrokeWidth);
  const [tickGameMap, setTickGameMap] = React.useState(initializeTickGameMap);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const useAbbreviation = isTabletOrMobile || ticks.length > 22

  console.log("ticks is " + JSON.stringify(ticks));

  // build tickGameMap {0: 1, 50: 1, 70: 2, 100: 2, 120: 3, 150: 3, }
  function initializeTickGameMap() {
    const map = new Map();
    let length = ticks[ticks.length - 1];
    // TODO low priority bug: gameLengthArray becomes undefined after 5 re-submits for some reason?
    let mapIterator
    try {
      mapIterator = gameLengthArray.keys();
    } catch (err) {
      console.log("Error generating Chart: " + err)
      return map;
    }
    let currentKey = mapIterator.next().value;
    let nextKey = mapIterator.next().value;
    for (let i = 0; i < length; i++) {
      if (i === gameLengthArray.get(nextKey)) {
        currentKey = nextKey;
        nextKey = mapIterator.next().value;
      } 
      map.set(i, currentKey);
    }
    return map
  }

  function initializeStrokeWidth() {
    let initialStrokeWidth = {}
    keys.map((e, i) => {
      initialStrokeWidth[keys[i]] = 1.5;
    })
    return initialStrokeWidth;
  }

  const handleSliderChange = (event, newValue) => {
    if (newValue[0] !== startIndex || newValue[1] !== endIndex) {
      setValue(newValue)
      setStartIndex(newValue[0])
      setEndIndex(newValue[1])
    }
  };

  const handleMouseEnter = (o) => {
    const { dataKey } = o;
    setStrokeWidth({...strokeWidth, [dataKey]: 2.5})
  };

  const handleMouseLeave = (o) => {
    setStrokeWidth(initializeStrokeWidth)
  };

  const coordinateData = isRotated ? rotatedCoordinateData : defaultCoordinateData;

  const shapes = ["star", "triangle", "cross", "diamond", "square", "wye", "circle"]

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const onTotalClick = (event) => {
    onShowTotal(event.target.checked);
  }

  const onRotateClick = (event) => {
    onRotate(event.target.checked);
  }

  // entry looks like 'WSH-Alex Ovechkin:43"
  // storing each team/player in an "object"(really a map) to determine which color to use
  // after third color, move to random colors
  const getColor = entry => {
    if (chartType === "pie" && !isSecond && !isSeason) {
      return colorObject[entry.name];
    }
    return colorObject[entry.name.split(":")[0]];
  }

  const seasonFormatter = tick => {
    if (chartRadio === "game") {
      if (gameTypeIndex === 0) {
        return 'Game ' + tick;
      } else {
        let game = tick;
        if (game % 7 === 1) {
          let round = ((game - 1)/7) + 1;
          return "Round " + round;
        } else {
          return game % 7 === 0 ? "G7" : "G" + game % 7;
        }
      }
    } else if (chartRadio === "minuteSeason") {
      let game = tickGameMap.get(tick)
      if (gameTypeIndex === 0) {
        return 'Game ' + game;
      } else {
        if (game % 7 === 1) {
          let round = ((game - 1)/7) + 1;
          return "Round " + round;
        } else {
          return game % 7 === 0 ? "G7" : "G" + game % 7;
        }
      }
    } else if (chartRadio === "season") {
      let game = tickGameMap.get(tick)
      if (gameTypeIndex === 0) {
        return 'Game ' + game;
      } else {
        if (game % 7 === 1) {
          let round = ((game - 1)/7) + 1;
          return "Round " + round;
        } else {
          return game % 7 === 0 ? "G7" : "G" + game % 7;
        }
      }
    }
  }

  const barTickFormatter = tick => {
    return tick.split("/")[0]
  }

  const tickFormatter = tick => {
    if (isSeason) {
      return seasonFormatter(tick)
    }
    const multiplier = isSecond ? 60 : 1;
    const period = (tick - tick % (20 * multiplier))/(20 * multiplier) + 1;
    if (tick === 0) {
      return useAbbreviation ? "1st" : "1st Period";
    } else if (tick === 20 * multiplier) {
      return useAbbreviation ? "2nd" : "2nd Period"
    } else if (tick === 40 * multiplier) {
      return useAbbreviation ? "3rd" : "3rd Period"
    } else if (tick === 60 * multiplier) {
      return useAbbreviation ? "OT" : "End Reg"
    } else if (tick === (20 * (period - 1) * multiplier)) {
      return (period - 3) + "OT";
    } else {
      return isSecond ? secondFormatter(tick) : minuteFormatter(tick);
    }
  }

  const secondFormatter = time => {
    const timeInPeriod = time % 1200;
    const second = timeInPeriod % 60;
    const minute = (timeInPeriod - second) / 60;
    let minuteString = "";
    let secondString = "";
    if (minute < 10) {
      minuteString = "0" + minute.toString();
    } else {
      minuteString = minute.toString();
    }
    if (second < 10) {
      secondString = "0" + second.toString();
    } else {
      secondString = second.toString();
    }
    return minuteString + ":" + secondString;
  }

  const minuteFormatter = time => {
    if (time instanceof String) {
      return time;
    }
    const minute = time % 20;
    let minuteString = "";
    if (minute < 10) {
      minuteString = "0" + minute.toString();
    } else {
      minuteString = minute.toString();
    }
    return minuteString + ":00";
  }

  const barTooltipFormatter = (t) => {
    console.log("bar tooltip t is " + t)
    return Math.abs(t)
  }

  const BarTooltipContent = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      let name = payload[0].payload.name.split('/')[0]
      let color = getColor(payload[0].payload)
      let maxWidth = isTabletOrMobile ? 300 : 500
      return (
        payload[0].payload.negValue != null ? (
        <Paper elevation={4} sx={{ py: 1, maxWidth: {maxWidth}}}>
          <Typography sx={{ px: 2 }} component="h6" variant="subtitle2" color={color} display="block">{`${name}`}</Typography> 
          <Typography sx={{ px: 2 }} component="h6" variant="subtitle2" color={color} display="block">{`${payload[0].payload.stat} : ${payload[0].payload.value}`}</Typography> 
          <Typography sx={{ px: 2 }} component="h6" variant="subtitle2" color={color} display="block">{`${payload[0].payload.oppStat} : ${(payload[0].payload.negValue * -1)}`}</Typography> 
          <Typography sx={{ px: 2 }} component="h6" variant="subtitle2" color={color} display="block">{`Differential : ${(payload[0].payload.value + payload[0].payload.negValue)}`}</Typography>
          <Typography sx={{ px: 2 }} component="h6" variant="subtitle2" color={color} display="block">{`Percentage : ${((payload[0].payload.value/(payload[0].payload.value - payload[0].payload.negValue) * 100).toFixed(2))}%`}</Typography> 
        </Paper>
        ) : (
          <Paper elevation={4} sx={{ py: 1, maxWidth: {maxWidth}}}>
            <Typography sx={{ px: 2 }} component="h6" variant="subtitle2" color={color} display="block">{`${name} : ${payload[0].payload.value}`}</Typography> 
          </Paper>
        )
      );
    }
  
    return null;
  };

  const tooltipFormatter = (t) => {
    let game = 0;
    let time = t;
    let gameFloat = 0;
    let gameString = '';
    if (isSeason) {
      game = tickGameMap.get(t);
      gameString = seasonFormatter(t);
      if (t === gameLengthArray.get(game) || chartRadio === "game") {
        return gameString.includes("Round") ? gameString + ": G1" : gameString;
      } else {
        gameFloat = t - gameLengthArray.get(tickGameMap.get(t))
      }
    }
    if (gameFloat > 0) {
      time = gameFloat
    }
    const multiplier = isSecond ? 60 : 1;
    const timeString = isSecond ? secondFormatter(time) : minuteFormatter(time);
    const period = (time - time % (20 * multiplier))/(20 * multiplier) + 1;
    let periodString = "";
    if (period > 4) {
      periodString = (period - 3) + " OT";
    } else if (period > 3) {
      periodString = "OT";
    } else if (period === 3) {
      periodString = "3rd Period";
    } else if (period === 2) {
      periodString = "2nd Period";
    } else if (period === 1) {
      periodString = "1st Period";
    }
    return timeString + " in " + periodString;
  }

  const pieChartNameFormatter = (name) => {
    let splitName = name.split("-")
    if (splitName[1] == null) {
      return name;
    }
    // handle players with hypenated names
    if (splitName[2] != null) {
      return `${splitName[1]}-${splitName[2]} (${splitName[0]})`;
    }
    return `${splitName[1]} (${splitName[0]})`;
  }

  const CustomizedBarLabel = ({x, y, fill, value}) => {
    let dx = isTabletOrMobile ? 3 : 9
    return (
      <text 
        x={x} 
        y={y} 
        dy={16} 
        dx={dx} 
        fontSize='16'
        fontFamily='sans-serif'
        fill={fill}
        textAnchor="middle">{Math.abs(value)}
      </text>
    )
  };

  const pieChartTimeFormatter = (time) => {
    if (time == null) {
      return "";
    }
    return tooltipFormatter(time);
  }

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const teamDy = isSeason ? -10 : -27;
    const nameDy = isSeason ? 10 : -9;
    const nameSplitArray = pieChartNameFormatter(pieData[index].name.split("/")[0].split(":")[0]).split(" ");
    let teamString = nameSplitArray[2] != null ? nameSplitArray[2].substring(1,4) : nameSplitArray[0];
    const firstName = nameSplitArray[2] != null ? nameSplitArray[0] : "";
    let lastName = nameSplitArray[2] != null ? nameSplitArray[1] : "";
    // handles players like Trevor van Riemsdyk
    if (nameSplitArray[3] != null) {
      teamString = nameSplitArray[3].substring(1,4)
      lastName = nameSplitArray[1] + nameSplitArray[2]
    }
    const fullName = firstName + " " + lastName;
    const fontSize = isTabletOrMobile && fullName.length >= 16 ? Math.ceil(160 / fullName.length) + "px" : "12px";

    return (
      <g>
        {percent * 100 > 3 && !isTabletOrMobile &&  (
          <text x={x} y={y} fill="white" textAnchor={'middle'} dominantBaseline="central" font-size={fontSize}>
            {`${(percent * 100).toFixed(2)}%`}
          </text>
        )}
      </g>
    );
  };

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const lineLength = isTabletOrMobile ? 15 : 30;
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + lineLength) * cos;
    const my = cy + (outerRadius + lineLength) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    const teamDy = isSeason ? -10 : -27;
    const nameDy = isSeason ? 10 : -9;
    const timeSplitArray = pieChartTimeFormatter(payload.name.split(":")[1]).split(" ");
    const timeString = isSeason ? "" : "at " + timeSplitArray[0] + " in";
    const periodString = isSeason ? "" : timeSplitArray[2] + (timeSplitArray[3] != null ? ' ' + timeSplitArray[3] : '')
    const nameSplitArray = pieChartNameFormatter(payload.name.split("/")[0].split(":")[0]).split(" ");
    let teamString = nameSplitArray[2] != null ? nameSplitArray[2].substring(1,4) : nameSplitArray[0];
    const firstName = nameSplitArray[2] != null ? nameSplitArray[0] : "";
    let lastName = nameSplitArray[2] != null ? nameSplitArray[1] : "";
    // handles players like Trevor van Riemsdyk
    if (nameSplitArray[3] != null) {
      teamString = nameSplitArray[3].substring(1,4)
      lastName = nameSplitArray[1] + " " + nameSplitArray[2]
    }
    const fullName = firstName + " " + lastName;
    const fontSize = isTabletOrMobile && fullName.length >= 16 ? Math.ceil(224 / fullName.length) + "px" : "16px";
    const percentageFontSize = isTabletOrMobile ? "14px": "16px";
  
    return (
      <g>
        <text x={cx} y={cy} dy={teamDy} textAnchor="middle" fill={fill}>
          {`${teamString}`}
        </text>
        <text x={cx} y={cy} dy={nameDy} textAnchor="middle" font-size={fontSize} fill={fill}>
          {`${firstName} ${lastName}`}
        </text>
        <text x={cx} y={cy} dy={9} textAnchor="middle" fill={fill}>
          {`${timeString}`}
        </text>
        <text x={cx} y={cy} dy={27} textAnchor="middle" fill={fill}>
          {`${periodString}`}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} font-size={percentageFontSize} fill="#333">{`${value}`}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} font-size={percentageFontSize} fill="#999">
          {`(${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };


  const renderInactiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const lineLength = isTabletOrMobile ? 15 : 30;
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + lineLength) * cos;
    const my = cy + (outerRadius + lineLength) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const textAnchor = cos >= 0 ? 'start' : 'end';
    const teamDy = isSeason ? -10 : -27;
    const nameDy = isSeason ? 10 : -9;
    const timeSplitArray = pieChartTimeFormatter(payload.name.split(":")[1]).split(" ");
    const nameSplitArray = pieChartNameFormatter(payload.name.split(":")[0]).split(" ");
    const teamString = nameSplitArray[2] != null ? nameSplitArray[2].substring(1,4) : nameSplitArray[0];
    const firstName = nameSplitArray[2] != null ? nameSplitArray[0] : "";
    const lastName = nameSplitArray[2] != null ? nameSplitArray[1] : "";
    const fullName = firstName + " " + lastName;
    const fontSize = isTabletOrMobile && fullName.length >= 16 ? Math.ceil(224 / fullName.length) + "px" : "16px";
    const percentageFontSize = isTabletOrMobile ? "14px": "16px";
  
    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} font-size={percentageFontSize} fill="#333">{`${value}`}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} font-size={percentageFontSize} fill="#999">
          {`(${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  const CustomRectangle = props => {
    const rx = isTabletOrMobile ? "55" :"135";
    return (
      <rect
        x={props.x}
        y={props.y}
        width={props.width}
        height={props.height}
        fill="none"
        stroke="black"
        rx={rx}
      />
    );
  };

  const CustomCircle = props => {
    const r = isTabletOrMobile ? "35" :"70";
    const strokeWidth = isTabletOrMobile ? "1": "2";
    return (
      <circle
        stroke="red"
        stroke-width={strokeWidth}
        fill="none"
        cx={props.x + props.width / 2}
        cy={props.y + props.height / 2}
        r={r}
      />
    );
  };

  const CustomHalfRectangle = props => {
    const strokeWidth = isTabletOrMobile ? "1": "2";
    return (
      <svg>
        <defs>
          <clipPath id="round-corner">
              <rect x={props.x - 6} y={props.y} width={props.width - 6} height={props.height} rx="5" stroke="red"/>
          </clipPath>
      </defs>
        <rect
          x={props.x}
          y={props.y}
          width={props.width}
          height={props.height}
          stroke-width={strokeWidth}
          stroke="red"
          fill="none"
        />
      </svg>
    );
  };

  const CustomLineTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      let color = getColor(payload[0].payload)
      let maxWidth = isTabletOrMobile ? 300 : 500
      return (
        <Paper elevation={4} sx={{ py: 1, maxWidth: {maxWidth}}}>
          {payload.map((e, i) => 
            <Typography sx={{ px: 2 }} component="h6" variant="subtitle2" color={color} display="block">{`${payload[i].name} : ${payload[i].value}`}</Typography>
          )} 
          <Typography sx={{ px: 2 }} component="h6" variant="subtitle2" color={color} display="block">{`Description: ${payload[0].payload.description}`}</Typography>
          <Typography sx={{ px: 2 }} component="h6" variant="subtitle2" color={color} display="block">{`Period: ${payload[0].payload.period}`}</Typography>
          <Typography sx={{ px: 2 }} component="h6" variant="subtitle2" color={color} display="block">{`Date: ${payload[0].payload.date}`}</Typography>
        </Paper>
      );
    }
  
    return null;
  };

  const CustomCoordinateTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      let color = getColor(payload[0].payload)
      let maxWidth = isTabletOrMobile ? 300 : 500
      return (
        <Paper elevation={4} sx={{ py: 1, maxWidth: {maxWidth}}}>
          {payload.map((e, i) => 
            <Typography sx={{ px: 2 }} component="h6" variant="subtitle2" color={color} display="block">{`${payload[i].name} : ${payload[i].value}`}</Typography>
          )} 
          <Typography sx={{ px: 2 }} component="h6" variant="subtitle2" color={color} display="block">{`Description: ${payload[0].payload.description}`}</Typography>
          <Typography sx={{ px: 2 }} component="h6" variant="subtitle2" color={color} display="block">{`Period: ${payload[0].payload.period}`}</Typography>
          <Typography sx={{ px: 2 }} component="h6" variant="subtitle2" color={color} display="block">{`Date: ${payload[0].payload.date}`}</Typography>
          <Typography sx={{ px: 2 }} component="h6" variant="subtitle2" color={color} display="block">{`Game Number: ${payload[0].payload.gameNumber}`}</Typography>
        </Paper>
      );
    }
  
    return null;
  };

  const customAxisTick = (props) => {
    const { index, x, y, payload } = props;
    let color = colorObject[payload.value]
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill={color} font-size="14px" transform="rotate(-35)">
          {payload.value.split("/")[0]}
        </text>
      </g>
    );
  }

  const renderColorfulLegendText = (value, entry) => {
    console.log('entry is ' + JSON.stringify(entry))
    console.log('value is ' + value)
    const { name } = entry;
    const color = getColor(entry)
  
    return <span style={{ color }}>{name}: {value}</span>;
  };

  const lineChartAspect = isTabletOrMobile ? 2 : 3;
  const barChartAspect = isTabletOrMobile ? 1.5 : 2.3;
  const coordinateChartAspect = isTabletOrMobile ? 2 : 2.3;
  const lineMinHeight = isTabletOrMobile ? 330 : 480;
  const minHeight = isTabletOrMobile ? 310 : 480;
  const coordinateMinHeight = isTabletOrMobile ? 310 : 630;
  const travellerWidth = isTabletOrMobile ? 20 : 5;
  const lineWidth = isTabletOrMobile ? 1 : 2;
  const pieLegendSize = isTabletOrMobile ? 10 : 15;
  const pieLegendWidth = isTabletOrMobile ? 300: 240;
  const lineLimit = 100
  
  return (
    <React.Fragment>
      <Grid container spacing={2}>
      {chartType === "coordinate" && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={1} sm={0.5}></Grid>
              <Grid item xs={4} sm={2}>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch
                      checked={isRotated}
                      onChange={onRotateClick}
                    />}
                    label="Show In One Zone"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={7} sm={2}>
                {coordinateData.length > 1 && (
                  <FormGroup>
                    <FormControlLabel
                      control={<Switch
                        checked={showTotal}
                        onChange={onTotalClick}
                      />}
                      label="Show All Stats"
                    />
                  </FormGroup>
                )} 
              </Grid>
            </Grid>
            <ResponsiveContainer aspect={coordinateChartAspect} minHeight={coordinateMinHeight}>
              <ScatterChart
                margin={{
                  top: 10,
                  right: 10,
                  bottom: 10,
                  left: 10,
                }}
              >
                <CartesianGrid />
                {/* Convert to distances? */}
                <XAxis type="number" dataKey="x" name="X" unit="ft" ticks={[-100, -75, -50, -25, 0, 25, 50, 75, 100]} />
                <YAxis type="number" dataKey="y" name="Y" unit="ft" ticks={[-50, -25, 0, 25, 50]} />
                <ZAxis type="string" dataKey="name" name="Team" />
                <Tooltip cursor={{ strokeDasharray: '3 3' }} allowEscapeViewBox={{x: false, y: true}} content={<CustomCoordinateTooltip />} />
                <Legend />
                { showTotal ? (coordinateData.map((e, i) => 
                  <Scatter name={statList[i]} data={coordinateData[i]} isAnimationActive={false} fill="black" legendType={shapes[i]} shape={shapes[i]} >
                    {coordinateData[i].map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={getColor(entry)} />
                    ))}
                  </Scatter>
                )) : (<Scatter name={statList[statIndex]} data={coordinateData[statIndex]} isAnimationActive={false} fill="black" legendType={shapes[statIndex]} shape={shapes[statIndex]} >
                    {coordinateData[statIndex].map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={getColor(entry)} />
                    ))}
                  </Scatter>)}
                <Brush dataKey="gameNumber" height={20} stroke={theme.palette.primary.main} width={"90%"} />
                <ReferenceArea
                  x1={-100}
                  x2={100}
                  y1={-42.5}
                  y2={42.5}
                  shape={<CustomRectangle />}
                />
                <ReferenceLine
                  stroke="red"
                  strokeWidth={lineWidth}
                  segment={[{ x: -89, y: -39.5 }, { x: -89, y: 39.5 }]}
                />
                <ReferenceLine
                  stroke="red"
                  strokeWidth={lineWidth}
                  segment={[{ x: 89, y: -39.5 }, { x: 89, y: 39.5 }]}
                />
                <ReferenceLine
                  stroke="red"
                  strokeWidth={2}
                  segment={[{ x: 0, y: -42.5 }, { x: 0, y: 42.5 }]}
                />
                <ReferenceLine
                  stroke="blue"
                  strokeWidth={2}
                  segment={[{ x: 25, y: -42.5 }, { x: 25, y: 42.5 }]}
                />
                <ReferenceLine
                  stroke="blue"
                  strokeWidth={2}
                  segment={[{ x: -25, y: -42.5 }, { x: -25, y: 42.5 }]}
                />
                <ReferenceArea
                  x1={0}
                  x2={0}
                  shape={<CustomCircle />}
                />
                <ReferenceArea
                  x1={-69}
                  x2={-69}
                  y1={-22}
                  y2={-22}
                  shape={<CustomCircle />}
                />
                <ReferenceArea
                  x1={-69}
                  x2={-69}
                  y1={22}
                  y2={22}
                  shape={<CustomCircle />}
                />
                <ReferenceArea
                  x1={69}
                  x2={69}
                  y1={-22}
                  y2={-22}
                  shape={<CustomCircle />}
                />
                <ReferenceArea
                  x1={69}
                  x2={69}
                  y1={22}
                  y2={22}
                  shape={<CustomCircle />}
                />
                <ReferenceArea
                  x1={0}
                  x2={0}
                  shape={<CustomCircle />}
                />
                <ReferenceArea
                  x1={-83}
                  x2={-89}
                  y1={-6}
                  y2={6}
                  shape={<CustomHalfRectangle />}
                />
                <ReferenceArea
                  x1={83}
                  x2={89}
                  y1={-6}
                  y2={6}
                  shape={<CustomHalfRectangle />}
                />
              </ScatterChart>
            </ResponsiveContainer>
          </Grid>
        )}
        {chartType === "pie" && (
          <Grid item xs={12}>
            <ResponsiveContainer aspect={lineChartAspect} minHeight={minHeight}>
              <PieChart>
                <Pie
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  labelLine={false}
                  label={renderCustomizedLabel}
                  data={pieData}
                  dataKey="value"
                  isAnimationActive={useAnimation}
                  cx="35%"
                  cy="50%"
                  innerRadius="42%"
                  outerRadius="80%"
                  onMouseEnter={onPieEnter}
                >
                  {pieData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={getColor(entry)} strokeWidth={0.25} />
                  ))}
                </Pie>
                <Brush dataKey="name" height={20} stroke='#000000' />
                {(chartRadio === "season" || chartRadio === "minuteSeason" || chartRadio === "game") && (
                  <Legend layout="vertical" verticalAlign='middle' align="left" width={pieLegendWidth} payload={pieData.slice(0,pieLegendSize).map(
                    (entry, index) => ({
                      id: entry.name,
                      type: "square",
                      value: `${entry.name.split("/")[0]}: ${entry.value}`,
                      color: getColor(entry)
                    }))}/>
                  )}
              </PieChart>
            </ResponsiveContainer>
          </Grid>
        )}
        {chartType === "bar" && (
          <Grid item xs={12}>
            <ResponsiveContainer aspect={barChartAspect} minHeight={minHeight}>
              <BarChart
                data={pieData.slice(0,25)}
                margin={{
                  top: 8,
                  right: 0,
                  bottom: 100,
                  left: 40,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" tick={customAxisTick} interval={0}/>
                <YAxis />
                <Tooltip cursor={{ strokeDasharray: '3 3' }} content={<BarTooltipContent/>} />
                <Bar
                  dataKey="value"
                  label={{ position: 'top' }}
                >
                  {pieData.slice(0,25).map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={getColor(entry)} />
                  ))}
                </Bar>
                {
                  pieData[0].negValue != null && (
                    <Bar
                      dataKey="negValue"
                      label={<CustomizedBarLabel/>}
                    >
                      {pieData.slice(0,25).map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={getColor(entry)} />
                      ))}
                    </Bar>
                  )
                }
              </BarChart>
            </ResponsiveContainer>
          </Grid>
        )}
        {chartType === "line" && (
          <React.Fragment>
        <Grid item xs={12}>
          <ResponsiveContainer aspect={lineChartAspect} minHeight={lineMinHeight}>
            <ComposedChart
              data={data}
              margin={{
                top: 8,
                right: 0,
                bottom: 0,
                left: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />          
              <XAxis
                xAxisId="0"
                allowDataOverflow
                dataKey="timeNumber"
                type="number"
                interval="preserveStart"
                domain={value}
                ticks={ticks}
                tickFormatter={tickFormatter}
                stroke={theme.palette.text.secondary}
                style={theme.typography.body2}
              />
              <YAxis
                interval={1}
                allowDataOverflow
                stroke={theme.palette.text.secondary}
                style={theme.typography.body2}
              />
              <ZAxis
                dataKey="time"
                ticks={ticks}
                tickFormatter={tickFormatter}
                stroke={theme.palette.text.secondary}
                style={theme.typography.body2}
              />
              <Tooltip 
                cursor={{ strokeDasharray: '3 3' }} 
                labelFormatter={(value) => tooltipFormatter(value)} 
                itemSorter={(item) => {
                  return (item.value) * -1;
                }}
              />
              {(!isPlayerBreakdown || !isTabletOrMobile) && <Legend onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />}
              {keys.slice(0,lineLimit).map((e, i) =>
                <Line
                  isAnimationActive={useAnimation}
                  type="monotone"
                  dataKey={keys[i]}
                  strokeWidth={strokeWidth[keys[i]]}
                  stroke={colorObject[keys[i]]}
                  dot={false}
                  name={keys[i].split("/")[0]}
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>
        </Grid>
          <Grid item xs={12} align="right">
            <Slider
              getAriaLabel={() => 'Temperature range'}
              value={value}
              min={isSeason ? 1 : 0}
              max={data[data.length - 1].timeNumber}
              onChange={handleSliderChange}
              valueLabelDisplay="auto"
              valueLabelFormat={tooltipFormatter}
              sx={{width: "95%", mb: 2}}
            />
          </Grid>
        </React.Fragment>
        )}
      </Grid>
    </React.Fragment>
  );
}