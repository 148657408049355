import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  Typography,
} from '@mui/material'
import Title from './Title';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const columns = [
  { field: 'playerName', headerName: 'Player', minWidth: 180, headerClassName: 'super-app-theme--header' },
  {
    field: 'playerTriCode',
    headerName: 'Team',
    minWidth: 110,
    headerClassName: 'super-app-theme--header'
  },
  {
    field: 'playerPosition',
    headerName: 'Position',
    minWidth: 120,
    headerClassName: 'super-app-theme--header'
  },
  {
    field: 'eventAction',
    headerName: 'Event',
    minWidth: 150,
    headerClassName: 'super-app-theme--header'
  },
  {
    field: 'eventSecondaryType',
    headerName: 'Detail',
    minWidth: 120,
    headerClassName: 'super-app-theme--header'
  },
  {
    field: 'side',
    headerName: 'Side',
    minWidth: 100,
    headerClassName: 'super-app-theme--header'
  },
  {
    field: 'gameType',
    headerName: 'Game Type',
    minWidth: 150,
    headerClassName: 'super-app-theme--header',
    valueGetter: (params) =>
      {return params.value === "P" ? "Playoff" : "Regular"},
  },
  {
    field: 'gameDay',
    headerName: 'Date',
    minWidth: 120,
    headerClassName: 'super-app-theme--header'
  },
  {
    field: 'period',
    headerName: 'Period',
    minWidth: 120,
    headerClassName: 'super-app-theme--header'
  },
  {
    field: 'periodTime',
    headerName: 'Time',
    minWidth: 110,
    headerClassName: 'super-app-theme--header'
  },
  {
    field: 'coordinates',
    headerName: 'Coordinates',
    description: 'This shows the X,Y coordinates of where the play occurred',
    sortable: false,
    minWidth: 130,
    headerClassName: 'super-app-theme--header',
    valueGetter: (params) =>
     `${params.row.coordinateX}, ${params.row.coordinateY}`,
  },
];

function NoResultsOverlay() {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Typography component="h1" variant="h4" display="block">No results found in local filter</Typography>
    </Stack>
  );
}

function NoRowsOverlay() {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Typography component="h1" variant="h4" display="block">No rows found</Typography>
    </Stack>
  );
}

// TODO: tabulate table?
export default function EventTable({data, onChangeRowsPerPage, rowsPerPage}) {
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('gameDay')
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: rowsPerPage,
    page: 0,
  });
  
  const dataGridHeight = data.length < 10 ? 300 : 750
  
  const handleChangePage = (event, newPage) => {
    setPaginationModel({...paginationModel, page: newPage});
  };

  // TODO: low priority - send pagination up to Dashboard?
  const handleChangeRowsPerPage = (event) => {
    onChangeRowsPerPage(parseInt(event.target.value, 10));
    setPaginationModel({pageSize: parseInt(event.target.value, 10), page: 0});
  };

  return (
    <React.Fragment>
      <Title>Table of Events</Title>
      <Box sx={{ height: dataGridHeight, width: '100%' }}>
      <DataGrid
        rows={data.slice().sort(getComparator(order, orderBy))}
        columns={columns}
        getRowId={(row) => row.eventId || 'empty'}
        density="compact"
        disableDensitySelector
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[25, 50, 100]}
        slots={{ toolbar: GridToolbar, noRowsOverlay: NoRowsOverlay, noResultsOverlay: NoResultsOverlay }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            printOptions: { disableToolbarButton: true },
            csvOptions: { disableToolbarButton: true },
          },
        }}
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': { 
              fontWeight: 'bold !important',
              color: 'rgba(128, 0, 0, 1)'
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: 'rgba(128, 0, 0, 0.08)',
            width: '100%'
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
            width: '0.5rem',
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
            background: 'rgba(128, 0, 0, 0.08)',
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(128, 0, 0, 0.6)',
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
            background: 'rgba(128, 0, 0, 0.8)',
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:active': {
            background: 'rgba(128, 0, 0, 1)',
          },
          '& .MuiInput-root': {
            color: 'rgba(128, 0, 0, 1)',
          },
          '& .MuiInput-root:hover': {
            borderBottom: 'rgba(128, 0, 0, 0.53) !important',
          },
          '& .MuiInputBase-root': {
            color: 'rgba(128, 0, 0, 1)',
            borderBottom: 'rgba(128, 0, 0, 0.26) !important',
          },
          '& .MuiInputBase-root:hover': {
            borderBottom: 'rgba(128, 0, 0, 0.53) !important',
          },
          '& .MuiInputBase-root:active': {
            borderBottom: 'rgba(128, 0, 0, 1) !important',
          },
          '& .MuiTablePagination-root': {
            color: 'rgba(128, 0, 0, 1) !important',
          },
        }}
      />
      </Box>
    </React.Fragment>
  );
}