import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

export const mainListItems = (
  <React.Fragment>
    <ListItemButton component="a" href="/about">
      <ListItemText primary="About" primaryTypographyProps={{
                    fontWeight: 'bold',
                  }}/>
    </ListItemButton>
    <ListItemButton component="a" href="/contact">
      <ListItemText primary="Contact" primaryTypographyProps={{
                    fontWeight: 'bold',
                  }}/>
    </ListItemButton>
    <ListItemButton component="a" href="/privacy-policy">
      <ListItemText primary="Privacy Policy" primaryTypographyProps={{
                    fontWeight: 'bold',
                  }}/>
    </ListItemButton>
    <ListItemButton component="a" href="/terms-conditions">
      <ListItemText primary="Terms & Conditions" primaryTypographyProps={{
                    fontWeight: 'bold',
                  }}/>
    </ListItemButton>
  </React.Fragment>
);