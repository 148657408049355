import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  Typography,
} from '@mui/material'
import Title from './Title';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

const toiComparator = (x, y) => {
  if (x == undefined) {
    return -1;
  }
  if (y == undefined) {
      return 1;
  }
  let a = parseInt(x.split(':')[0])
  let b = parseInt(y.split(':')[0])
  let v = parseInt(x.split(':')[1])
  let w = parseInt(y.split(':')[1])
  if (a !== b) {
      return a - b;
  } else {
      return v - w;
  }
};
const rankComparator = (x, y) => {
  if (x == undefined) {
      return -1;
  }
  if (y == undefined) {
      return 1;
  }
  return parseInt(x.split(' ')[0]) - parseInt(y.split(' ')[0]);
}
const pctComparator = (x, y) => {
  if (x == undefined) {
      return -1;
  }
  if (y == undefined) {
      return 1;
  }
  return parseFloat(x.split(' ')[0]) - parseFloat(y.split(' ')[0]);
}

// TODO: Even, PP, SH Save Percentage?
const columns = [
  { field: 'playerName', headerName: 'Player', minWidth: 180, headerClassName: 'super-app-theme--header' },
  {
    field: 'team',
    headerName: 'Team',
    minWidth: 80,
    headerClassName: 'super-app-theme--header'
  },
  {
    field: 'position',
    headerName: 'Position',
    minWidth: 80,
    headerClassName: 'super-app-theme--header'
  },
  {
    field: 'Games',
    headerName: 'GP',
    description: 'Games Played',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'GamesStarted',
    headerName: 'GS',
    description: 'Games Started',
    minWidth: 60,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'TOI',
    headerName: 'TOI',
    description: 'Time on Ice',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: toiComparator
  },
  {
    field: 'wins',
    headerName: 'Wins',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'losses',
    headerName: 'Losses',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'ot',
    headerName: 'OTL',
    description: 'Overtime Loss',
    minWidth: 100,
    headerClassName: 'super-app-theme--header'
  },
  {
    field: 'saves',
    headerName: 'Saves',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'savePct',
    headerName: 'Save%',
    description: 'Save Percentage',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: pctComparator
  },
  {
    field: 'shutouts',
    headerName: 'SO',
    description: 'Shutouts',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'shotsAgainst',
    headerName: 'SA',
    description: 'Shots Against',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'goalsAgainst',
    headerName: 'GA',
    description: 'Goals Against',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'GAA',
    headerName: 'GAA',
    description: 'Goals Against Average',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: pctComparator
  },
  {
    field: 'PIM',
    headerName: 'PIM',
    description: 'Penalty Minutes',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'G',
    headerName: 'G',
    description: 'Goals',
    minWidth: 80,
    headerClassName: 'super-app-theme--header'
  },
  {
    field: 'A',
    headerName: 'A',
    description: 'Assists',
    minWidth: 80,
    headerClassName: 'super-app-theme--header'
  },
  {
    field: 'P',
    headerName: 'P',
    description: 'Points',
    minWidth: 80,
    headerClassName: 'super-app-theme--header'
  },
  {
    field: 'divide',
    headerName: '|',
    width: 10,
    headerClassName: 'super-app-theme--header',
    valueGetter: (params) => `|`,
  },
  {
    field: 'playoffs',
    headerName: 'Playoffs',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'playoffGames',
    headerName: 'Games',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'playoffGamesStarted',
    headerName: 'GS',
    description: 'Games Started',
    minWidth: 60,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'playoffTOI',
    headerName: 'TOI',
    description: 'Time on Ice',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: toiComparator
  },
  {
    field: 'playoffWins',
    headerName: 'Wins',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'playoffLosses',
    headerName: 'Losses',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'playoffSaves',
    headerName: 'Saves',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'playoffSavePct',
    headerName: 'Save%',
    description: 'Save Percentage',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: pctComparator
  },
  {
    field: 'playoffShutouts',
    headerName: 'SO',
    description: 'Shutouts',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'playoffShotsAgainst',
    headerName: 'SA',
    description: 'Shots Against',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'playoffGoalsAgainst',
    headerName: 'GA',
    description: 'Goals Against',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'playoffGAA',
    headerName: 'GAA',
    description: 'Goals Against Average',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: pctComparator
  },
  {
    field: 'playoffPIM',
    headerName: 'PIM',
    description: 'Penalty Minutes',
    minWidth: 100,
    headerClassName: 'super-app-theme--header',
    sortComparator: rankComparator
  },
  {
    field: 'playoffG',
    headerName: 'G',
    description: 'Goals',
    minWidth: 80,
    headerClassName: 'super-app-theme--header'
  },
  {
    field: 'playoffA',
    headerName: 'A',
    description: 'Assists',
    minWidth: 80,
    headerClassName: 'super-app-theme--header'
  },
  {
    field: 'playoffP',
    headerName: 'P',
    description: 'Points',
    minWidth: 80,
    headerClassName: 'super-app-theme--header'
  },
];

function NoResultsOverlay() {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Typography component="h1" variant="h4" display="block">No results found in local filter</Typography>
    </Stack>
  );
}

function NoRowsOverlay() {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Typography component="h1" variant="h4" display="block">No rows found</Typography>
    </Stack>
  );
}

// TODO: tabulate table?
export default function GoalieTable({data, onChangeRowsPerPage, rowsPerPage}) {
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: rowsPerPage,
    page: 0,
  });
  
  const dataGridHeight = data.length < 10 ? 300 : 750
  
  const handleChangePage = (event, newPage) => {
    setPaginationModel({...paginationModel, page: newPage});
  };

  // TODO: low priority - send pagination up to Dashboard?
  const handleChangeRowsPerPage = (event) => {
    onChangeRowsPerPage(parseInt(event.target.value, 10));
    setPaginationModel({pageSize: parseInt(event.target.value, 10), page: 0});
  };

  return (
    <Box sx={{pt:2}}>
      <Title>Goalie Stats</Title>
      <Box sx={{ height: dataGridHeight, width: '100%' }}>
      <DataGrid
        rows={data}
        columns={columns}
        getRowId={(row) => row.rowId || 'empty'}
        density="compact"
        disableDensitySelector
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[25, 50, 100]}
        slots={{ toolbar: GridToolbar, noRowsOverlay: NoRowsOverlay, noResultsOverlay: NoResultsOverlay }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            printOptions: { disableToolbarButton: true },
            csvOptions: { disableToolbarButton: true },
          },
        }}
        sx={{
          
          '& .MuiDataGrid-columnHeaderTitle': { 
              fontWeight: 'bold !important',
              color: 'rgba(128, 0, 0, 1)'
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: 'rgba(128, 0, 0, 0.08)',
            width: '100%'
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
            width: '0.5rem',
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
            background: 'rgba(128, 0, 0, 0.08)',
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(128, 0, 0, 0.6)',
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
            background: 'rgba(128, 0, 0, 0.8)',
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:active': {
            background: 'rgba(128, 0, 0, 1)',
          },
          '& .MuiInput-root': {
            color: 'rgba(128, 0, 0, 1)',
          },
          '& .MuiInput-root:hover': {
            borderBottom: 'rgba(128, 0, 0, 0.53) !important',
          },
          '& .MuiInputBase-root': {
            color: 'rgba(128, 0, 0, 1)',
            borderBottom: 'rgba(128, 0, 0, 0.26) !important',
          },
          '& .MuiInputBase-root:hover': {
            borderBottom: 'rgba(128, 0, 0, 0.53) !important',
          },
          '& .MuiInputBase-root:active': {
            borderBottom: 'rgba(128, 0, 0, 1) !important',
          },
          '& .MuiTablePagination-root': {
            color: 'rgba(128, 0, 0, 1) !important',
          },
        }}
      />
      </Box>
    </Box>
  );
}