import * as React from 'react';
import { styled, createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import { useMediaQuery } from 'react-responsive'
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import { mainListItems } from './listItems';
import Skeleton from '@mui/material/Skeleton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { BrowserRouter as Router, Routes, Route, useParams, useLocation }
    from 'react-router-dom';
import Chart from './Chart';
import Summary from './Summary';
import EventTable from './EventTable';
import GoalieTable from './GoalieTable';
import PlayerTable from './PlayerTable';
import Form from './Form';
import Contact from './Contact';
import Footer from './Footer';
import About from './About';
import PrivacyPolicy from './PrivacyPolicy';
import Login from './Login';
import TermsAndConditions from './TermsAndConditions';
import { teamInfo } from '../util/TeamInfo';
import { teamSelection, viewThreshold } from '../util/constants.js';
import { BreakfastDiningOutlined } from '@mui/icons-material';

let defaultTheme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#800000',
      darker: '#7A0000',
    },
    neutral: {
      main: '#7D8491',
      contrastText: '#fff',
    },
    secondary: {
      main: '#E67F0D',
      darker: '#C16C0B'
    }
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        maxWidthXl: {
          maxWidth: "100rem",
        },
      },
    },
  },
});

defaultTheme = responsiveFontSizes(defaultTheme);

if (process.env.REACT_APP_ENV === 'production') {
  console.log = function () {}
}

// add boolean for first form call
export default function Dashboard({valid, userName, imageUrl, onLogout}) {
  const [open, setOpen] = React.useState(false);
  const [chartTitle, setChartTitle] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [showAlert, setShowAlert] = React.useState(false);
  const [colorObject, setColorObject] = React.useState({});
  const [keys, setKeys] = React.useState([]);
  const [eventList, setEventList] = React.useState([]);
  const [gameStartArray, setGameStartArray] = React.useState([]);
  const [chartSecondData, setChartSecondData] = React.useState([[[]]]);
  const [chartMinuteData, setChartMinuteData] = React.useState([[[]]]);
  const [chartMinuteSeasonData, setChartMinuteSeasonData] = React.useState([[[]]]);
  const [chartSeasonData, setChartSeasonData] = React.useState([[[]]]);
  const [chartGameSeasonData, setChartGameSeasonData] = React.useState([[[]]]);
  const [chartPieSecondData, setChartPieSecondData] = React.useState([[[]]]);
  const [chartPieMinuteData, setChartPieMinuteData] = React.useState([[[]]]);
  const [chartPieSeasonData, setChartPieSeasonData] = React.useState([[[]]]);
  const [chartCoordinateSeasonData, setChartCoordinateSeasonData] = React.useState([[[]]]);
  const [chartCoordinateRotatedSeasonData, setChartCoordinateRotatedSeasonData] = React.useState([[[]]]);
  const [tableData, setTableData] = React.useState([]);
  const [playerTableData, setPlayerTableData] = React.useState([]);
  const [goalieTableData, setGoalieTableData] = React.useState([]);
  const [eventType, setEventType] = React.useState([]);
  const [amount, setAmount] = React.useState([[]]);
  const [startTime, setStartTime] = React.useState(null);
  const [endTime, setEndTime] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isPlayerBreakdown, setIsPlayerBreakdown] = React.useState(false);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [lineChartRadio, setLineChartRadio] = React.useState("minute");
  const [pieChartRadio, setPieChartRadio] = React.useState("season");
  const [sideRadio, setSideRadio] = React.useState("total");
  const [gameRadio, setGameRadio] = React.useState("regular");
  const [homeAwayIndex, setHomeAwayIndex] = React.useState(0);
  const [gameTypeIndex, setGameTypeIndex] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [showTotal, setShowTotal] = React.useState(false);
  const [isRotated, setIsRotated] = React.useState(false);
  const [useAnimation, setUseAnimation] = React.useState(false);
  const [statPanelExpanded, setStatPanelExpanded] = React.useState(false);
  const [filterPanelExpanded, setFilterPanelExpanded] = React.useState(false);
  const [secondTickArray, setSecondTickArray] = React.useState([[0, 300, 600, 900, 1200, 1500, 1800, 2100, 2400, 2700, 3000, 3300, 3600, 3900], [0, 300, 600, 900, 1200, 1500, 1800, 2100, 2400, 2700, 3000, 3300, 3600, 3900]]);
  const [minuteTickArray, setMinuteTickArray] = React.useState([[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65], [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65]]);
  const [seasonTickArray, setSeasonTickArray] = React.useState([[]]);
  const [seasonMinuteTickArray, setSeasonMinuteTickArray] = React.useState([[]]);
  const [seasonPeriodTickArray, setSeasonPeriodTickArray] = React.useState([[]]);
  const [teamList, setTeamList] = React.useState([{ label: 'Florida Panthers', id: 13, abbreviation: "FLA", hex: "#C8102E", secondaryHex: "#BC955C", tertiaryHex: "#041E42", logo: "/logo/nhl-florida-panthers-logo.png", division: "Atlantic" },
  { label: 'Vegas Golden Knights', id: 54, abbreviation: "VGK", hex: "#B4975A", secondaryHex: "#111111", tertiaryHex: "#333F42", logo: "/logo/nhl-vegas-golden-knights-logo.png", division: "Pacific" }])
  const [season, setSeason] = React.useState('20232024');
  const [selectedPlayerList, setSelectedPlayerList] = React.useState([]);
  const [formStatList, setFormStatList] = React.useState(['GOAL']);
  const [playerOptionsList, setPlayerOptionsList] = React.useState([]);
  const [positionList, setPositionList] = React.useState([]);
  const [isLanding, setIsLanding] = React.useState(true);
  const [hasURLParams, setHasURLParams] = React.useState(false);
  const [menuName, setMenuName] = React.useState(null);
  const [checkboxes, setCheckboxes] = React.useState({
    hit: false,
    hitTaken: false,
    fight: false,
    shot: false,
    blockedShot: false,
    shotTakenBlocked: false,
    goal: true,
    goalAgainst: false,
    save: false,
    powerPlayGoal: false,
    shorthandedGoal: false,
    gameWinningGoal: false,
    assist: false,
    point: false,
    primaryAssist: false,
    secondaryAssist: false,
    missedShot: false,
    faceoff: false,
    faceoffWin: false,
    faceoffLoss: false,
    giveaway: false,
    takeaway: false,
    penalty: false,
    penaltyDrawn: false,
  })
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  let views = Cookies.get('views')

  const toggleDrawer = () => {
    setShowAlert(false);
    setMenuName(null)
    setOpen(!open);
  };
  const handleLoading = React.useCallback((loading) => {
    setIsLanding(false)
    setIsLoading(loading);
  },[])

  const handleStartTime = (event) => {
    if(event.format('YYYY-MM-DD') == "Invalid Date" || event < 0) {
      console.log('invalid start date')
    } else {
      setStartTime(event == null ? null : event.format('YYYY-MM-DD'))
    }
  }
  const handleEndTime = (event) => {
    if(event.format('YYYY-MM-DD') == "Invalid Date" || event < 0) {
      console.log('invalid end date')
    } else {
      setEndTime(event == null ? null : event.format('YYYY-MM-DD'))
    }
  }

  const handleTeamList = (teamList) => {
    setTeamList(teamList);
    // when team list is empty, empty playerList as well
    if (teamList.length === 0) {
      setSelectedPlayerList([]);
    }
  }

  const handlePositionList = (positionList) => {
    setPositionList(positionList);
  }

  const handleStatList = (formStatList) => {
    setFormStatList(formStatList);
  }

  const handleStatPanel = (event, isExpanded) => {
    setStatPanelExpanded(isExpanded);
  }

  const handleFilterPanel = (event, isExpanded) => {
    setFilterPanelExpanded(isExpanded);
  }

  const updatePlayerOptionsList = (playerList) => {
    let sortedList = playerList.sort(function(a,b){
      // here a , b is whole object, you can access its property
      // convert both to lowercase
      // get last names
      let v = a.fullName.split(" ")[1].toLowerCase();
      let w = b.fullName.split(" ")[1].toLowerCase();
    
      //compare the player names by last name
      if(v>w){return 1;}
      if(v<w){return -1;}
      return 0;
    })
    setPlayerOptionsList(sortedList);
    // when team list is updated, check existing selectedPlayerList and remove players no longer in the player list options
    let updatedSelectedPlayerList = selectedPlayerList.filter(selectedPlayer => playerList.some(playerOption => selectedPlayer.id === playerOption.id))
    setSelectedPlayerList(updatedSelectedPlayerList);

  }

  const handleSeason = (season) => {
    // remove existing start/end dates, if any
    setStartTime(null)
    setEndTime(null)
    setSeason(season)
  }

  const handleCheckbox = (checkboxes) => {
    setCheckboxes(checkboxes)
  }

  const handlePlayerBreakdown = (event) => {
    setIsPlayerBreakdown(event.target.checked)
  }

  const handleSelectedPlayerList = (event, newValue) => {
    // TODO: handle null removal better?
    if (newValue.length > 1 && newValue[0].fullName === "Any") {
      newValue.shift();
    }
    setSelectedPlayerList(newValue == null ? [{ fullName: 'Any', id: "" }] : newValue)
  }

  const handleShowTotal = (totalBoolean) => {
    setUseAnimation(false);
    setShowTotal(totalBoolean);
  }

  const handleRotate = (rotateBoolean) => {
    setIsRotated(rotateBoolean);
  }

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
  }

  const handleTabChange = (event, newTabIndex) => {
    setUseAnimation(false);
    setTabIndex(newTabIndex);
  };

  const handleLineChartRadioChange = (event) => {
    setUseAnimation(false);
    setLineChartRadio(event.target.value);
  };

  const handlePieChartRadioChange = (event) => {
    setUseAnimation(false);
    setPieChartRadio(event.target.value);
  };

  /*
  const handleDateRangeChange = (newDateRange) => {
    console.log('handling date range change');
    let end = dayjs(new Date()).format('YYYY-MM-DD')
    let start = dayjs(new Date());
    if (newDateRange === '1d') {
      start.subtract(1, 'day');
    } else if (newDateRange === '1w') {
      start.subtract(1, 'week')
    } else if (newDateRange === '1m') {
      start.subtract(1, 'month')
    } else if (newDateRange === '6m') {
      start.subtract(6, 'month')
    } else if (newDateRange === '1s') {
      //TODO: add map for start and end of seasons?
      //full season doesn't need start and end time
      end = null
      start == null
    }
    setDateRange(newDateRange);
    setStartTime(dayjs(new Date()).subtract())
    setEndTime(end.format('YYYY-MM-DD'))
    // run form submit?
  };
  */

  // total = 0, home = 1, away = 2
  const handleSideRadioChange = (event, newSideRadio) => {
    let index = 0;
    if (newSideRadio === "home") {
      index = 1;
    } else if (newSideRadio === "away") {
      index = 2;
    }
    setUseAnimation(false);
    setSideRadio(newSideRadio);
    setHomeAwayIndex(index);
  };

  const handleGameRadioChange = (event, newGameRadio) => {
    let index = 0;
    if (newGameRadio === "playoff") {
      index = 1;
    }
    setUseAnimation(true);
    setGameRadio(newGameRadio);
    setGameTypeIndex(index);
  };

  // Generate Event Table Data
  const createTableData = (eventId, playerName, playerTriCode, action, playerPosition, gameDay, period, periodTime, eventSecondaryType, side, gameType, coordinateX, coordinateY, strengthCode) => {
    let eventAction = action
    if (action === "Goal") {
      if (strengthCode === "PPG") {
        eventAction = "Power Play Goal"
      } else if (strengthCode === "SHG") {
        eventAction = "Shorthanded Goal"
      }
    }
    return { eventId, playerName, playerTriCode, eventAction, playerPosition, gameDay, period, periodTime, eventSecondaryType, side, gameType, coordinateX, coordinateY };
  }

  // Generate Player Table Data
  const createPlayerTableData = (rowReg, rowPlayoff) => {
    let rowId = uuidv4()
    let season = rowReg.season.split('#')[0]
    if (rowPlayoff.games != null) {
      return { rowId, playerId: rowReg.playerId, season, playerName: rowReg.playerName, team: rowReg.team, position: rowReg.position, Games: rowReg.games, TOI: rowReg.timeOnIce, TOIPerG: rowReg.timeOnIcePerGame, G: rowReg.goals + " (" + rowReg.rankGoals + ")", 
      A: rowReg.assists + " (" + rowReg.rankAssists + ")", P: rowReg.points + " (" + rowReg.rankPoints + ")", PIM: rowReg.pim + " (" + rowReg.rankPenaltyMinutes + ")", Shots: rowReg.shots + " (" + rowReg.rankShots + ")", FaceoffPct: rowReg.faceOffPct,
      Hits: rowReg.hits + " (" + rowReg.rankHits + ")", PPG: rowReg.powerPlayGoals + " (" + rowReg.rankPowerPlayGoals + ")", PPP: rowReg.powerPlayPoints, FOPercentage: rowReg.faceoffPct, ShotPct: rowReg.shotPct + " (" + rowReg.rankShotPct + ")", 
      GWG: rowReg.gameWinningGoals, OTG: rowReg.overTimeGoals + " (" + rowReg.rankOvertimeGoals + ")", SHG: rowReg.shortHandedGoals + " (" + rowReg.rankShortHandedGoals + ")", SHP: rowReg.shorthandedPoints, 
      BlockedShots: rowReg.blocked + " (" + rowReg.rankBlockedShots + ")", PlusMinus: rowReg.plusMinus + " (" + rowReg.rankPlusMinus + ")", Shifts: rowReg.shifts, 
      playoffs: "PLAYOFFS", playoffGames: rowPlayoff.games + " (" + rowPlayoff.rankGamesPlayed + ")", playoffTOI: rowPlayoff.timeOnIce, playoffTOIPerG: rowPlayoff.timeOnIcePerGame, playoffG: rowPlayoff.goals + " (" + rowPlayoff.rankGoals + ")", 
      playoffA: rowPlayoff.assists + " (" + rowPlayoff.rankAssists + ")", playoffP: rowPlayoff.points + " (" + rowPlayoff.rankPoints + ")", playoffPIM: rowPlayoff.pim + " (" + rowPlayoff.rankPenaltyMinutes + ")", playoffShots: rowPlayoff.shots + " (" + rowPlayoff.rankShots + ")", 
      playoffHits: rowPlayoff.hits + " (" + rowPlayoff.rankHits + ")", playoffPPG: rowPlayoff.powerPlayGoals + " (" + rowPlayoff.rankPowerPlayGoals + ")", playoffPPP: rowPlayoff.powerPlayPoints, playoffFaceoffPct: rowPlayoff.faceoffPct, playoffShotPct: rowPlayoff.shotPct + " (" + rowPlayoff.rankShotPct + ")", 
      playoffGWG: rowPlayoff.gameWinningGoals, playoffOTG: rowPlayoff.overTimeGoals + " (" + rowPlayoff.rankOvertimeGoals + ")", playoffSHG: rowPlayoff.shortHandedGoals + " (" + rowPlayoff.rankShortHandedGoals + ")", playoffSHP: rowPlayoff.shorthandedPoints, 
      playoffBlockedShots: rowPlayoff.blocked + " (" + rowPlayoff.rankBlockedShots + ")", playoffPlusMinus: rowPlayoff.plusMinus + " (" + rowPlayoff.rankPlusMinus + ")", playoffShifts: rowPlayoff.shifts };
    } 
    return { rowId, playerId: rowReg.playerId, season, playerName: rowReg.playerName, team: rowReg.team, position: rowReg.position, Games: rowReg.games, TOI: rowReg.timeOnIce, TOIPerG: rowReg.timeOnIcePerGame, G: rowReg.goals + " (" + rowReg.rankGoals + ")", 
      A: rowReg.assists + " (" + rowReg.rankAssists + ")", P: rowReg.points + " (" + rowReg.rankPoints + ")", PIM: rowReg.pim + " (" + rowReg.rankPenaltyMinutes + ")", Shots: rowReg.shots + " (" + rowReg.rankShots + ")", FaceoffPct: rowReg.faceOffPct,
      Hits: rowReg.hits + " (" + rowReg.rankHits + ")", PPG: rowReg.powerPlayGoals + " (" + rowReg.rankPowerPlayGoals + ")", PPP: rowReg.powerPlayPoints, FOPercentage: rowReg.faceoffPct, ShotPct: rowReg.shotPct + " (" + rowReg.rankShotPct + ")", 
      GWG: rowReg.gameWinningGoals, OTG: rowReg.overTimeGoals + " (" + rowReg.rankOvertimeGoals + ")", SHG: rowReg.shortHandedGoals + " (" + rowReg.rankShortHandedGoals + ")", SHP: rowReg.shorthandedPoints, 
      BlockedShots: rowReg.blocked + " (" + rowReg.rankBlockedShots + ")", PlusMinus: rowReg.plusMinus + " (" + rowReg.rankPlusMinus + ")", Shifts: rowReg.shifts, 
    };
  }

  // Generate Goalie Table Data
  const createGoalieTableData = (rowReg, rowPlayoff) => {
    let rowId = uuidv4()
    let season = rowReg.season.split('#')[0]
    if (rowPlayoff.games != null) {
      return { rowId, playerId: rowReg.playerId, season, playerName: rowReg.playerName, team: rowReg.team, position: rowReg.position, Games: rowReg.games + " (" + rowReg.rankGamesPlayed + ")", GamesStarted: rowReg.gamesStarted, TOI: rowReg.timeOnIce, TOIPerG: rowReg.timeOnIcePerGame, G: rowReg.goals, 
        A: rowReg.assists, P: rowReg.points, PIM: rowReg.pim + " (" + rowReg.rankPenaltyMinutes + ")", wins: rowReg.wins + " (" + rowReg.rankWins + ")", losses: rowReg.losses + " (" + rowReg.rankLosses + ")", ot: rowReg.ot + " (" + rowReg.rankOt + ")", saves: rowReg.saves + " (" + rowReg.rankSaves + ")", 
        shutouts: rowReg.shutouts + " (" + rowReg.rankShutouts + ")", savePct: (parseFloat(rowReg.savePercentage.toFixed(3))) + " (" + rowReg.rankSavePercentage + ")", shotsAgainst: rowReg.shotsAgainst + " (" + rowReg.rankShotsAgainst + ")", goalsAgainst: rowReg.goalsAgainst + " (" + rowReg.rankGoalsAgainst + ")", GAA: (parseFloat(rowReg.goalAgainstAverage.toFixed(3))) + " (" + rowReg.rankGoalsAgainst + ")", 
        PPSaves: rowReg.powerPlaySaves, SHSaves: rowReg.shortHandedSaves, evenSaves: rowReg.evenSaves, PPSA: rowReg.powerPlayShots, SHSA: rowReg.shortHandedShots, ESA: rowReg.evenShots, PPSavePct: rowReg.powerPlaySavePercentage, SHSavePct: rowReg.shorthandedSavePercentage,
        EvenSavePct: rowReg.evenStrengthSavePercentage,
        playoffs: "PLAYOFFS", playoffGames: rowPlayoff.games + " (" + rowPlayoff.rankGames + ")", playoffGamesStarted: rowPlayoff.gamesStarted, playoffTOI: rowPlayoff.timeOnIce, playoffTOIPerG: rowPlayoff.timeOnIcePerGame, playoffG: rowPlayoff.goals, 
        playoffA: rowPlayoff.assists, playoffP: rowPlayoff.points, playoffPIM: rowPlayoff.pim + " (" + rowPlayoff.rankPenaltyMinutes + ")", playoffWins: rowPlayoff.wins + " (" + rowPlayoff.rankWins + ")", playoffLosses: rowPlayoff.losses + " (" + rowPlayoff.rankLosses + ")", playoffSaves: rowPlayoff.saves + " (" + rowPlayoff.rankSaves + ")", 
        playoffShutouts: rowPlayoff.shutouts + " (" + rowPlayoff.rankShutouts + ")", playoffSavePct: (parseFloat(rowPlayoff.savePercentage.toFixed(3))) + " (" + rowPlayoff.rankSavePercentage + ")", playoffShotsAgainst: rowPlayoff.shotsAgainst + " (" + rowPlayoff.rankShotsAgainst + ")", playoffGoalsAgainst: rowPlayoff.goalsAgainst + " (" + rowPlayoff.rankGoalsAgainst + ")", playoffGAA: (parseFloat(rowPlayoff.goalAgainstAverage.toFixed(3))) + " (" + rowPlayoff.rankGoalsAgainst + ")", 
        playoffPPSaves: rowPlayoff.powerPlaySaves, playoffSHSaves: rowPlayoff.shortHandedSaves, playoffEvenSaves: rowPlayoff.evenSaves, playoffPPSA: rowPlayoff.powerPlayShots, playoffSHSA: rowPlayoff.shortHandedShots, playoffESA: rowPlayoff.evenShots, playoffPPSavePct: rowPlayoff.powerPlaySavePercentage, playoffSHSavePct: rowPlayoff.shorthandedSavePercentage,
        playoffEvenSavePct: rowPlayoff.evenStrengthSavePercentage };
    }
    return { rowId, playerId: rowReg.playerId, season, playerName: rowReg.playerName, team: rowReg.team, position: rowReg.position, Games: rowReg.games + " (" + rowReg.rankGames + ")", GamesStarted: rowReg.gamesStarted, TOI: rowReg.timeOnIce, TOIPerG: rowReg.timeOnIcePerGame, G: rowReg.goals, 
      A: rowReg.assists, P: rowReg.points, PIM: rowReg.pim + " (" + rowReg.rankPenaltyMinutes + ")", wins: rowReg.wins + " (" + rowReg.rankWins + ")", losses: rowReg.losses + " (" + rowReg.rankLosses + ")", ot: rowReg.ot + " (" + rowReg.rankOt + ")", saves: rowReg.saves + " (" + rowReg.rankSaves + ")", 
      shutouts: rowReg.shutouts + " (" + rowReg.rankShutouts + ")", savePct: (parseFloat(rowReg.savePercentage.toFixed(3))) + " (" + rowReg.rankSavePercentage + ")", shotsAgainst: rowReg.shotsAgainst + " (" + rowReg.rankShotsAgainst + ")", goalsAgainst: rowReg.goalsAgainst + " (" + rowReg.rankGoalsAgainst + ")", GAA: (parseFloat(rowReg.goalAgainstAverage.toFixed(3))) + " (" + rowReg.rankGoalsAgainst + ")", 
      PPSaves: rowReg.powerPlaySaves, SHSaves: rowReg.shortHandedSaves, evenSaves: rowReg.evenSaves, PPSA: rowReg.powerPlayShots, SHSA: rowReg.shortHandedShots, ESA: rowReg.evenShots, PPSavePct: rowReg.powerPlaySavePercentage, SHSavePct: rowReg.shorthandedSavePercentage,
      EvenSavePct: rowReg.evenStrengthSavePercentage
    };
  }

  function camelize(string) {
    let splitString = string.split("_")
    let camelString = "";
    let i = 0;
    for (const s of splitString) {
      if (i === 0) {
        camelString += s.toLowerCase()
      } else {
        let camel = s.toLowerCase();
        let capitalized = camel.charAt(0).toUpperCase() + camel.slice(1);
        camelString += capitalized;
      }
      i++;
    }
    return camelString
  }

  // need formPlayerBreakdown because state may not have updated yet
  function initializeKeys(minutePieData, seasonPieData, formPlayerBreakdown) {
    let initializedColorKeys = []
    let initializedKeys = []
    let minuteLength = minutePieData[0][0].length
    let minuteArray = []
    for (let i = 0; i < minuteLength; i++) {
      minuteArray.push(...minutePieData[0][0][i])
    }
    // use the greater of the two (regular season vs overtime) to determine the color key setup
    let maxMinute = minuteTickArray[1].length >= minuteTickArray[0].length ? minuteTickArray[1][minuteTickArray[1].length - 1] : minuteTickArray[0][minuteTickArray[0].length - 1]
    if (minuteArray != null && minuteArray.length > 0) {
      initializedKeys = Object.keys(minuteArray[0]).filter(key => key !== 'time' && key !== 'timeNumber');
    } 
    if (initializedKeys != null && initializedKeys.length > 0) {
      if (!formPlayerBreakdown) {
        for (let k of initializedKeys) {
          for (let i = 0; i < maxMinute; i++) {
            initializedColorKeys.push(k + ":" + i)
          }
        }
      } else {
        for (const minuteObject of minuteArray) {
          // for isPlayerBreakdown, only add a key if data exists
          for (let k of initializedKeys) {
            if (minuteObject[k] > 0) {
              initializedColorKeys.push(k + ":" + minuteObject.timeNumber)
            }
          }
        }
      }
    } 
    return initializedColorKeys;
  }

  // sort keys for legend readability
  // key looks like [teamCode]-[playerName]-[playerId]
  function sortKeys(minuteData) {
    let initializedKeys = []
    let minuteArray = minuteData[0][0][0]
    if (minuteArray != null && minuteArray.length > 0) {
      initializedKeys = Object.keys(minuteArray[0]).filter(key => key !== 'time' && key !== 'timeNumber');
    } 
    let sortedKeys = initializedKeys.sort(function(a,b){
      let x = a.split("-")[0].toLowerCase();
      let y = b.split("-")[0].toLowerCase();

    //compare the player names by team
    //then compare by last name.
      if(x>y){return 1;} 
      if(x<y){return -1;}
      if (a.split(" ")[1] != null ) {
        let v = a.split(" ")[1].toLowerCase();
        let w = b.split(" ")[1].toLowerCase();
        if(v>w){return 1;}
        if(v<w){return -1;}
      }
      return 0;
    })
    return sortedKeys;
  }

  // exclude lighter colors by multiplying by 0xDDDDDD instead of 0xFFFFFF
  function randomColor(colorObject, counter) {
    let randomColor = `#${Math.floor(Math.random() * 0xDDDDDD).toString(16).padStart(6, '0')}`;
    let counterLimit = 10;
    let localCounter = 0;
    let localLimit = 3;
    while (counter < counterLimit && localCounter < localLimit && isColorTooClose(colorObject, randomColor)) {
      randomColor = `#${Math.floor(Math.random() * 0xDDDDDD).toString(16).padStart(6, '0')}`;
      localCounter++;
    }
    return randomColor;
  } 

  // adjusts hex color. Positive is lighter, negative is darker
  function adjust(color, amount) {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
  }

  function colorDifference(color1, color2) {
    // Convert the hex colors to RGB values
    var r1 = parseInt(color1.substring(1, 3), 16);
    var g1 = parseInt(color1.substring(3, 5), 16);
    var b1 = parseInt(color1.substring(5, 7), 16);
  
    var r2 = parseInt(color2.substring(1, 3), 16);
    var g2 = parseInt(color2.substring(3, 5), 16);
    var b2 = parseInt(color2.substring(5, 7), 16);
  
    // Calculate the difference between the RGB values
    var dr = r1 - r2;
    var dg = g1 - g2;
    var db = b1 - b2;
  
    // Return the difference as a single number
    return Math.sqrt(dr * dr + dg * dg + db * db);
  }

  // loop through colorObject to determine if color is too close to an existing color
  function isColorTooClose(colorObject, teamHex) {
    let colorsTooClose = false;
    let difference = 0;
    let colorSet = new Set(Object.values(colorObject));
    // don't check for large color set, takes wayyyy too long
    if (colorSet.size > 100) {
      return false
    }
    colorSet.forEach(color => {
      difference = colorDifference(color, teamHex)
      if (difference < 40) {
        colorsTooClose = true;
      }
    })
    return colorsTooClose;
  }

  // use multiple colors for existing team if color is already in use (when there are multiple players)
  // TODO: this is taking too long to run more than three teams. Wayyyy too long...
  function initializeColors(keys) {
    let newColorsObject = {};
    let counter = 0
    let totalColorCounter = 0;
    let totalColorCounterLimit = Math.max(Math.ceil(keys.length / 30), 180);
    let totalColorLimit = 2000;
    const colors = keys.map((key) => {
      let team = teamInfo.find(team => team.abbreviation === key.split(":")[0].split("-")[0])
      if (totalColorCounter < totalColorCounterLimit && !Object.keys(newColorsObject).includes(key.split(":")[0])) {
        if (Object.values(newColorsObject).includes(team.hex) || isColorTooClose(newColorsObject, team.hex)) {
          if (Object.values(newColorsObject).includes(team.secondaryHex) || isColorTooClose(newColorsObject, team.secondaryHex)) {
            if (Object.values(newColorsObject).includes(team.tertiaryHex) || isColorTooClose(newColorsObject, team.tertiaryHex)) {
              let newColor = randomColor(newColorsObject, counter);
              counter++;
              newColorsObject = { ...newColorsObject, [key.split(":")[0]]: newColor, [key]: newColor}
            } else {
              newColorsObject = { ...newColorsObject, [key.split(":")[0]]: team.tertiaryHex, [key]: team.tertiaryHex}
            }
          } else {
            newColorsObject = { ...newColorsObject, [key.split(":")[0]]: team.secondaryHex, [key]: team.secondaryHex}
          }
        } else {
          newColorsObject = { ...newColorsObject, [key.split(":")[0]]: team.hex, [key]: team.hex}
        }
        totalColorCounter++;
      } else if (totalColorCounter >= totalColorCounterLimit && totalColorCounter < totalColorLimit) {
        newColorsObject = { ...newColorsObject, [key.split(":")[0]]: team.hex, [key]: team.hex}
        totalColorCounter++
      } 
      // add key color for full key, adjust by period
      if (totalColorCounter < totalColorCounterLimit) {
        let color = newColorsObject[key.split(":")[0]]
        let baseNumber = color === "#000000" ? 0 : -30;
        let timestamp = key.split(":")[1] != null ? parseInt(key.split(":")[1]) : -1
        if (timestamp >= 0) {
          if (timestamp < 20) {
            newColorsObject = { ...newColorsObject, [key]: adjust(color, baseNumber)}
          } else if (timestamp < 40) {
            newColorsObject = { ...newColorsObject, [key]: adjust(color, baseNumber + 30)}
          } else if (timestamp < 60) {
            newColorsObject = { ...newColorsObject, [key]: adjust(color, baseNumber + 60)}
          } else {
            newColorsObject = { ...newColorsObject, [key]: adjust(color, baseNumber + 90)}
          }
        }
      }
      return newColorsObject[key.split(":")[0]];
    })

    return { ...newColorsObject };
  }

  // loadData adds 0 to timestamps in the chart without data and updates the React state.
  // chartMapArray is an array of nested maps. Each index in the array is one event type. 
  // the parent map is the timestamp
  // the child map is the number of events for a specific team.
  // maxValue is the number of ticks on the x-axis. For example, a game has 3600 seconds.
  // interval is distance between ticks set to zero. For example, an interval of 4 would 
  // attempt to put a 0 at x-values 0, 4, 8, 12, etc.
  function loadData(chartMapArray, teamArray, maxValue, interval) {
    let chartArray = [];
    chartMapArray.forEach(chartMap => {
      let finalChartArray = [];
      for (let i = 0; i < maxValue; i++) {
        if (chartMap.has(i)){
          // update other teams without values to zero 
          teamArray.forEach(team => {
            if (!chartMap.get(i).has(team)) {
              chartMap.get(i).set(team, 0);
            }
          });
          finalChartArray.push({timeNumber: i, time: i, ...Object.fromEntries(chartMap.get(i))});
        } else if (i % interval === 0) {
          let map = new Map();
          teamArray.forEach(team => {
            map.set(team, 0);
          })
          finalChartArray.push({timeNumber: i, time: i, ...Object.fromEntries(map)});
        }
      }
      chartArray.push(finalChartArray);
    })
    return chartArray;
  }

  // chartPieMapArray for pie chart looks like [{"ANA:34": 5, "ARI:14": 10, "COL:56": 4, ...}, {...}, {...}, etc]
  // transform into array of objects like [[{name: "ANA:34", value: 5}, {name: "ARI:14", value: 10}, ...], [{},{},{}], etc]
  // sortByValue is used by the season aggregation to sort by value rather than by key
  function loadPieData(chartPieMapArray, sortByValue, statList) {
    let chartPieArray = [];
    let i = 0;
    chartPieMapArray.forEach(chartMap => {
      let finalPieChartArray = [];
      let playerIdMap = [{playerId: 0, team: 'XYZ', updated: false, value: 0}]
      // check if this is a player list.
      // if so, include a "Total" value, that aggregates player data even for
      // players that have changed teams within the season
      // key for player data is {teamCode}-{playerName}/{playerId}  
      chartMap.forEach((value, key) => {
        let keySplit = key.split("/");
        let currentKey = "fake player"
        if (keySplit.length > 1) {
          let currentPlayerId = keySplit.pop()
          let currentPlayerName = keySplit[0].split("-")[1]
          let currentTeam = keySplit[0].split("-")[0]
          currentKey = keySplit[0]
          let obj = playerIdMap.find((o, i) => {
            // need current team check otherwise ASSIST stat gets updated and shows TOT in pie chart
            if (o.playerId === currentPlayerId && o.team !== currentTeam) {
                let existingValue = o.value
                playerIdMap[i] = { playerId: currentPlayerId, team: currentTeam, playerName: o.playerName, updated: true, value: existingValue + value };
                return true; // stop searching
            }
          });
          if (obj === undefined) {
            playerIdMap.push({playerId: currentPlayerId, team: currentTeam, playerName: currentPlayerName, updated: false, value: value})
          }
        }
        let map = new Map();
        map.set("name", key);
        map.set("value", value);
        if (sortByValue) {
          map.set("stat", statList[i])
          if (statList[i] === "FACEOFF_WIN" || statList[i] === "TAKEAWAY") {
            map.set("negValue", 0);
          }
        }
        finalPieChartArray.push({...Object.fromEntries(map)});
      });
      playerIdMap.forEach((item) => {
        if (item.updated) {
          let map = new Map();
          map.set("name", "TOT-" + item.playerName + "/" + item.playerId);
          map.set("value", item.value);
          if (sortByValue) {
            map.set("stat", statList[i])
            if (statList[i] === "FACEOFF_WIN" || statList[i] === "TAKEAWAY") {
              map.set("negValue", 0);
            }
          }
          finalPieChartArray.push({...Object.fromEntries(map)});
        }
      })
      let sortedArray = []
      if (sortByValue) {
        // sort array by value
        sortedArray = finalPieChartArray.sort(function(a,b){
          // here a , b is whole object, you can access its property
          //convert both to lowercase
            let x = a.value
            let y = b.value
      
          // compare the player values
          // This currently sorts in clockwise order on the pie chart
            if(x>y){return -1;} 
            if(x<y){return 1;}
            return 0;
          })
      } else {
        // sort array by map key
        sortedArray = finalPieChartArray.sort(function(a,b){
          // here a , b is whole object, you can access its property
          //convert both to lowercase
            let x = a.name.split(":")[0].toLowerCase();
            let y = b.name.split(":")[0].toLowerCase();
            let v = parseInt(a.name.split(":")[1])
            let w = parseInt(b.name.split(":")[1])
      
          //compare the player names in alphabetical order
          //then compare the integer timestamp. This currently sorts in clockwise order on the pie chart
            if(x>y){return 1;} 
            if(x<y){return -1;}
            if(v>w){return -1;}
            if(v<w){return 1;}
            return 0;
          })
      }
      
      chartPieArray.push(sortedArray);
      i++;
    })
    return chartPieArray;
  }

  // chartMap Array looks like ...
  // transform in array of objects like [{time: 1, game: 1, CHI: 1, DET: 0}, {time: 102, game: 2, CHI: 0, DET: 1}, {...}]
  // gameLengthArray includes the overall start time of the game in minutes {1: 0, 2: 60, 3: 120, 4: 200}
  function loadSeasonChartData(chartMapArray, teamArray, maxValue, interval, gameTypeInd, homeAwayInd, tempSeasonTickArray, gameLengthArray, isPlayerBreakdown) {
    let chartArray = [];
    tempSeasonTickArray.push([])
    tempSeasonTickArray[gameTypeInd].push([])
    // sort array by date first?
    chartMapArray.forEach(chartMap => {
      let finalChartArray = [];
      // flatten gameDay + value to single map
      let ticks = []
      let lastKey = 0;
      let newChartMap = chartMap;
      // loop through initial chartMap and make sure it has a key for each game
      if (gameTypeInd === 0 && homeAwayInd === 0) {
        for (let i = 1; i < chartMap.size; i++) {
          if(!chartMap.has(i)) {
            console.log("adding missing data for game " + i);
            newChartMap.set(i, new Map());
          }
        }
      }
      //re-sort keys
      let sortedChartMap = new Map([...newChartMap.entries()].sort((x, y) => {
        if (x == undefined) {
            return -1;
        }
        if (y == undefined) {
            return 1;
        }
        return parseInt(x) - parseInt(y);
      }));
      sortedChartMap.forEach((value, key, map) => {
        const gameString = "Game " + key;
        console.log("game number is " + key);
        console.log("value is " + JSON.stringify(Object.fromEntries(value)))
        if (key > lastKey) {
          lastKey = key;
        }
        // determine the maximum Value for the game..
        // minimum is 60 minutes, making sure to include enough OT buffer after the last event
        // needed because some playoff games go to multiple OTs, while some do not have OT at all
        // storing in a map for reference in the charts component and to minimize the work for total/home/away logic
        let max = 60;
        if (gameLengthArray[gameTypeInd].has(key) && gameLengthArray[gameTypeInd].has(key + 1)) {
          max = gameLengthArray[gameTypeInd].get(key + 1) - gameLengthArray[gameTypeInd].get(key);
        } else {
          if (key === 1) {
            gameLengthArray[gameTypeInd].set(key, 0);
          }
          // playoffs are organized by round, and not all rounds go to game 7
          // for example the third round of the playoffs will always be game "15", even if 15 games haven't yet been played
          if (gameTypeInd === 1 && homeAwayInd === 0 && !gameLengthArray[gameTypeInd].has(key) && gameLengthArray[gameTypeInd].has(key - 1)) {
            gameLengthArray[gameTypeInd].set(key, gameLengthArray[gameTypeInd].get(key - 1))
            gameLengthArray[gameTypeInd].delete(key - 1)
          }
          // determine max value and set as start of next key
          for (let i = 60; i < maxValue; i++) {
            if(value.has(i)) {
              max = i;
            }
          }
          // round to nearest 5
          if (max % 5 !== 0) {
            max += (5 - max % 5)
          }
          // only update if this is the first run-through (using total games, not home or away)
          if (homeAwayInd === 0) {
            gameLengthArray[gameTypeInd].set(key + 1, gameLengthArray[gameTypeInd].get(key) + max);
          }
        }
        let gameStart = gameLengthArray[gameTypeInd].get(key)
        console.log("gameLengthArray[gameTypeInd] is " + JSON.stringify(Object.fromEntries(gameLengthArray[gameTypeInd])))
        console.log("key is " + key);
        console.log("gameStart is " + gameStart);
        ticks.push(gameStart);
        for (let i = 0; i < max; i++) {
          let timeValue = i;
          let timeNumberValue = gameStart + i
          if (i === 0) {
            timeValue = gameString;
          }
          if (value.has(i)){
            // update other teams without values to zero for each interval per game
            teamArray.forEach(team => {
              if (!value.get(i).has(team)) {
                value.get(i).set(team, 0);
              }
            });
            finalChartArray.push({timeNumber: timeNumberValue, time: timeValue, game: parseInt(key), ...Object.fromEntries(value.get(i))});
          } else if (i % interval === 0 && !isPlayerBreakdown) {
            let map = new Map();
            teamArray.forEach(team => {
              map.set(team, 0);
            })
            finalChartArray.push({timeNumber: timeNumberValue, time: timeValue, game: parseInt(key), ...Object.fromEntries(map)});
          }
        }
      })
      ticks.push(gameLengthArray[gameTypeInd].get(lastKey + 1));
      ticks.sort((a, b) => {return a-b})
      let tickGameArray = []
      console.log("ticks in season chart is " + JSON.stringify(ticks))
      ticks.forEach(value => {
        tickGameArray.push(value);
      })
      chartArray.push(finalChartArray);
      tempSeasonTickArray[gameTypeInd][homeAwayInd].push(tickGameArray)
    })
    return chartArray;
  }

  // chartMap Array looks like ...
  // transform in array of objects like [{time: 1, game: 1, CHI: 1, DET: 0}, {time: 102, game: 2, CHI: 0, DET: 1}, {...}]
  function loadGameSeasonChartData(chartMapArray, teamArray, gameTypeInd, homeAwayInd, tempSeasonTickArray) {
    let chartArray = [];
    tempSeasonTickArray.push([])
    tempSeasonTickArray[gameTypeInd].push([])
    // sort array by date first?
    chartMapArray.forEach(chartMap => {
      let finalChartArray = [];
      // flatten gameDay + value to single map
      let ticks = []
      chartMap.forEach((value, key, map) => {
        const gameString = "Game " + key;
        ticks.push(parseInt(key));
        teamArray.forEach(team => {
          if (!value.has(team)) {
            value.set(team, 0);
          }
        });
        finalChartArray.push({timeNumber: key, time: gameString, game: parseInt(key), ...Object.fromEntries(value)});
      })
      ticks.sort((a, b) => {return a-b})
      let tickGameArray = []
      ticks.forEach(value => {
        tickGameArray.push(value);
      })
      chartArray.push(finalChartArray);
      tempSeasonTickArray[gameTypeInd][homeAwayInd].push(tickGameArray)
    })
    return chartArray;
  }

  function loadSecondData(chartLineMapArray, chartSecondPieMapArray, teamArray, secondTickArray, interval) {
    const chartLineArray = []
    const chartPieArray = []
    fill2DimensionsLineArray(chartLineArray, chartLineMapArray, 2, 3, teamArray, secondTickArray, interval);
    console.log("second line array loaded")
    fill2DimensionsPieArray(chartPieArray, chartSecondPieMapArray, 2, 3);
    console.log("second pie array loaded")
    setChartSecondData(chartLineArray);
    setChartPieSecondData(chartPieArray);
  }

  function loadMinuteData(chartMapArray, chartMinutePieMapArray, teamArray, minuteTickArray, interval) {
    const chartArray = []
    const chartPieArray = []
    fill2DimensionsLineArray(chartArray, chartMapArray, 2, 3, teamArray, minuteTickArray, interval);
    fill2DimensionsPieArray(chartPieArray, chartMinutePieMapArray, 2, 3);
    setChartMinuteData(chartArray);
    setChartPieMinuteData(chartPieArray);
    return chartArray;
  }

  // TODO: include playerId for traded players to show full stats on Season Pie chart
  // TODO: maybe include playerId as index/key instead of or in addition to team?
  function loadSeasonData(chartMapArray, chartSeasonMinuteMapArray, chartSeasonGameLineMapArray, chartSeasonPieMapArray, chartSeasonCoordinateMapArray, chartSeasonCoordinateRotatedMapArray, teamArray, minuteTickArray, interval, isPlayerBreakdown, statList) {
    const chartArray = []
    const chartMinuteArray = []
    const chartGameArray = []
    const chartPieArray = []
    // get length of each game
    const gameLengthArray = [];
    // reg season length
    gameLengthArray.push(new Map())
    // playoff length
    gameLengthArray.push(new Map())
    console.log("season")
    fill2DimensionsSeasonArray(chartArray, chartMapArray, 2, 3, teamArray, minuteTickArray, interval, gameLengthArray, isPlayerBreakdown);
    console.log("loaded season regular array")
    fill2DimensionsSeasonArray(chartMinuteArray, chartSeasonMinuteMapArray, 2, 3, teamArray, minuteTickArray, 1, gameLengthArray, isPlayerBreakdown);
    console.log("loaded season minute array")
    fill2DimensionsSeasonGameArray(chartGameArray, chartSeasonGameLineMapArray, 2, 3, teamArray, minuteTickArray);
    console.log("loaded season game array")
    fill2DimensionsPieArray(chartPieArray, chartSeasonPieMapArray, 2, 3, true, statList);
    console.log("loaded season pie array")
    processSeasonPieData(chartPieArray, statList)
    setGameStartArray(gameLengthArray)
    setChartSeasonData(chartArray);
    setChartMinuteSeasonData(chartMinuteArray)
    setChartGameSeasonData(chartGameArray)
    setChartPieSeasonData(chartPieArray);
    setChartCoordinateSeasonData(chartSeasonCoordinateMapArray);
    setChartCoordinateRotatedSeasonData(chartSeasonCoordinateRotatedMapArray);
    return chartArray;
  }

  function processSeasonPieData(chartPieData, statList) {
    //only process for total regular and total playoff ([0][0] and [1][0]) 
    let regularSeasonTotalData = chartPieData[0][0]
    let playoffSeasonTotalData = chartPieData[1][0]
    let firstIndex = 0;
    let secondIndex = 0;
    // TODO: what about plain FACEOFF?
    if (statList != null && statList.includes('FACEOFF_WIN') && statList.includes('FACEOFF_LOSS')) {
      firstIndex = statList.indexOf('FACEOFF_WIN')
      secondIndex = statList.indexOf('FACEOFF_LOSS')
      updatePieData(chartPieData, statList, firstIndex, secondIndex)
    }
    if (statList != null && statList.includes('GIVEAWAY') && statList.includes('TAKEAWAY')) {
      firstIndex = statList.indexOf('TAKEAWAY')
      secondIndex = statList.indexOf('GIVEAWAY')
      updatePieData(chartPieData, statList, firstIndex, secondIndex)
    }
  }

  // firstIndex is the positive value and secondIndex will be the negative value in the bar chart
  function updatePieData(chartPieData, statList, firstIndex, secondIndex) {
    let regularSeasonTotalDataFirstStat = chartPieData[0][0][firstIndex]
    let regularSeasonTotalDataSecondStat = chartPieData[0][0][secondIndex]
    let playoffSeasonTotalDataFirstStat = chartPieData[1][0][firstIndex]
    let playoffSeasonTotalDataSecondStat = chartPieData[1][0][secondIndex]

    //combine first and second indexes for each value in the data
    //both arrays are sorted by player Name.
    // TODO: optimize to run through the array in O(n) using "pointers"
    regularSeasonTotalDataFirstStat.forEach((entry) => {
      for (let i = 0; i < regularSeasonTotalDataSecondStat.length; i++) {
        if (entry.name === regularSeasonTotalDataSecondStat[i].name) {
          entry.negValue = -1 * regularSeasonTotalDataSecondStat[i].value
          entry.oppStat = regularSeasonTotalDataSecondStat[i].stat
          break;
        }
      }
    })

    playoffSeasonTotalDataFirstStat.forEach((entry) => {
      for (let i = 0; i < playoffSeasonTotalDataSecondStat.length; i++) {
        if (entry.name === playoffSeasonTotalDataSecondStat[i].name) {
          entry.negValue = -1 * playoffSeasonTotalDataSecondStat[i].value
          entry.oppStat = playoffSeasonTotalDataSecondStat[i].stat
          break;
        }
      }
    })
  }

  // season needs an extra map for gameDay
  // season line map arrays look like [{"2022-10-18": {"51": {"ANA": 1}, "287": {"NSH": 1}, "827": {"ANA": 1}}, {"324": {"ANA": 1}, "1589": {"NSH": 2}}, "2022-10-21": {....}]
  function fillSeasonLineMapArray(mapArray, index, value, gameDay, gameNumber, team) {
    if (mapArray[index].has(gameNumber)) {
      if (mapArray[index].get(gameNumber).has(value)) {
        if (mapArray[index].get(gameNumber).get(value).has(team)) {
          mapArray[index].get(gameNumber).get(value).set(team, mapArray[index].get(gameNumber).get(value).get(team) + 1);
        } else {
          mapArray[index].get(gameNumber).get(value).set(team, 1);
        }
      } else {
        mapArray[index].get(gameNumber).set(value, new Map());
        mapArray[index].get(gameNumber).get(value).set(team, 1);
      }
    } else {
      mapArray[index].set(gameNumber, new Map());
      mapArray[index].get(gameNumber).set(value, new Map());
      mapArray[index].get(gameNumber).get(value).set(team, 1);
    }
  }

  // season needs an extra map for gameDay
  // game season line map arrays look like [{"2022-10-18": {"ANA": 4, "NSH": 2}, "2022-10-21": {....}]
  function fillGameSeasonLineMapArray(mapArray, index, gameNumber, team) {
    if (mapArray[index].has(gameNumber)) {
      if (mapArray[index].get(gameNumber).has(team)) {
        mapArray[index].get(gameNumber).set(team, mapArray[index].get(gameNumber).get(team) + 1);
      } else {
        mapArray[index].get(gameNumber).set(team, 1);
      }
    } else {
      mapArray[index].set(gameNumber, new Map());
      mapArray[index].get(gameNumber).set(team, 1);
    }
  }

  // line map arrays look like [{"51": {"ANA": 1}, "287": {"NSH": 1}, "827": {"ANA": 1}}, {"324": {"ANA": 1}, "1589": {"NSH": 2}}]
  function fillLineMapArray(mapArray, index, value, team) {
    if (mapArray[index].has(value)) {
      if (mapArray[index].get(value).has(team)) {
        mapArray[index].get(value).set(team, mapArray[index].get(value).get(team) + 1);
      } else {
        mapArray[index].get(value).set(team, 1);
      }
    } else {
      mapArray[index].set(value, new Map());
      mapArray[index].get(value).set(team, 1);
    }
  }

  // pie map arrays look like [{"ANA:14": 2}, {"NSH:50": 1}}, {{"ANA:34": 1}, {"NSH:45": 2}}]
  // this is to distinguish them on the pie chart
  function fillPieMapArray(mapArray, index, value, team) {
    let key = team + ":" + value;
    if (mapArray[index].has(key)) {
      mapArray[index].set(key, mapArray[index].get(key) + 1);
    } else {
      mapArray[index].set(key, 1);
    }
  }

  // season pie map arrays look like [{"ANA": 2}, {"NSH": 1}}, {{"ANA": 1}, {"NSH": 2}}]
  function fillSeasonPieMapArray(mapArray, index, team) {
    if (mapArray[index].has(team)) {
      mapArray[index].set(team, mapArray[index].get(team) + 1);
    } else {
      mapArray[index].set(team, 1);
    }
  }

  // season coordinate map arrays look like [{"ANA": 2, "x": -69, "y": 22}, {"NSH": 1, "x": -69, "y": 22}}, {{"ANA": 1, "x": 0, "y": 0}, {"NSH": 2, "x": 0, "y": 0}}]
  function fillSeasonCoordinateMapArray(mapArray, index, team, row, gameNumber) {
    let map = new Map();
    map.set("name", team);
    map.set("value", 1);
    map.set("x", row.coordinateX);
    map.set("y", row.coordinateY);
    map.set("description", row.eventDescription);
    map.set("gameNumber", gameNumber)
    map.set("date", row.gameDay);
    map.set("period", row.period + " @ " + row.periodTime);
    mapArray[index].push({...Object.fromEntries(map)});
  }

  // rotated season coordinates show all the coordinates in one zone by moving all negative x coordinates to the positive side. This is done by multiplying x and y by -1.
  // season coordinate map arrays look like [{"ANA": 2, "x": -69, "y": 22}, {"NSH": 1, "x": -69, "y": 22}}, {{"ANA": 1, "x": 0, "y": 0}, {"NSH": 2, "x": 0, "y": 0}}]
  function fillSeasonCoordinateRotatedMapArray(mapArray, index, team, row, gameNumber) {
    let map = new Map();
    map.set("name", team);
    map.set("value", 1);
    map.set("description", row.eventDescription);
    map.set("gameNumber", gameNumber)
    map.set("date", row.gameDay);
    map.set("period", row.period + " @ " + row.periodTime);
    if (row.coordinateX < 0) {
      map.set("x", row.coordinateX * -1);
      map.set("y", row.coordinateY * -1);
    } else {
      map.set("x", row.coordinateX);
      map.set("y", row.coordinateY);
    }
    mapArray[index].push({...Object.fromEntries(map)});
  }

  function fillArrays(chartSecondLineMapArray, chartSecondPieMapArray, chartMinuteLineMapArray, chartMinutePieMapArray, chartSeasonLineMapArray, chartSeasonMinuteLineMapArray, chartSeasonGameLineMapArray, chartSeasonPieMapArray, 
    chartSeasonCoordinateMapArray, chartSeasonCoordinateRotatedMapArray, index, gameTimeInSeconds, gameTimeInMinutes, gameTimeInFiveMinutes, gameNumber, chartLineCode, i, row) {
    // create second map for each team/player
    fillLineMapArray(chartSecondLineMapArray, index, gameTimeInSeconds, chartLineCode[i]);
    fillPieMapArray(chartSecondPieMapArray, index, gameTimeInSeconds, chartLineCode[i]);
    // create minute map for each team/player
    fillLineMapArray(chartMinuteLineMapArray, index, gameTimeInMinutes, chartLineCode[i]);
    fillPieMapArray(chartMinutePieMapArray, index, gameTimeInMinutes, chartLineCode[i]);
    // season needs an extra map for gameDay
    fillSeasonLineMapArray(chartSeasonLineMapArray, index, gameTimeInFiveMinutes, row.gameDay, gameNumber, chartLineCode[i]);
    fillSeasonLineMapArray(chartSeasonMinuteLineMapArray, index, gameTimeInMinutes, row.gameDay, gameNumber, chartLineCode[i]);
    fillGameSeasonLineMapArray(chartSeasonGameLineMapArray, index, gameNumber, chartLineCode[i]);
    fillSeasonPieMapArray(chartSeasonPieMapArray, index, chartLineCode[i]);
    fillSeasonCoordinateMapArray(chartSeasonCoordinateMapArray, index, chartLineCode[i], row, gameNumber);
    fillSeasonCoordinateRotatedMapArray(chartSeasonCoordinateRotatedMapArray, index, chartLineCode[i], row, gameNumber);
  }

  function handleAssists(eventType, includePlayerPrimaryAssist, includePlayerSecondaryAssist, usePlayerName, 
      row, statList, statIndex, chartLineCode, side, actorSide, playerNameArray, playerIdArray, eventAction, playerPosition, amountArray, teamArray, gameMap) {
    let actorHomeAwayIndex = row.actorIsHome ? 1 : 2;
    let rowGameTypeIndex = row.gameType === "P" ? 1 : 0;
    //statList, statIndex, chartLineCode, code, playerNameArray, playerName, sideArray, side, eventAction, playerPosition, amountArray, rowGameTypeIndex, 
    //eventHomeAwayIndex, statString, eventActionString, playerPositionString
    if (includePlayerPrimaryAssist) {
      let primaryAssistCode = usePlayerName ? row.playerPrimaryAssistTriCode + "-" + row.playerPrimaryAssistName + "/" + row.playerPrimaryAssistId : row.playerPrimaryAssistTriCode;
      updateEventArrays(statList, statIndex, chartLineCode, primaryAssistCode, playerNameArray, row.playerPrimaryAssistName, playerIdArray, row.playerPrimaryAssistId, side, actorSide, eventAction, playerPosition, amountArray, rowGameTypeIndex, 
        actorHomeAwayIndex, eventType, "Primary Assist", row.playerPrimaryAssistPosition, gameMap, row, true)

        updateTeamArray(teamArray, primaryAssistCode)
    }
    if (row.playerSecondaryAssistName != null && includePlayerSecondaryAssist) {
      let secondaryAssistCode = usePlayerName ? row.playerActorTriCode + "-" + row.playerSecondaryAssistName + "/" + row.playerSecondaryAssistId : row.playerActorTriCode;
      updateEventArrays(statList, statIndex, chartLineCode, secondaryAssistCode, playerNameArray, row.playerSecondaryAssistName, playerIdArray, row.playerSecondaryAssistId, side, actorSide, eventAction, playerPosition, amountArray, rowGameTypeIndex, 
        actorHomeAwayIndex, eventType, "Secondary Assist", row.playerSecondaryAssistPosition, gameMap, row, true)
      
      updateTeamArray(teamArray, secondaryAssistCode)
    }
  }

  function fill3DimensionsMapArray(arr, rows, columns, k){
    //arr.push([[[new Map()], [new Map()]],[[new Map()],[new Map()]],[[new Map()],[new Map()]]])
    for (var i = 0; i < rows; i++) {
      arr.push([])
      for (var j = 0; j < columns; j++) {
        arr[i].push([]);
        arr[i][j].push([]);
        arr[i][j][k] = new Map();
      }
    }
  }

  function fill3DimensionsArray(arr, rows, columns, k){
    for (var i = 0; i < rows; i++) {
      arr.push([])
      for (var j = 0; j < columns; j++) {
        arr[i].push([]);
        arr[i][j].push([]);
        arr[i][j][k] = [];
      }
    }
  }

  function fill2DimensionsLineArray(newArray, arr, rows, columns, teamArray, tickArray, interval){
    for (var i = 0; i < rows; i++) {
        newArray.push([])
        for (var j = 0; j < columns; j++) {
            newArray[i][j] = loadData(arr[i][j], teamArray, tickArray[i][tickArray[i].length - 1], interval);
        }
    }
  }

  function fill2DimensionsPieArray(newArray, arr, rows, columns) {
    fill2DimensionsPieArray(newArray, arr, rows, columns, false, [], [])
  }

  function fill2DimensionsPieArray(newArray, arr, rows, columns, sortByValue, statList){
    for (var i = 0; i < rows; i++) {
        newArray.push([])
        for (var j = 0; j < columns; j++) {
            newArray[i][j] = loadPieData(arr[i][j], sortByValue, statList);
        }
    }
  }

  function fill2DimensionsSeasonArray(newArray, arr, rows, columns, teamArray, tickArray, interval, gameLengthArray, isPlayerBreakdown){
    let tempSeasonTickArray = [];
    for (var i = 0; i < rows; i++) {
        newArray.push([])
        for (var j = 0; j < columns; j++) {
            newArray[i][j] = loadSeasonChartData(arr[i][j], teamArray, tickArray[i][tickArray[i].length - 1], interval, i, j, tempSeasonTickArray, gameLengthArray, isPlayerBreakdown);
        }
    }
    if (interval === 1) {
      setSeasonMinuteTickArray(tempSeasonTickArray);
    } else {
      setSeasonPeriodTickArray(tempSeasonTickArray);
    }
  }

  function fill2DimensionsSeasonGameArray(newArray, arr, rows, columns, teamArray, tickArray){
    let tempSeasonTickArray = [];
    for (var i = 0; i < rows; i++) {
        newArray.push([])
        for (var j = 0; j < columns; j++) {
            newArray[i][j] = loadGameSeasonChartData(arr[i][j], teamArray, i, j, tempSeasonTickArray);
        }
    }
    setSeasonTickArray(tempSeasonTickArray);
  }

  // need to add args here because the parent initial state overrides the URL parameters on landing
  function handleFormSubmit(response, events, players, statList, teamIdList, playerIdList, positionIdList, formPlayerBreakdown) {
    if (!valid && views >= viewThreshold) {
      console.log('not a valid query, ending form submit function')
      setShowAlert(true);
      setIsLanding(false);
      setIsLoading(false);
      return;
    }
    const midnight = new Date();
    midnight.setHours(24, 0, 0, 0);
    Cookies.set('views', parseInt(views) + 1, {expires: midnight})
    views = Cookies.get('views')
    console.log('Dashboard views is ' + views)
    setShowAlert(false);
    setSeasonTickArray([]);
    setSeasonMinuteTickArray([]);
    setSeasonPeriodTickArray([]);
    setGameStartArray([]);
    setData(events);
    // TODO: set Player Data
    setTabIndex(0);
    setEventList(statList);
    setStatPanelExpanded(false);
    setFilterPanelExpanded(false);
    setIsLanding(false);
    if (!valid && views >= viewThreshold) {
      console.log('not a valid query, ending form submit function')
      setIsLoading(false);
      return;
    }
    if (response.data == undefined || events == undefined) {
      alert("Something went wrong. Error message: " + response.data.errorMessage)
      setIsLoading(false);
      return;
    }
    // create arrays based on length of statList
    // line map arrays look like [{"51": {"ANA": 1}, "287": {"NSH": 1}, "827": {"ANA": 1}}, {"324": {"ANA": 1}, "1589": {"NSH": 2}}]
    // each index is a different stat. The outer key is the timestamp in seconds. The inner map includes the team triCode as key 
    // and the amount the team had at outer key timestamp for the corresponding stat
    // total = 0, home = 1, away = 2
    let chartSecondLineMapArray = [];
    let chartMinuteLineMapArray = [];
    let chartSeasonLineMapArray = [];
    let chartSeasonMinuteLineMapArray = [];
    let chartSeasonGameLineMapArray = [];
    let chartSecondPieMapArray = [];
    let chartMinutePieMapArray = [];
    let chartSeasonPieMapArray = [];
    let chartSeasonCoordinateMapArray = [];
    let chartSeasonCoordinateRotatedMapArray = [];
    let chartTitleArray = [];
    let amountArray = [];
    let teamArray = [];
    const fullTableData = [];
    const fullPlayerTableData = [];
    const fullGoalieTableData = [];
    const eventIdList = [];
    let count = 0;
    statList.forEach(stat => {
      chartTitleArray.push(stat + " Chart");
      fill3DimensionsMapArray(chartSecondLineMapArray, 2, 3, count);
      fill3DimensionsMapArray(chartMinuteLineMapArray, 2, 3, count);
      fill3DimensionsMapArray(chartSeasonLineMapArray, 2, 3, count);
      fill3DimensionsMapArray(chartSeasonMinuteLineMapArray, 2, 3, count);
      fill3DimensionsMapArray(chartSeasonGameLineMapArray, 2, 3, count);
      fill3DimensionsMapArray(chartSecondPieMapArray, 2, 3, count);
      fill3DimensionsMapArray(chartMinutePieMapArray, 2, 3, count);
      fill3DimensionsMapArray(chartSeasonPieMapArray, 2, 3, count);
      fill3DimensionsArray(chartSeasonCoordinateMapArray, 2, 3, count);
      fill3DimensionsArray(chartSeasonCoordinateRotatedMapArray, 2, 3, count);
      amountArray.push([[0], [0], [0]],[[0], [0], [0]]);
      count++;
    });
    setChartTitle(chartTitleArray);
    setEventType(statList);
    let excludeGoalDuplicates = statList.includes("GOAL") && (statList.includes("POINT"));
    let excludePrimaryAssistDuplicates = statList.includes("PRIMARY_ASSIST") && (statList.includes("ASSIST") || statList.includes("POINT"));
    let excludeSecondaryAssistDuplicates = statList.includes("SECONDARY_ASSIST") && (statList.includes("ASSIST") || statList.includes("POINT"));
    let excludeAssistDuplicates = statList.includes("ASSIST") && statList.includes("POINT");
    let excludeFaceoffDuplicates = statList.includes("FACEOFF") && (statList.includes("FACEOFF_WON") || statList.includes("FACEOFF_LOSS"));

    console.log('sorting events')
    //Data can come from multiple lambdas. Sort combined data by gameId then period, then timestamp
    let sortedResponse = events.sort(function(a,b){
      // here a , b is whole object, you can access its property
      //convert both to lowercase
      let x = a.gameId;
      let y = b.gameId;
      let v = a.period;
      let w = b.period;
      let r = parseInt(a.periodTime.split(":")[0]) * 60 + parseInt(a.periodTime.split(":")[1]);
      let s = parseInt(b.periodTime.split(":")[0]) * 60 + parseInt(b.periodTime.split(":")[1]);

      //compare the gameId integer,
      //then compare the time value
      if (x !== y) {
        return x - y;
      } 
      if (v !== w) {
        return v - w;
      }
      if (r !== s) {
        return r - s;
      }
      return 0;
    });

    // if player breakdown checkbox is true, break down stats per player, rather than accumulate by team
    // to accomplish the breakdown, generate the player list here from the list of events
    // only do so if the playerIdList isn't populated already (the user selected specific players)
    if (formPlayerBreakdown && playerIdList.length === 0) {
      let playerList = []
      if (positionIdList.length > 0) {
        playerList = sortedResponse.filter(row => positionIdList.includes(row.playerActorPosition)).map(row => row.playerActorId)
        playerList.push(...sortedResponse.filter(row => positionIdList.includes(row.playerPrimaryAssistPosition)).map(row => row.playerPrimaryAssistId), 
          ...sortedResponse.filter(row => positionIdList.includes(row.playerSecondaryAssistPosition)).map(row => row.playerSecondaryAssistId), 
          ...sortedResponse.filter(row => positionIdList.includes(row.playerRecipientPosition)).map(row => row.playerRecipientId))
      } else {
        playerList = sortedResponse.map(row => row.playerActorId)
        if (statList.includes("ASSIST") || statList.includes("PRIMARY_ASSIST") || statList.includes("SECONDARY_ASSIST")){
          playerList.push(...sortedResponse.filter(row => row.playerPrimaryAssistId !== null).map(row => row.playerPrimaryAssistId))
          playerList.push(...sortedResponse.filter(row => row.playerSecondaryAssistId !== null).map(row => row.playerSecondaryAssistId))
        }
        playerList.push(...sortedResponse.map(row => row.playerRecipientId))
      }
      // get unique ids
      console.log("teamId list is " + teamIdList)
      console.log("sorted Response size is " + sortedResponse.length)
      console.log("playerList length is " + playerList.length)
      // count number of events for each player by converting array into map of playerId: amount key: value pair
      if (teamIdList.length > 3) {
        console.log("limiting player size")
        let playerMap = new Map()
        playerList.forEach((e) => {
          if(!playerMap.has(e)) {
            playerMap.set(e, 1)
          } else {
            playerMap.set(e, playerMap.get(e) + 1);
          }
        })
        // loop through map and only add players that are around 1/250th of the total events. For example, if the 
        // total number of events is 2500, the threshold is 10.
        // this is to prevent "long tails" that slow down compute when user is searching for stat leaders
        // TODO: break this list apart into actor/recipient lists based on the stat in question
        // TODO: can't lower threshold further or begin to miss players on the stat
        // For example, Joel Edmundson goes missing for blocked shots, likely because this list counts both 
        // actor and recipient of the event equally (because I'm lazy and haven't split that out yet). 
        // It is likely he was blocked fewer times, and so disappears
        let threshold = Math.floor(sortedResponse.length / 250 / statList.length)
        console.log('threshold is ' + threshold)
        playerMap.forEach((value, key) => {
          if (value >= threshold) {
            playerIdList.push(key)
          }
        })
      } else {
        playerIdList = [...new Set(playerList)]
      }
      console.log("playerIdList length is " + playerList.length)
    } 

    // handle player stat data. Use playerIdList (Set) to filter players for the query
    let sortedPlayerResponse = players.filter(p => playerIdList.includes(p.playerId)).sort(function(a,b){
      let x = a.playerId;
      let y = b.playerId;
      let v = a.season;
      let w = b.season;
      if (x !== y) {
        return x - y;
      } 
      if(v>w){return -1;}
      if(v<w){return 1;}
      return 0;
    })

    console.log("playerList sorted");
    let i = 0;
    let player = null;
    let playerId;
    let previousPlayerId = 0;
    let position;
    let rowReg;
    let rowPlayoff;
    let s;
    let hasPlayoffData = false;
    let hasDuplicate = false;
    let teamTriCodeList = teamIdList.map(id => teamInfo.find(team => team.id === id).abbreviation);
    while (i < sortedPlayerResponse.length) {
      player = sortedPlayerResponse[i];
      playerId = player.playerId;
      position = player.position;
      s = player.season;
      rowReg = player;
      hasDuplicate = i < sortedPlayerResponse.length - 1 && playerId === sortedPlayerResponse[i + 1].playerId && sortedPlayerResponse[i + 1].season.includes('R')
      // Background: The Query Lambda is agnostic to the other queries called from the UI. Therefore, each query response from the Lambda includes player stat data. 
      // If there are multiple stat queries, there will likely be duplicates of player stat data returned. 
      // Action: Skip regular season duplicates
      while (hasDuplicate) {
        i++;
        hasDuplicate = i < sortedPlayerResponse.length - 1 && playerId === sortedPlayerResponse[i + 1].playerId && sortedPlayerResponse[i + 1].season.includes('R')
      }
      hasPlayoffData = i < sortedPlayerResponse.length - 1 && playerId === sortedPlayerResponse[i + 1].playerId && sortedPlayerResponse[i + 1].season.includes('P')
      rowPlayoff = hasPlayoffData ? sortedPlayerResponse[i + 1] : {}
      if (teamTriCodeList.includes(rowReg.team)) {
        if (position === "G") {
          fullGoalieTableData.push(createGoalieTableData(rowReg, rowPlayoff));
        } else {
          fullPlayerTableData.push(createPlayerTableData(rowReg, rowPlayoff));
        }
      }
      previousPlayerId = playerId;
      // skip any playoff duplicates
      while (i < sortedPlayerResponse.length && previousPlayerId == sortedPlayerResponse[i].playerId) {
        i++
      }
    }

    console.log('starting to process events')
    // TODO: clean notTableData :I
    const notTableData = sortedResponse.map(row => {
      // get index of statList for event
      // TODO: could store the index in a map to reduce retrieval cost
      const statIndex = [];
      // ignore event duplicates
      if (eventIdList.includes(row.eventId)) {
        return;
      } else {
        eventIdList.push(row.eventId)
      }
      const periodTimeInSeconds = parseInt(row.periodTime.split(":")[0]) * 60 + parseInt(row.periodTime.split(":")[1]);
      // this creates time within game
      const gameTimeInSeconds = periodTimeInSeconds + (1200 * (parseInt(row.period) - 1));
      const gameTimeInMinutes = parseInt(row.periodTime.split(":")[0]) + (20 * (parseInt(row.period) - 1))
      const gameTimeInFiveMinutes = gameTimeInMinutes - (gameTimeInMinutes % 10)
      let chartLineCode = [];
      let gameMap = [];
      let playerNameArray = [];
      let playerIdArray = [];
      let eventAction = [];
      let playerPosition = [];
      let side = [];
      let includeActorTeam = teamIdList == null || teamIdList.length === 0 || teamIdList.includes(row.playerActorTeamId)
      let includeRecipientTeam = teamIdList == null || teamIdList.length === 0 || teamIdList.includes(row.playerRecipientTeamId)
      let usePlayerName = playerIdList != null && playerIdList.length > 0 && playerIdList[0] !== "";
      // only need to filter position if there's no player list. The UI player list would already be filtered by position
      let usePositionList = !usePlayerName && positionIdList != null && positionIdList.length > 0 && positionIdList[0] !== "";
      let actorCode = usePlayerName ? row.playerActorTriCode + "-" + row.playerActorName + "/" + row.playerActorId: row.playerActorTriCode;
      let recipientCode = usePlayerName ? row.playerRecipientTriCode + "-" + row.playerRecipientName  + "/" + row.playerRecipientId: row.playerRecipientTriCode;
      let excludePlayerGoalScorer = usePlayerName && row.eventTypeId === "GOAL" && !playerIdList.includes(row.playerActorId);
      let includePlayerActor = ((!usePlayerName && includeActorTeam) || (usePlayerName && playerIdList.includes(row.playerActorId)))
        && (!usePositionList || (usePositionList && positionIdList.includes(row.playerActorPosition)));
      let includePlayerRecipient = ((!usePlayerName && includeRecipientTeam) || (usePlayerName && playerIdList.includes(row.playerRecipientId)))
        && (!usePositionList || (usePositionList && positionIdList.includes(row.playerRecipientPosition)));
      let includePlayerPrimaryAssist = ((!usePlayerName && includeActorTeam) || (usePlayerName && playerIdList.includes(row.playerPrimaryAssistId)))
        && (!usePositionList || (usePositionList && positionIdList.includes(row.playerPrimaryAssistPosition)));
      let includePlayerSecondaryAssist = ((!usePlayerName && includeActorTeam) || (usePlayerName && playerIdList.includes(row.playerSecondaryAssistId))) 
        && (!usePositionList || (usePositionList && positionIdList.includes(row.playerSecondaryAssistPosition)));
      let actorSide = row.actorIsHome ? "Home" : "Away";
      let recipientSide = row.actorIsHome ? "Away" : "Home";
      let actorHomeAwayIndex = row.actorIsHome ? 1 : 2;
      let recipientHomeAwayIndex = row.actorIsHome ? 2 : 1;
      let rowGameTypeIndex = row.gameType === "P" ? 1 : 0;
      let isEvenStrengthGoal = true;

      // Set Playoff tick array
      // Increase OT if this number of OTs have not been seen before.
      // Each OT increases the tick array by 4.
      const expectedTickArrayLength = row.period <= 4 ? 17 : 17 + (row.period - 4) * 4;
      const tempSecondTickArray = secondTickArray;
      const tempMinuteTickArray = minuteTickArray;
      if (row.gameType === "P" && row.periodType === "OVERTIME" && tempSecondTickArray[1].length < expectedTickArrayLength) {
        let length = tempSecondTickArray[1].length;
        let currentLastSecondValue = tempSecondTickArray[1][length - 1];
        let currentLastMinuteValue = tempMinuteTickArray[1][length - 1];
        while (currentLastMinuteValue < (row.period * 20 + 5)) {
          currentLastMinuteValue += 5;
          currentLastSecondValue += 300;
          tempSecondTickArray[1].push(currentLastSecondValue);
          tempMinuteTickArray[1].push(currentLastMinuteValue);
        }
      }

      setSecondTickArray(tempSecondTickArray);
      setMinuteTickArray(tempMinuteTickArray);

      // Handle Actor events. FACEOFF handled separately
      if (includeActorTeam && row.eventTypeId !== "FACEOFF" && statList.includes(row.eventTypeId) && !excludePlayerGoalScorer && includePlayerActor) {
        updateEventArrays(statList, statIndex, chartLineCode, actorCode, playerNameArray, row.playerActorName, playerIdArray, row.playerActorId, side, actorSide, eventAction, playerPosition, amountArray, rowGameTypeIndex, 
          actorHomeAwayIndex, row.eventTypeId, row.eventAction, row.playerActorPosition, gameMap, row, true)
        updateTeamArray(teamArray, actorCode)
      }

      if (row.playerRecipientTeamId != null && includeRecipientTeam && includePlayerRecipient) {
        updateTeamArray(teamArray, recipientCode)
      }

      // Handle POWER_PLAY_GOAL and SHORTHANDED_GOAL
      if (includeActorTeam && row.eventTypeId === "GOAL" && statList.includes("POWER_PLAY_GOAL") && !excludePlayerGoalScorer && includePlayerActor && row.strengthCode === "PPG") {
        updateEventArrays(statList, statIndex, chartLineCode, actorCode, playerNameArray, row.playerActorName, playerIdArray, row.playerActorId, side, actorSide, eventAction, playerPosition, amountArray, rowGameTypeIndex, 
          actorHomeAwayIndex, "POWER_PLAY_GOAL", "Power Play Goal", row.playerActorPosition, gameMap, row, true)
        updateTeamArray(teamArray, actorCode)
        isEvenStrengthGoal = false
      }
      if (includeActorTeam && row.eventTypeId === "GOAL" && statList.includes("SHORTHANDED_GOAL") && !excludePlayerGoalScorer && includePlayerActor && row.strengthCode === "SHG") {
        updateEventArrays(statList, statIndex, chartLineCode, actorCode, playerNameArray, row.playerActorName, playerIdArray, row.playerActorId, side, actorSide, eventAction, playerPosition, amountArray, rowGameTypeIndex, 
          actorHomeAwayIndex, "SHORTHANDED_GOAL", "Shorthanded Goal", row.playerActorPosition, gameMap, row, true)
        updateTeamArray(teamArray, actorCode)
        isEvenStrengthGoal = false
      }

      // Handle GAME_WNNING_GOAL
      if (includeActorTeam && row.eventTypeId === "GOAL" && statList.includes("GAME_WINNING_GOAL") && !excludePlayerGoalScorer && includePlayerActor && row.gameWinningGoal) {
        updateEventArrays(statList, statIndex, chartLineCode, actorCode, playerNameArray, row.playerActorName, playerIdArray, row.playerActorId, side, actorSide, eventAction, playerPosition, amountArray, rowGameTypeIndex, 
          actorHomeAwayIndex, "GAME_WINNING_GOAL", "Game Winning Goal", row.playerActorPosition, gameMap, row, true)
        updateTeamArray(teamArray, actorCode)
      }

      // FACEOFF stat needs to include faceoffs won and faceoffs lost
      if (row.eventTypeId === "FACEOFF" && statList.includes("FACEOFF")) {
        if (includeActorTeam && includePlayerActor) {
          updateEventArrays(statList, statIndex, chartLineCode, actorCode, playerNameArray, row.playerActorName, playerIdArray, row.playerActorId, side, actorSide, eventAction, playerPosition, amountArray, rowGameTypeIndex, 
            actorHomeAwayIndex, "FACEOFF", "Faceoff Won", row.playerActorPosition, gameMap, row, true)
        }
        if (includeRecipientTeam && includePlayerRecipient) {
          updateEventArrays(statList, statIndex, chartLineCode, recipientCode, playerNameArray, row.playerRecipientName, playerIdArray, row.playerRecipientId, side, recipientSide, eventAction, playerPosition, amountArray, rowGameTypeIndex, 
            recipientHomeAwayIndex, "FACEOFF", "Faceoff Lost", row.playerRecipientPosition, gameMap, row, false)
        }
      }

      // FACEOFF_WIN stat requires actor Team
      if (row.eventTypeId === "FACEOFF" && statList.includes("FACEOFF_WIN") && includeActorTeam && includePlayerActor) {
        updateEventArrays(statList, statIndex, chartLineCode, actorCode, playerNameArray, row.playerActorName, playerIdArray, row.playerActorId, side, actorSide, eventAction, playerPosition, amountArray, rowGameTypeIndex, 
          actorHomeAwayIndex, "FACEOFF_WIN", "Faceoff Won", row.playerActorPosition, gameMap, row, true)

        updateTeamArray(teamArray, actorCode)
      }

      // FACEOFF_LOSS stat is for recipient team
      if (row.eventTypeId === "FACEOFF" && statList.includes("FACEOFF_LOSS") && includeRecipientTeam && row.playerRecipientName != null && includePlayerRecipient) {
        updateEventArrays(statList, statIndex, chartLineCode, recipientCode, playerNameArray, row.playerRecipientName, playerIdArray, row.playerRecipientId, side, recipientSide, eventAction, playerPosition, amountArray, rowGameTypeIndex, 
          recipientHomeAwayIndex, "FACEOFF_LOSS", "Faceoff Loss", row.playerRecipientPosition, gameMap, row, false)
       
        updateTeamArray(teamArray, recipientCode)
      }

      // eventType, includePlayerPrimaryAssist, includePlayerSecondaryAssist, usePlayerName, 
      // row, statList, statIndex, chartLineCode, side, gameType, actorSide, playerNameArray, eventAction, playerPosition, amountArray, teamArray, gameMap
      // handle ASSIST stat
      if (row.eventTypeId === "GOAL" && statList.includes("ASSIST") && row.playerPrimaryAssistName != null && includeActorTeam) {
        handleAssists("ASSIST", includePlayerPrimaryAssist, includePlayerSecondaryAssist, usePlayerName, 
          row, statList, statIndex, chartLineCode, side, actorSide, playerNameArray, playerIdArray, eventAction, playerPosition, amountArray, teamArray, gameMap);
      }

      // handle POINT stat
      if (row.eventTypeId === "GOAL" && statList.includes("POINT") && includeActorTeam) {
        if (!excludePlayerGoalScorer && includePlayerActor) {
          updateEventArrays(statList, statIndex, chartLineCode, actorCode, playerNameArray, row.playerActorName, playerIdArray, row.playerActorId, side, actorSide, eventAction, playerPosition, amountArray, rowGameTypeIndex, 
            actorHomeAwayIndex, "POINT", "Goal", row.playerActorPosition, gameMap, row, true)
        }
        if (row.playerPrimaryAssistName != null) {
          handleAssists("POINT", includePlayerPrimaryAssist, includePlayerSecondaryAssist, usePlayerName, 
          row, statList, statIndex, chartLineCode, side, actorSide, playerNameArray, playerIdArray, eventAction, playerPosition, amountArray, teamArray, gameMap);
        }
      }

      // handle PRIMARY_ASSIST stat
      if (row.eventTypeId === "GOAL" && statList.includes("PRIMARY_ASSIST") && row.playerPrimaryAssistName != null && includePlayerPrimaryAssist && includeActorTeam) {
        let primaryAssistCode = usePlayerName ? row.playerActorTriCode + "-" + row.playerPrimaryAssistName + "/" + row.playerPrimaryAssistId : row.playerActorTriCode;
        updateEventArrays(statList, statIndex, chartLineCode, primaryAssistCode, playerNameArray, row.playerPrimaryAssistName, playerIdArray, row.playerPrimaryAssistId, side, actorSide, eventAction, playerPosition, amountArray, rowGameTypeIndex, 
          actorHomeAwayIndex, "PRIMARY_ASSIST", "Primary Assist", row.playerPrimaryAssistPosition, gameMap, row, true)
        
        updateTeamArray(teamArray, primaryAssistCode)
      }

      // handle SECONDARY_ASSIST stat
      if (row.eventTypeId === "GOAL" && statList.includes("SECONDARY_ASSIST") && row.playerSecondaryAssistName != null && includePlayerSecondaryAssist && includeActorTeam) {
        let secondaryAssistCode = usePlayerName ? row.playerActorTriCode + "-" + row.playerSecondaryAssistName + "/" + row.playerSecondaryAssistId : row.playerActorTriCode;
        updateEventArrays(statList, statIndex, chartLineCode, secondaryAssistCode, playerNameArray, row.playerSecondaryAssistName, playerIdArray, row.playerSecondaryAssistId, side, actorSide, eventAction, playerPosition, amountArray, rowGameTypeIndex, 
          actorHomeAwayIndex, "SECONDARY_ASSIST", "Secondary Assist", row.playerSecondaryAssistPosition, gameMap, row, true)
        
        updateTeamArray(teamArray, secondaryAssistCode)
      }

      // handle SHOT_TAKEN_BLOCKED stat
      handleRecipientEvent(row, gameMap, statList, includeRecipientTeam, includePlayerRecipient, statIndex, chartLineCode, recipientCode, playerNameArray, playerIdArray, side, recipientSide, eventAction, playerPosition, amountArray, rowGameTypeIndex, 
        recipientHomeAwayIndex, "BLOCKED_SHOT", "SHOT_TAKEN_BLOCKED", "Shot Taken Blocked")

      // handle HIT_TAKEN stat
      handleRecipientEvent(row, gameMap, statList, includeRecipientTeam, includePlayerRecipient, statIndex, chartLineCode, recipientCode, playerNameArray, playerIdArray, side, recipientSide, eventAction, playerPosition, amountArray, rowGameTypeIndex, 
        recipientHomeAwayIndex, "HIT", "HIT_TAKEN", "Hit Taken")

      // handle PENALTY_DRAWN stat
      // includes fight penalties 
      if (row.eventTypeId === "PENALTY" && statList.includes("PENALTY_DRAWN") && includeRecipientTeam && row.playerRecipientName != null && includePlayerRecipient && row.playerRecipientType === "DrewBy") {
        updateEventArrays(statList, statIndex, chartLineCode, recipientCode, playerNameArray, row.playerRecipientName, playerIdArray, row.playerRecipientId, side, recipientSide, eventAction, playerPosition, amountArray, rowGameTypeIndex, 
          recipientHomeAwayIndex, "PENALTY_DRAWN", "Penalty Drawn", row.playerRecipientPosition, gameMap, row, false)
        
        updateTeamArray(teamArray, recipientCode)
      }

      // handle FIGHT stat
      if (row.eventTypeId === "PENALTY" && statList.includes("FIGHT") && includeActorTeam && row.playerActorName !== null && row.eventSecondaryType === "Fighting" && includePlayerActor) {
        updateEventArrays(statList, statIndex, chartLineCode, actorCode, playerNameArray, row.playerActorName, playerIdArray, row.playerActorId, side, actorSide, eventAction, playerPosition, amountArray, rowGameTypeIndex, 
          actorHomeAwayIndex, "FIGHT", "Fight", row.playerActorPosition, gameMap, row, true)
        
        updateTeamArray(teamArray, actorCode)
      }

      // handle GOAL_AGAINST stat
      handleRecipientEvent(row, gameMap, statList, includeRecipientTeam, includePlayerRecipient, statIndex, chartLineCode, recipientCode, playerNameArray, playerIdArray, side, recipientSide, eventAction, playerPosition, amountArray, rowGameTypeIndex, 
        recipientHomeAwayIndex, "GOAL", "GOAL_AGAINST", "Goal Against")

      // handle SAVE stat
      handleRecipientEvent(row, gameMap, statList, includeRecipientTeam, includePlayerRecipient, statIndex, chartLineCode, recipientCode, playerNameArray, playerIdArray, side, recipientSide, eventAction, playerPosition, amountArray, rowGameTypeIndex, 
        recipientHomeAwayIndex, "SHOT", "SAVE", "Save")

      statIndex.forEach((index, i) => {
        // check for regular season vs playoffs
        // 03 = playoffs, For playoff games, the 2nd digit of the specific number gives the round of the playoffs, 
        // the 3rd digit specifies the matchup, and the 4th digit specifies the game (out of 7).
        // for example, 2022030415 is game 5 of the stanley cup finals
        let digits = String(row.gameId).split('').map(Number);
        let gameNumber = digits[5] === 2 ? gameMap[i] : ((digits[7] - 1)* 7 + digits[9]);
        // Home Data
        if (side[i] === "Home") {
          fillArrays(chartSecondLineMapArray[rowGameTypeIndex][1], chartSecondPieMapArray[rowGameTypeIndex][1], chartMinuteLineMapArray[rowGameTypeIndex][1], 
            chartMinutePieMapArray[rowGameTypeIndex][1], chartSeasonLineMapArray[rowGameTypeIndex][1], chartSeasonMinuteLineMapArray[rowGameTypeIndex][1], chartSeasonGameLineMapArray[rowGameTypeIndex][1], chartSeasonPieMapArray[rowGameTypeIndex][1], 
            chartSeasonCoordinateMapArray[rowGameTypeIndex][1], chartSeasonCoordinateRotatedMapArray[rowGameTypeIndex][1], index, gameTimeInSeconds, gameTimeInMinutes, gameTimeInFiveMinutes, gameNumber, chartLineCode, i, row);
        // Away Data
        } else if (side[i] === "Away") {
          fillArrays(chartSecondLineMapArray[rowGameTypeIndex][2], chartSecondPieMapArray[rowGameTypeIndex][2], chartMinuteLineMapArray[rowGameTypeIndex][2], 
            chartMinutePieMapArray[rowGameTypeIndex][2], chartSeasonLineMapArray[rowGameTypeIndex][2], chartSeasonMinuteLineMapArray[rowGameTypeIndex][2], chartSeasonGameLineMapArray[rowGameTypeIndex][2], chartSeasonPieMapArray[rowGameTypeIndex][2], 
            chartSeasonCoordinateMapArray[rowGameTypeIndex][2], chartSeasonCoordinateRotatedMapArray[rowGameTypeIndex][2], index, gameTimeInSeconds, gameTimeInMinutes, gameTimeInFiveMinutes, gameNumber, chartLineCode, i, row);
        }
        // Total Data
        fillArrays(chartSecondLineMapArray[rowGameTypeIndex][0], chartSecondPieMapArray[rowGameTypeIndex][0], chartMinuteLineMapArray[rowGameTypeIndex][0], 
          chartMinutePieMapArray[rowGameTypeIndex][0], chartSeasonLineMapArray[rowGameTypeIndex][0], chartSeasonMinuteLineMapArray[rowGameTypeIndex][0], chartSeasonGameLineMapArray[rowGameTypeIndex][0], chartSeasonPieMapArray[rowGameTypeIndex][0], 
          chartSeasonCoordinateMapArray[rowGameTypeIndex][0], chartSeasonCoordinateRotatedMapArray[rowGameTypeIndex][0], index, gameTimeInSeconds, gameTimeInMinutes, gameTimeInFiveMinutes, gameNumber, chartLineCode, i, row);
        // exclude duplicates in table for goal/assist/point/PPG/SHG
        if (!(!isEvenStrengthGoal && statList[index] === "GOAL")
          && !(excludeGoalDuplicates && statList[index] === "GOAL") 
          && !(excludePrimaryAssistDuplicates && statList[index] === "PRIMARY_ASSIST")
          && !(excludeSecondaryAssistDuplicates && statList[index] === "SECONDARY_ASSIST")
          && !(excludeAssistDuplicates && statList[index] === "ASSIST")
          && !(excludeFaceoffDuplicates && statList[index] === "FACEOFF")) {
            let eventId = row.eventId
            if (statList[index].includes("ASSIST") || statList[index] === "POINT" || statList[index].includes("FACEOFF")) {
              eventId = uuidv4()
            }
          fullTableData.push(createTableData(eventId, playerNameArray[i], chartLineCode[i].split('-')[0], eventAction[i], playerPosition[i], row.gameDay, row.period, row.periodTime, row.eventSecondaryType, side[i], row.gameType, row.coordinateX, row.coordinateY, row.strengthCode));
        }
      })
      
      // TODO: remove. this doesn't do anything
      return createTableData(row.eventId, playerNameArray, chartLineCode, eventAction, playerPosition, row.gameDay, row.period, row.periodTime, row.eventSecondaryType, row.gameType, row.coordinateX, row.coordinateY);
    })
    console.log('events processed')
    // Loads Home, Away, and Total Data
    loadSecondData(chartSecondLineMapArray, chartSecondPieMapArray, teamArray, secondTickArray, 15);
    console.log('second data loaded')
    let minuteData = loadMinuteData(chartMinuteLineMapArray, chartMinutePieMapArray,  teamArray, minuteTickArray, 1);
    console.log('minute data loaded')
    let seasonPieData = loadSeasonData(chartSeasonLineMapArray, chartSeasonMinuteLineMapArray, chartSeasonGameLineMapArray, chartSeasonPieMapArray, chartSeasonCoordinateMapArray, chartSeasonCoordinateRotatedMapArray, teamArray, minuteTickArray, 10, formPlayerBreakdown, statList);
    console.log('all data transformed and loaded')
    setAmount(amountArray);
    let initializedColorKeys = initializeKeys(minuteData, seasonPieData, formPlayerBreakdown);
    processSeasonPieData(seasonPieData);
    console.log('initializing colors')
    setColorObject(initializeColors(initializedColorKeys));
    console.log('initializing keys')
    setKeys(sortKeys(minuteData));
    // TODO: tabulate table data?
    console.log('setting table data')
    setTableData(fullTableData);
    setPlayerTableData(fullPlayerTableData);
    setGoalieTableData(fullGoalieTableData);
    setIsLoading(false);
    console.log("loading complete!");
  }

  function handleRecipientEvent(row, gameMap, statList, includeRecipientTeam, includePlayerRecipient, statIndex, chartLineCode, recipientCode, playerNameArray, playerIdArray, sideArray, recipientSide, eventAction, playerPosition, amountArray, rowGameTypeIndex, 
    recipientHomeAwayIndex, eventTypeString, statString, eventActionString) {
    if (row.eventTypeId === eventTypeString && statList.includes(statString) && includeRecipientTeam && row.playerRecipientName != null && includePlayerRecipient) {
      updateEventArrays(statList, statIndex, chartLineCode, recipientCode, playerNameArray, row.playerRecipientName, playerIdArray, row.playerRecipientId, sideArray, recipientSide, eventAction, playerPosition, amountArray, rowGameTypeIndex, 
        recipientHomeAwayIndex, statString, eventActionString, row.playerRecipientPosition, gameMap, row, false)
    }
  }

  function updateEventArrays(statList, statIndex, chartLineCode, code, playerNameArray, playerName, playerIdArray, playerId, sideArray, side, eventAction, playerPosition, amountArray, rowGameTypeIndex, 
    eventHomeAwayIndex, statString, eventActionString, playerPositionString, gameMap, row, isActor) {
      let gameNumber = isActor ? row.playerActorTeamGameNumber : row.playerRecipientTeamGameNumber
      statIndex.push(statList.indexOf(statString))
      chartLineCode.push(code)
      playerNameArray.push(playerName)
      playerIdArray.push(playerId)
      sideArray.push(side)
      eventAction.push(eventActionString)
      playerPosition.push(playerPositionString)
      gameMap.push(gameNumber)
      if (amountArray[rowGameTypeIndex][0][statList.indexOf(statString)] == null) {
        amountArray[rowGameTypeIndex][0][statList.indexOf(statString)] = 0;
      }
      if (amountArray[rowGameTypeIndex][eventHomeAwayIndex][statList.indexOf(statString)] == null) {
        amountArray[rowGameTypeIndex][eventHomeAwayIndex][statList.indexOf(statString)] = 0;
      }
      amountArray[rowGameTypeIndex][0][statList.indexOf(statString)] += 1;
      amountArray[rowGameTypeIndex][eventHomeAwayIndex][statList.indexOf(statString)] += 1;
  }

  function updateTeamArray(teamArray, code) {
    if (!teamArray.includes(code)) {
      teamArray.push(code);
    }
  }

  function RenderAllCharts({index}) {
    const chartTypeArray = ['coordinate','pie','bar','line'];
    let buttonSize = isTabletOrMobile ? 'small' : 'medium'
    return (
      <Grid container spacing={0}>
        {chartTypeArray.map((e, i) => (
          <Grid item xs={12} sx={{py: 2}}>
          <Paper
            elevation={2}
            sx={{
              p: 2,
              flex: 1,
              flexGrow: 1,
              width:'100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          > 
          <RenderChart
            index={index}
            chartType={chartTypeArray[i]}
          />
          <Grid container spacing={0}>
            <Grid item xs={0} sm={0.4}></Grid>
            {/* Changes Chart Type */}
            {chartTypeArray[i] !== "coordinate" && chartTypeArray[i] !== "bar" ? (
              <Grid item xs={12} sm={2.6} alignItems="left">
                <FormControl sx={{ minWidth: 80 }}>
                  <InputLabel sx = {{ml: 2}} id="time-type-select-label">Time Type</InputLabel>
                  {
                    chartTypeArray[i] === "line" ? (
                      <Select
                        labelId="time-type-select-label"
                        id="time-type-select"
                        value={lineChartRadio}
                        label="Time Type"
                        onChange={handleLineChartRadioChange}
                        size={buttonSize}
                        sx = {{ml: 2}}
                      >
                        <MenuItem value="second">Total Per Second</MenuItem>
                        <MenuItem value="minute">Total Per Minute</MenuItem>
                        <MenuItem value="minuteSeason">Season: Minute by Minute</MenuItem>
                        <MenuItem value="season">Season: Period by Period</MenuItem>
                        <MenuItem value="game">Season: Game by Game</MenuItem>       
                      </Select>
                    ) : (
                      <Select
                        labelId="time-type-select-label"
                        id="time-type-select"
                        value={pieChartRadio}
                        label="Time Type"
                        onChange={handlePieChartRadioChange}
                        size={buttonSize}
                        sx = {{ml: 2}}
                      >
                        <MenuItem value="second">Total Per Second</MenuItem>
                        <MenuItem value="minute">Total Per Minute</MenuItem>
                        <MenuItem value="season">Season</MenuItem>    
                      </Select>
                    )
                  }
                </FormControl>
              </Grid>
            ) : <Grid item xs={12} sm={2.6}></Grid>}       
            <Grid item xs={6} sm={3}>
              <Grid container spacing={0} alignItems="center">
                <Grid item xs={0.3} sm={0}></Grid>
                <Grid item xs={4} sm={3.7}>
                  <Typography color="primary" sx={{ py: 2}} id="game-row-radio-buttons-group-label">Game Type</Typography>
                </Grid>
                <Grid item xs={7.7} sm={8}>
                  <ToggleButtonGroup
                    color="primary"
                    value={gameRadio}
                    exclusive
                    onChange={handleGameRadioChange}
                    aria-label="game-row-radio-buttons-group-label"
                  >
                    <ToggleButton value="regular" size={buttonSize}>Regular</ToggleButton>
                    <ToggleButton value="playoff" size={buttonSize}>Playoff</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </Grid>                 
            <Grid item xs={6} sm={4}>
              <Grid container spacing={0} alignItems="center">
                <Grid item xs={0.3} sm={0}></Grid>
                <Grid item xs={4} sm={3.3}>
                  <Typography color="primary" sx={{ py: 2, px: 0}} id="side-row-radio-buttons-group-label">Home/Away</Typography>
                </Grid>
                <Grid item xs={7.7} sm={8}>
                  <ToggleButtonGroup
                    color="primary"
                    value={sideRadio}
                    exclusive
                    onChange={handleSideRadioChange}
                    aria-label="side-row-radio-buttons-group-label"
                  >
                    <ToggleButton value="total" size={buttonSize}>Total</ToggleButton>
                    <ToggleButton value="home" size={buttonSize}>Home</ToggleButton>
                    <ToggleButton value="away" size={buttonSize}>Away</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        </Grid>
        ))}
      </Grid>
    )
  }

  function RenderChart({index, chartType}) {
    let defaultCoordinateData = chartCoordinateSeasonData[gameTypeIndex][homeAwayIndex];
    let rotatedCoordinateData = chartCoordinateRotatedSeasonData[gameTypeIndex][homeAwayIndex];
    if (chartType === "coordinate" || (chartType === "line" && lineChartRadio === "second") || (chartType === "pie" && pieChartRadio === "second")) {
        return (
        <React.Fragment>
          {chartSecondData[gameTypeIndex][homeAwayIndex][index] != null && chartSecondData[gameTypeIndex][homeAwayIndex][index].length > 0 ? (<Chart 
            title={chartTitle[index]}
            data={chartSecondData[gameTypeIndex][homeAwayIndex][index]}
            pieData={chartPieSecondData[gameTypeIndex][homeAwayIndex][index]}
            defaultCoordinateData={defaultCoordinateData}
            rotatedCoordinateData={rotatedCoordinateData}
            statList={eventList}
            ticks={secondTickArray[gameTypeIndex]}
            isSecond={true}
            isSeason={false}
            chartType={chartType}
            chartRadio={chartType === "line" ? lineChartRadio : pieChartRadio}
            statIndex={index}
            showTotal={showTotal}
            onShowTotal={handleShowTotal}
            isRotated={isRotated}
            onRotate={handleRotate}
            useAnimation={useAnimation}
            keys={keys}
            colorObject={colorObject}
            gameTypeIndex={gameTypeIndex}
            gameLengthArray={gameStartArray[gameTypeIndex]}
            isPlayerBreakdown={isPlayerBreakdown}
          />) :
          (<Typography
            variant="h4"
            color="primary"
            noWrap
            sx={{ p: 6 }}
          >
            {`No Data Available for chart selection: ${chartType}/${chartType === "line" ? lineChartRadio : pieChartRadio}/${gameRadio}/${sideRadio}`}
          </Typography>)
          }
        </React.Fragment>
        )
      } else if ((chartType === "line" && lineChartRadio === "minute") || (chartType === "pie" && pieChartRadio === "minute")) {
        return (
          <React.Fragment>
            {chartMinuteData[gameTypeIndex][homeAwayIndex][index] != null && chartMinuteData[gameTypeIndex][homeAwayIndex][index].length > 0 ? (<Chart 
          title={chartTitle[index]}
          data={chartMinuteData[gameTypeIndex][homeAwayIndex][index]}
          pieData={chartPieMinuteData[gameTypeIndex][homeAwayIndex][index]}
          defaultCoordinateData={defaultCoordinateData}
          rotatedCoordinateData={rotatedCoordinateData}
          statList={eventList}
          ticks={minuteTickArray[gameTypeIndex]}
          isSecond={false}
          isSeason={false}
          chartType={chartType}
          chartRadio={chartType === "line" ? lineChartRadio : pieChartRadio}
          statIndex={index}
          showTotal={showTotal}
          onShowTotal={handleShowTotal}
          isRotated={isRotated}
          onRotate={handleRotate}
          useAnimation={useAnimation}
          keys={keys}
          colorObject={colorObject}
          gameTypeIndex={gameTypeIndex}
          gameLengthArray={gameStartArray[gameTypeIndex]}
          isPlayerBreakdown={isPlayerBreakdown}
        />) :
        (<Typography
          variant="h4"
          color="primary"
          noWrap
          sx={{ p: 6 }}
        >
          {`No Data Available for chart selection: ${chartType}/${chartType === "line" ? lineChartRadio : pieChartRadio}/${gameRadio}/${sideRadio}`}
        </Typography>)
        }
      </React.Fragment>
      )
    } else if ((chartType === "line" && lineChartRadio === "minuteSeason")) {
        return (
          <React.Fragment>
              {chartMinuteSeasonData[gameTypeIndex][homeAwayIndex][index] != null && chartMinuteSeasonData[gameTypeIndex][homeAwayIndex][index].length > 0 ? (<Chart 
              title={chartTitle[index]}
              data={chartMinuteSeasonData[gameTypeIndex][homeAwayIndex][index]}
              pieData={chartPieSeasonData[gameTypeIndex][homeAwayIndex][index]}
              defaultCoordinateData={defaultCoordinateData}
              rotatedCoordinateData={rotatedCoordinateData}
              statList={eventList}
              ticks={seasonMinuteTickArray[gameTypeIndex][homeAwayIndex][index]}
              isSecond={false}
              isSeason={true}
              chartType={chartType}
              chartRadio={lineChartRadio}
              statIndex={index}
              showTotal={showTotal}
              onShowTotal={handleShowTotal}
              isRotated={isRotated}
              onRotate={handleRotate}
              useAnimation={useAnimation}
              keys={keys}
              colorObject={colorObject}
              gameTypeIndex={gameTypeIndex}
              gameLengthArray={gameStartArray[gameTypeIndex]}
              isPlayerBreakdown={isPlayerBreakdown}
            />  ) :
            (<Typography
              variant="h4"
              color="primary"
              noWrap
              sx={{p: 6}}
            >
              {`No Data Available for chart selection: ${chartType}/${chartType === "line" ? lineChartRadio : pieChartRadio}/${gameRadio}/${sideRadio}`}
            </Typography>)
        }
      </React.Fragment>
      )
    } else if ((chartType === "line" && lineChartRadio === "season") || (chartType === "pie" && pieChartRadio === "season") || chartType === "bar") {
        return (
          <React.Fragment>
              {chartSeasonData[gameTypeIndex][homeAwayIndex][index] != null && chartSeasonData[gameTypeIndex][homeAwayIndex][index].length > 0 ? (<Chart 
              title={chartTitle[index]}
              data={chartSeasonData[gameTypeIndex][homeAwayIndex][index]}
              pieData={chartPieSeasonData[gameTypeIndex][homeAwayIndex][index]}
              defaultCoordinateData={defaultCoordinateData}
              rotatedCoordinateData={rotatedCoordinateData}
              statList={eventList}
              ticks={seasonPeriodTickArray[gameTypeIndex][homeAwayIndex][index]}
              isSecond={false}
              isSeason={true}
              chartType={chartType}
              chartRadio={chartType === "line" ? lineChartRadio : pieChartRadio}
              statIndex={index}
              showTotal={showTotal}
              onShowTotal={handleShowTotal}
              isRotated={isRotated}
              onRotate={handleRotate}
              useAnimation={useAnimation}
              keys={keys}
              colorObject={colorObject}
              gameTypeIndex={gameTypeIndex}
              gameLengthArray={gameStartArray[gameTypeIndex]}
              isPlayerBreakdown={isPlayerBreakdown}
            />  ) :
            (<Typography
              variant="h4"
              color="primary"
              noWrap
              sx={{p: 6}}
            >
              {`No Data Available for chart selection: ${chartType}/${chartType === "line" ? lineChartRadio : pieChartRadio}/${gameRadio}/${sideRadio}`}
            </Typography>)
        }
      </React.Fragment>
      )
    } else if ((chartType === "line" && lineChartRadio === "game")) {
        return (
          <React.Fragment>
              {chartGameSeasonData[gameTypeIndex][homeAwayIndex][index] != null && chartGameSeasonData[gameTypeIndex][homeAwayIndex][index].length > 0 ? (<Chart 
              title={chartTitle[index]}
              data={chartGameSeasonData[gameTypeIndex][homeAwayIndex][index]}
              pieData={chartPieSeasonData[gameTypeIndex][homeAwayIndex][index]}
              defaultCoordinateData={defaultCoordinateData}
              rotatedCoordinateData={rotatedCoordinateData}
              statList={eventList}
              ticks={seasonTickArray[gameTypeIndex][homeAwayIndex][index]}
              isSecond={false}
              isSeason={true}
              chartType={chartType}
              chartRadio={lineChartRadio}
              statIndex={index}
              showTotal={showTotal}
              onShowTotal={handleShowTotal}
              isRotated={isRotated}
              onRotate={handleRotate}
              useAnimation={useAnimation}
              keys={keys}
              colorObject={colorObject}
              gameTypeIndex={gameTypeIndex}
              gameLengthArray={gameStartArray[gameTypeIndex]}
              isPlayerBreakdown={isPlayerBreakdown}
            />  ) :
            (<Typography
              variant="h4"
              color="primary"
              noWrap
              sx={{p: 6}}
            >
              {`No Data Available for chart selection: ${chartType}/${chartType === "line" ? lineChartRadio : pieChartRadio}/${gameRadio}/${sideRadio}`}
            </Typography>)
        }
      </React.Fragment>
      )
    } else {
        return <Typography
          variant="h4"
          color="primary"
          noWrap
          sx={{ p: 6 }}
        >
          Not Available
      </Typography>;
    }
  }

  function MainPage() {
    const { teamId } = useParams();
    const { search } = useLocation();
    const parameters = new URLSearchParams(search);
    let additionalTeams = parameters.get('compareTeams');
    let seasonId = parameters.get('season')
    let statIdList = parameters.get('stats')
    let searchStartTime = parameters.get('startTime')
    let searchEndTime = parameters.get('endTime')
    let byPlayer = parameters.get('byPlayer')
    let searchPositionList = parameters.get('positions')
    let localPlayerBreakdown = byPlayer != null && byPlayer
    let currentStartTime = startTime
    let currentEndTime = endTime
    let pageTeamList = teamList;
    let urlParams = hasURLParams;
    let positionArray = positionList;
    let currentSeason = season;
    let currentStatList = formStatList;
    let currentCheckBoxes = checkboxes;
    let currentIsPlayerBreakdown = isPlayerBreakdown;
    let skeletonWidth = isTabletOrMobile ? 310 : 800
    let stickyTop = isTabletOrMobile ? 53: 63;
    if (isLanding && teamId != null) {
      pageTeamList = [];
      pageTeamList.push(teamInfo.find(team => team.abbreviation === teamId))
      urlParams = true;
      setHasURLParams(true);
      setTeamList(pageTeamList);
      if (seasonId != null) {
        currentSeason = seasonId;
        setSeason(seasonId)
      }
      if (localPlayerBreakdown != null) {
        currentIsPlayerBreakdown = localPlayerBreakdown
        setIsPlayerBreakdown(localPlayerBreakdown)
      }
      if (searchPositionList != null) {
        let positionSplit = searchPositionList.split(",")
        positionArray = []
        for (const p of positionSplit) {
          positionArray.push({label: p, id: p})
        }
        setPositionList(positionArray)
      }
      if (statIdList != null) {
        let statSplit = statIdList.split(",");
        // TODO: allow for subscribers?
        if (statSplit.length > 5) {
          alert("Please select 5 or fewer stats");        
        } else {
          let statArray = []
          // reset checkboxes
          let checkboxCopy = { ...checkboxes };
          for (const box in checkboxCopy) {
            checkboxCopy[box] = false;
          }
          // set checkboxes with statIdList
          for (const s of statSplit) {
            checkboxCopy[camelize(s)] = true;
            statArray.push(s);
          }
          currentStatList = statArray;
          setFormStatList(statArray);
          currentCheckBoxes = checkboxCopy;
          setCheckboxes(checkboxCopy)
        }
      }
      // TODO: double check whether this overrides the season parameters?
      // TODO: check formatting?
      if (searchStartTime != null) {
        currentStartTime = searchStartTime
        setStartTime(searchStartTime)
      }
      if (searchEndTime != null) {
        currentEndTime = searchEndTime
        setEndTime(searchEndTime)
      }
      if(additionalTeams != null) {
        let additionalTeamsArray = additionalTeams.split(",")
        // TODO: allow for subscribers?
        if (additionalTeamsArray.length > (teamSelection - 1)) {
          alert("Please select 3 or fewer teams"); 
        } else {
          for (const t of additionalTeamsArray) {
            pageTeamList.push(teamInfo.find(team => team.abbreviation === t))
          }
          setTeamList(pageTeamList);
        }
      }
    }
    return (
      <Container maxWidth="xl" sx={{ mt: 2, mb: 2 }}>
        <Grid container spacing={2}>
          {/* Form */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', minWidth: 335 }}>
              <Form 
                onFormSubmit={handleFormSubmit} 
                onLoading={handleLoading}
                checkboxes={currentCheckBoxes}
                onCheckboxChange={handleCheckbox}
                teamList={pageTeamList}
                onTeamListChange={handleTeamList}
                season={currentSeason}
                onSeasonChange={handleSeason}
                positionList={positionArray}
                onPositionListChange={handlePositionList}
                selectedPlayerList={selectedPlayerList}
                playerOptionsList={playerOptionsList}
                onPlayerListChange={handleSelectedPlayerList}
                updatePlayerOptionsList={updatePlayerOptionsList}
                startTime={currentStartTime}
                onStartTimeChange={handleStartTime}
                endTime={currentEndTime}
                onEndTimeChange={handleEndTime}
                statList={currentStatList}
                onStatListChange={handleStatList}
                statPanelExpanded={statPanelExpanded}
                onStatPanelChange={handleStatPanel}
                filterPanelExpanded={filterPanelExpanded}
                onFilterPanelChange={handleFilterPanel}
                isLanding={isLanding}
                isPlayerBreakdown={currentIsPlayerBreakdown}
                onPlayerBreakdownUpdate={handlePlayerBreakdown}
                hasURLParams={urlParams}
                valid={valid}
                views={views}
                showAlert={showAlert}
              />
            </Paper>
          </Grid>
          {/* Chart */}
          <Grid item xs={12}>
            <Paper
              sx={{
                py: 2,
                pr: 2,
                display: 'flex',
                flexDirection: 'column',
                minHeight: 335,
                minWidth: 700,
              }}
            > 
              { isLoading ? (
                <Grid container spacing={2} sx={{p: 2}}>
                  <Grid item xs={12}>
                    <Skeleton variant="rectangular" width={210} height={40} />
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton variant="rectangular" width={210} height={40} />
                  </Grid>
                  <Grid item xs={12} align="center">
                    <Skeleton variant="rounded" width={skeletonWidth} height={200} />
                  </Grid>
                  <Grid item xs={12} align="center">
                    <Skeleton sx={{width: skeletonWidth}}/>
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton variant="rectangular" width={210} height={40}/>
                  </Grid>
                </Grid>
              ) : (
                <Box
                  sx={{
                    width:"100%"
                  }}
                >
                  <Box
                  sx={{
                    flexGrow: 1,
                    flex: 1,
                    display: "flex",
                    position: '-webkit-sticky',
                    position: 'sticky',
                    top: stickyTop,
                    zIndex: 10,
                    backgroundColor: 'rgba(256, 256, 256, 1)',
                    opacity: 1,
                    width: "100%"
                  }}
                  >
                    <Tabs 
                      value={tabIndex} 
                      onChange={handleTabChange}
                      visibleScrollbar={true}
                      variant="scrollable"
                      sx={{ borderBottom: 1, borderColor: 'divider'}}>
                      {eventType.map((e, i) => <Tab label={eventType[i]}/>)}
                    </Tabs>
                  </Box>
                  <Box
                    sx={{
                      flexGrow: 1,
                      flex: 1,
                      display: "flex",
                    }}
                  >
                  {eventType.map((e, i) => 
                    <React.Fragment>
                      { tabIndex === i && (
                      <Paper
                        sx={{
                          p: 2,
                          flex: 1,
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      > 
                        <Summary 
                          eventType={eventType[i]}
                          amount={amount[gameTypeIndex][homeAwayIndex][i]}
                          startTime={startTime}
                          endTime={endTime}
                        />
                        <RenderAllCharts
                          index={i}
                        />
                      </Paper>
                      )}
                    </React.Fragment>
                  )}
                  </Box>
                </Box>
              )}
            </Paper>
          </Grid>
          {/* EventTable TODO: Put inside tabs? */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', minWidth: "70rem", zIndex: 20 }}>
              { isLoading ? (
                <Grid container spacing={2} sx={{p: 2}}>
                  <Grid item xs={12}>
                    <Skeleton />
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton />
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton />
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton />
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton />
                  </Grid>
                </Grid>
              ) : (
                <React.Fragment>
                  <EventTable 
                    data={tableData}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    rowsPerPage={rowsPerPage}
                  />
                  {playerTableData.length > 0 && (
                    <PlayerTable 
                      data={playerTableData}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      rowsPerPage={rowsPerPage}
                    />
                  )}
                  {goalieTableData.length > 0 && (
                    <GoalieTable 
                      data={goalieTableData}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      rowsPerPage={rowsPerPage}
                    />
                  )}
                </React.Fragment>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    )
  }

  const mainMenuListArr = ["Teams"];

  const subMenuObj = {
    Teams: teamInfo,
  };

  function TeamList({array}) {
    return(
      <List>
      {array.map((team, index) => (
          <ListItemButton key={team.abbreviation} component="a" href={`/team/${team.abbreviation}`}>
            {team.logo !== "" && (
              <img src={team.logo} height="25" width="25"/>
            )}&nbsp;&nbsp;
            <ListItemText primary={team.label} />
          </ListItemButton>
      ))}
      </List>
    );
  }

  const NotFound = () => {
    return (
      <Typography sx={{p: 4}} component="h1" variant="h2" align="center">404 Page Not Found</Typography>
    );
  };

  const list = () => {
    let arr = menuName ? subMenuObj[menuName] : mainMenuListArr;
    const clickListener = text => {
      if (!menuName) {
        return setMenuName(text);
      } else {
        return alert(`${text} clicked`);
      }
    };
    return (
      <Grid container sx={{pt: 2}}>
          {menuName && (
            <React.Fragment>
              <Grid item xs={12} sm={3} sx={{minWidth:"15rem"}}>
                <Typography sx={{pl: 2}} component="h6" variant="h6">Atlantic</Typography>
                <TeamList
                  array={arr.filter(team => team.division === "Atlantic")}
                />
              </Grid>
              <Grid item xs={12} sm={3} sx={{minWidth:"15rem"}}>
                <Typography sx={{pl: 2}} component="h6" variant="h6">Metropolitan</Typography>
                <TeamList
                  array={arr.filter(team => team.division === "Metropolitan")}
                />
              </Grid>
              <Grid item xs={12} sm={3} sx={{minWidth:"15rem"}}>
                <Typography sx={{pl: 2}} component="h6" variant="h6">Central</Typography>
                <TeamList
                  array={arr.filter(team => team.division === "Central")}
                />
              </Grid>
              <Grid item xs={12} sm={3} sx={{minWidth:"15rem"}}>
                <Typography sx={{pl: 2}} component="h6" variant="h6">Pacific</Typography>
                <TeamList
                  array={arr.filter(team => team.division === "Pacific")}
                />
              </Grid>
            </React.Fragment>
          )
          }
          {!menuName && arr.map((text, index) => (
            <List>
              <ListItemButton key={text} onClick={() => clickListener(text)}>
                <ListItemText primary={text} primaryTypographyProps={{
                    fontWeight: 'bold',
                  }}/>
                &nbsp;&nbsp;&nbsp;{<ChevronRightIcon />}
              </ListItemButton>
            </List>
            ))}
      </Grid>
    );
  };

  const drawerWidth = "10rem";

  return (
    <Router>
      <ThemeProvider theme={defaultTheme}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar position="absolute" open={open} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{ 
                  mr: 2,
                }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ 
                  flexGrow: 1
                }}
              >
                <Link color="inherit" underline="none" href="/">
                  FourteenSeventeen
                </Link>
              </Typography>
              <Login sx={{ ml: 2,}} valid={valid} userName={userName} imageUrl={imageUrl} onLogout={onLogout}/>
            </Toolbar>
          </AppBar>
          <Drawer 
            anchor="left" 
            open={open} 
            onClose={toggleDrawer} 
            onOpen={toggleDrawer}
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            }}>
            <Toolbar />
            <Box sx={{ overflow: 'auto' }}>
              {menuName && (
                <ListItemButton sx={{pb: 2, pt: 4}} onClick={() => setMenuName(null)}>
                  <ListItemText primary="Teams" primaryTypographyProps={{
                    fontWeight: 'bold',
                  }}/>
                  <ChevronLeftIcon/>
                </ListItemButton>
              )}
              {!menuName && (
                <React.Fragment>
                  {list()}
                </React.Fragment>
              )}
              <List component="nav">
                {mainListItems}
              </List>
            </Box>
          </Drawer>
          <Drawer
            anchor="left"
            open={menuName != null}
            onClose={() => setMenuName(null)}
            transitionDuration={0}
            elevation={3}
            BackdropProps={{ invisible: true }}
            sx={{
              "& .MuiDrawer-paper": {
                minWidth: "15rem",
                boxSizing: "border-box",
                marginLeft: "10rem",
                backgroundColor: "#e0e0e0",
              }
            }}
          >
            <Toolbar />
            <Box>
              {list()}
            </Box>
          </Drawer>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar />
            <Routes>
              <Route exact path='/' element={<MainPage />} />
              <Route exact path='/team/:teamId' element={<MainPage />} />
              <Route path='/about' element={<About />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/privacy-policy' element={<PrivacyPolicy />} />
              <Route path='/terms-conditions' element={<TermsAndConditions />} />
              <Route path='*' element={<NotFound />} />
            </Routes>
            <Footer sx={{ pt: 4 }} />
          </Box>
        </Box>
      </ThemeProvider>
    </Router>
  );
}